import React from 'react';
import Image from '../../atoms/imageAtom/ImageAtom';
import Heading from '../../atoms/heading/Heading';
import Paragraph from '../../atoms/paragraph/Paragraph';
import { useFormContext } from 'react-hook-form';
import { htmlParser } from 'utils/jsUtils';

const IHPComponent = (props) => {
  const { data } = props;
  const { selectAtomsMolecules, type, path } = data;
  const { watch } = useFormContext();
  const content = watch(`${path}.para.value`);
  return (
    <div
      onClick={() => selectAtomsMolecules(type)}
      className="w-full h-full cursor-pointer flex flex-col justify-between p-4"
    >
      <div className="h-36 cursor-pointer w-full flex justify-center items-center">
        <Image data={data} />
      </div>

      <div className="h-28 px-4">
        <Heading data={data} />
        {content ? (
          <div
            onClick={() => selectAtomsMolecules(type)}
            className="parent-list-default flex flex-col overflow-auto h-20"
          >
            {htmlParser(content)}{' '}
          </div>
        ) : (
          <div className="h-20 overflow-auto">
            <Paragraph data={data} />
          </div>
        )}
      </div>
    </div>
  );
};

export default IHPComponent;
