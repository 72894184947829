export const conditions = [
  {
    name: 'Survey',
    moduleName: 'Surface',
    roleAccess: 'ManageCreatesurveys',
  },
  {
    name: 'Communities',
    moduleName: 'Surface',
    roleAccess: 'ManageCommunities',
  },
  {
    name: 'Events',
    moduleName: 'Surface',
    roleAccess: 'ManageEvents',
  },
  {
    name: 'Manage Recognitions',
    moduleName: 'Recognition',
    roleAccess: 'Managerecognitionprogramsandawards',
  },
  {
    name: 'Insights',
    moduleName: 'Admin',
    roleAccess: 'Viewinsights',
  },
  {
    name: 'Reports',
    moduleName: 'Admin',
    roleAccess: 'Downloadreportsforvariousmodules',
  },
  {
    name: 'Masters',
    moduleName: 'Admin',
    roleAccess: 'Viewaddandeditmastertables',
  },
  {
    name: 'Permission Controls',
    moduleName: 'Admin',
    roleAccess: 'Provideaccesstomanagepermissionsforotherusers',
  },
  {
    name: 'Company Settings',
    moduleName: 'Admin',
    roleAccess: 'Provideaccesstomanagecomapnysettings',
  },
  {
    name: 'Hiring Intelligence',
    moduleName: 'HiringIntelligence',
    roleAccess: 'ManageOffer',
  },
  {
    name: 'Letters',
    moduleName: 'Letters',
    roleAccess: 'ManageLetterTemplate',
  },

  {
    name: 'Announcement',
    moduleName: 'Surface',
    roleAccess: 'ManageAnnouncement',
  },

  // {
  //   name: 'View Policies',
  //   moduleName: 'Benefit',
  //   roleAccess: 'ViewCompanyPolicies',
  // },
  // {
  //   name: 'Total Rewards Philosophy',
  //   moduleName: 'Compensation',
  //   flagName: 'RewardsPhilosophy',
  // },
  // {
  //   name: 'Recognition Champions',
  //   moduleName: 'Recognition',
  //   roleAccess: 'RecognitionChampions',
  // },
];

export const subItemsConditions = [
  {
    name: 'Manage Recognition',
    moduleName: 'Recognition',
    roleAccess: 'Managerecognitionprogramsandawards',
  },
  {
    name: 'Redeem Rewards',
    moduleName: 'Recognition',
    roleAccess: 'Accesstoredeempoints',
  },
  {
    name: 'Manage Policies',
    moduleName: 'Policy',
    roleAccess: 'Managecompanypolicies',
  },
  {
    name: 'Manage Benefits',
    moduleName: 'Benefit',
    roleAccess: 'Administerandmanagecompanybenefits',
  },
  {
    name: 'Manage Programs',
    moduleName: 'Benefit',
    roleAccess: 'Administerandmanagecompanybenefits',
  },
  {
    name: 'View Dashboard',
    moduleName: 'Benefit',
    roleAccess: 'Accessandviewbenefitsforself',
  },
  // {
  //   name: 'Manage Benefits',
  //   moduleName: 'Benefit',
  //   roleAccess: 'Accessandviewbenefitsforself',
  // },
  {
    name: 'Manage Compensation Plan',
    moduleName: 'Compensation',
    roleAccess: 'Managecompensationplans',
  },
  {
    name: 'Manage Forms',
    moduleName: 'Recognition',
    roleAccess: 'Managerecognitionprogramsandawards',
  },
  {
    name: 'My Compensation',
    moduleName: 'Profile',
    roleAccess: 'ManageCompensation',
  },
  {
    name: 'Recognition Champions',
    moduleName: 'Recognition',
    roleAccess: 'RecognitionChampions',
  },
  {
    name: 'Total Rewards Philosophy',
    moduleName: 'Compensation',
    roleAccess: 'RewardsPhilosophy',
  },
  {
    name: 'View Policies',
    moduleName: 'Policy',
    roleAccess: 'ViewCompanyPolicies',
  },
  // {
  //   name: 'Compensation Planning',
  //   moduleName: 'Compensation',
  //   roleAccess: 'CompensationWorksheets',
  // },
  {
    name: 'Increment Philosophy',
    moduleName: 'Compensation',
    roleAccess: 'IncrementPhilosophy',
  },
  {
    name: 'Bonus Calculations',
    moduleName: 'Compensation',
    roleAccess: 'ManageCompensationBonus',
  },
  {
    name: 'Transact Now',
    moduleName: 'Esop',
    roleAccess: 'TransactEquity',
  },
  {
    name: 'Equity Summary',
    moduleName: 'Profile',
    roleAccess: 'EsopTab',
  },
  {
    name: 'Dashboard',
    moduleName: 'Insurance',
    roleAccess: 'ManageInsuranceClaim',
  },
  {
    name: 'Summary',
    moduleName: 'Insurance',
    roleAccess: 'EnrolAndClaimInsurance',
  },
  {
    name: 'Health Insurance',
    moduleName: 'Insurance',
    roleAccess: 'EnrolAndClaimInsurance',
  },
  {
    name: 'Accidental',
    moduleName: 'Insurance',
    roleAccess: 'EnrolAndClaimInsurance',
  },
  {
    name: 'Life Insurance',
    moduleName: 'Insurance',
    roleAccess: 'EnrolAndClaimInsurance',
  },
  {
    name: 'My Claims',
    moduleName: 'Insurance',
    roleAccess: 'EnrolAndClaimInsurance',
  },
  {
    name: 'Compensation Planning',
    moduleName: 'Compensation',
    roleAccess: 'CompensationWorksheets',
  },
  {
    name: 'Compensation Planning',
    moduleName: 'Compensation',
    roleAccess: 'CompensationWorksheetsAdmin',
  },
];
