import {
  ArrowTopRightOnSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import MoveIcon from 'assets/svg/moveIcon';
import ModalDialogue from 'components/atoms/modalDialogue/ModalDialogue';
import Chart from 'components/molecules/chart/ChartComponent';
import HeatMapChart from 'components/molecules/nivo/charts/HeatMapChart';
import { useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { tableData } from './Configuration/DashboadConfiguration/Chartlist';
export default function DashboardCanvas({
  layout,
  setLayout,
  dashboardSettings,
  configFor,
  setConfigFor,
  setSidePanelOpen,
  selectedPage,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState({});
  const [layoutData, setLayoutData] = useState([]);
  const saveLayoutToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  useEffect(() => {
    const selectedPageData = layout.find(
      (item) => item?.pageIndex === selectedPage,
    );
    setLayoutData(selectedPageData?.data);
  }, [layout, selectedPage]);

  const updateParentData = (newData) => {
    const selectedPageData = layout?.map((item) => {
      if (item?.pageIndex === selectedPage) {
        return { ...item, data: newData };
      } else return item;
    });
    setLayout(selectedPageData);
    saveLayoutToLocalStorage('dashboardLayout', selectedPageData);
  };

  const handleLayoutChange = (newLayout) => {
    const updatedLayout = newLayout.map((item) => {
      const originalItem = layoutData?.find((orig) => orig.i === item.i);

      return {
        ...item,
        data: originalItem ? originalItem.data : null,
      };
    });
    console.log(updatedLayout, 'updatedLayout');
    setLayoutData(updatedLayout);
    updateParentData(updatedLayout);
  };

  const onDeleteCard = (i) => {
    const updatedLayout = layoutData.filter((item) => item?.i !== i);
    setLayoutData(updatedLayout);
    updateParentData(updatedLayout);
    setSidePanelOpen(false);
    //saveLayoutToLocalStorage('dashboardLayout', updatedLayout);
    setConfigFor({});
  };

  const handleCardClick = (card, event) => {
    event.stopPropagation(); // Prevent the event from bubbling up to the main canvas
    setConfigFor(card);
    setSidePanelOpen(true);
  };

  const handleOpenModal = (card, event) => {
    event.stopPropagation();
    setSelectedChart(card);
    setIsModalOpen(true);
  };

  useEffect(() => {
    setSelectedChart(selectedChart);
    console.log(selectedChart);
  }, [selectedChart]);

  const renderCards = () => {
    return layoutData?.map((card, index) => {
      if (card?.data?.type === 'separator') {
        return (
          <div
            key={card?.i}
            onClick={(event) => {
              event.stopPropagation();
              setConfigFor(card);
            }}
            className={` drag-handle cursor-move h-full flex items-center  w-full ${
              configFor?.i === card?.i
                ? 'border-2 border-black '
                : 'border-none'
            }`}
          >
            {configFor?.i === card?.i && (
              <div
                className="absolute -top-3 -right-2 bg-gray-300 rounded-full w-5 h-5 flex justify-center items-center cursor-pointer text-gray-500"
                onClick={() => onDeleteCard(card?.i)}
              >
                x
              </div>
            )}
            <div className=" bg-black h-1 w-full"></div>
          </div>
        );
      } else if (card?.data?.type === 'text') {
        return (
          <div
            key={card?.i}
            onClick={(event) => {
              event.stopPropagation();
              setConfigFor(card);
              setSidePanelOpen(true);
            }}
            className={`h-full flex items-center w-full cursor-pointer ${
              configFor?.i === card?.i
                ? 'border-2 border-black '
                : 'border-none'
            }`}
          >
            {configFor?.i === card?.i && (
              <div
                className={`absolute -top-3 -right-3 bg-gray-300 rounded-full w-5 h-5 flex justify-center items-center cursor-pointer text-gray-500`}
                onClick={() => onDeleteCard(card?.i)}
              >
                x
              </div>
            )}
            <div className="p-4 drag-handle cursor-move">
              <div
                className="flex justify-between items-center"
                style={{
                  color: card?.data?.fontColor
                    ? card?.data?.fontColor
                    : 'black',
                  fontSize: card?.data?.fontSize
                    ? `${card?.data?.fontSize}px`
                    : '16px',
                  fontFamily: card?.data?.fontFamily
                    ? card?.data?.fontFamily
                    : 'Arial',
                }}
              >
                {card?.data?.description || 'Edit Text'}
              </div>
            </div>
          </div>
        );
      } else if (card?.data?.type === 'table') {
        return (
          <div
            key={card?.i}
            onClick={(event) => {
              event.stopPropagation();
              setConfigFor(card);
              setSidePanelOpen(true);
            }}
            className={`drag-handle cursor-move  h-full flex items-center w-full ${
              configFor?.i === card?.i
                ? 'border-2 border-black '
                : 'border-none'
            }`}
          >
            {configFor?.i === card?.i && (
              <div
                className={`absolute -top-3 -right-3 bg-gray-300 rounded-full w-5 h-5 flex justify-center items-center cursor-pointer text-gray-500`}
                onClick={() => onDeleteCard(card?.i)}
              >
                x
              </div>
            )}
            <div className="bg-white p-4 rounded-md border overflow-auto min-w-28">
              <table className="items-center w-full border-collapse text-blueGray-700">
                <thead className="thead-light">
                  <tr>
                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase  font-semibold text-left">
                      Program Name
                    </th>
                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase font-semibold text-left">
                      Program Status
                    </th>
                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase  font-semibold text-left">
                      Program Owner
                    </th>
                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase font-semibold text-left">
                      Total Budget
                    </th>
                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase font-semibold text-left">
                      Utilized
                    </th>
                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase font-semibold text-left">
                      Balance Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm font-normal">
                  {tableData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="border px-6 align-middle text-xs py-4">
                        {row.programName}
                      </td>
                      <td className="border px-6 align-middle text-xs py-4">
                        {row.programStatus}
                      </td>
                      <td className="border px-6 align-middle text-xs py-4">
                        {row.programOwner}
                      </td>
                      <td className="border px-6 align-middle text-xs py-4">
                        {row.totalBudget}
                      </td>
                      <td className="border px-6 align-middle text-xs py-4">
                        {row.utilized}
                      </td>
                      <td className="border px-6 align-middle text-xs py-4">
                        {row.balanceAmount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      } else if (card?.data?.type === 'heatmap') {
        return (
          <div
            key={card?.i}
            onClick={(event) => handleCardClick(card, event)}
            className={` bg-white shadow rounded flex flex-col ${
              configFor?.i === card?.i
                ? 'border-2 border-black '
                : 'border-none'
            }`}
          >
            <div className="px-4 pt-4 pb-1 flex items-center justify-between">
              <span className="text-base font-semibold text-black">
                {card?.data?.title}
              </span>
              <div className="flex justify-between items-center">
                <div
                  className="drag-handle cursor-move"
                  onDragStart={() => setSidePanelOpen(false)}
                  onDragEnd={() => setSidePanelOpen(false)}
                >
                  <MoveIcon />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={(event) => {
                    handleOpenModal(card, event);
                  }}
                >
                  <ArrowTopRightOnSquareIcon className="w-6 h-6 text-gray-400" />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => onDeleteCard(card?.i)}
                >
                  <TrashIcon className="w-6 h-6 text-gray-400" />
                </div>
              </div>
            </div>
            <span className="text-sm font-medium text-gray-500 px-4 pb-4">
              {card?.data?.description}
            </span>
            {card?.data?.chartData && (
              <div className="h-full px-4 pb-4">
                <HeatMapChart
                  keys={card?.data?.chartData?.labels}
                  data={card?.data?.chartData?.datasets}
                />
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div
            key={card?.i}
            onClick={(event) => handleCardClick(card, event)}
            className={` bg-white shadow rounded flex flex-col ${
              configFor?.i === card?.i
                ? 'border-2 border-black '
                : 'border-none'
            }`}
          >
            <div className="px-4 pt-4 pb-1 flex items-center justify-between">
              <span className="text-base font-semibold text-black">
                {card?.data?.title}
              </span>
              <div className="flex justify-between items-center gap-2.5">
                <div
                  className="drag-handle cursor-move"
                  onDragStart={() => setSidePanelOpen(false)}
                  onDragEnd={() => setSidePanelOpen(false)}
                >
                  <MoveIcon />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={(event) => {
                    handleOpenModal(card, event);
                  }}
                >
                  <ArrowTopRightOnSquareIcon className="w-6 h-6 text-gray-400" />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => onDeleteCard(card?.i)}
                >
                  <TrashIcon className="w-6 h-6 text-gray-400" />
                </div>
              </div>
            </div>
            <span className="text-sm font-medium text-gray-500 px-4 pb-4">
              {card?.data?.description}
            </span>
            {card?.data?.chartData && (
              <div className="h-full px-4 pb-4 ">
                <Chart
                  displayLegend={true}
                  chartOptions={card?.data?.chartData?.chartOptions || null}
                  type={card?.data?.type}
                  data={card?.data?.chartData}
                />
              </div>
            )}
          </div>
        );
      }
    });
  };
  useEffect(() => {
    const handleResize = () => {
      const gridItems = document.querySelectorAll('.react-grid-item');

      if (gridItems) {
        gridItems.forEach((item) => {
          const contentElement = item.querySelector('.chart-container'); // Adjust this selector based on your actual content structure
          if (contentElement) {
            item.style.minHeight = `${contentElement.scrollHeight}px`; // Set the height based on the content's scrollHeight
          }
        });
      }
    };

    // Initial check on mount
    handleResize();

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div
      onClick={() => {
        setConfigFor('default');
      }}
      style={{ backgroundColor: dashboardSettings?.settings?.backgroundColor }}
      className={`min-h-full h-max   px-8 py-6`}
    >
      {/* <div className="h-[500px] bg-red-400"></div>
      <div className="h-[500px] bg-blue-400"></div>
      <div className="h-[500px] bg-black-400"></div> */}
      {/* <button
        className="bg-blue-500 text-white py-2 px-4 rounded mb-4"
        onClick={addCard}
      >
        Add New Card
      </button>{' '} */}
      {/* <button
        className="bg-blue-500 text-white py-2 px-4 rounded mb-4"
        onClick={() => {
          localStorage.removeItem('dashboardLayout');
        }}
      >
        Clear Data
      </button> */}
      <GridLayout
        className="layout"
        layout={layoutData}
        cols={12}
        rowHeight={20}
        margin={[
          dashboardSettings?.settings?.spacing,
          dashboardSettings?.settings?.spacing,
        ]}
        width={document.documentElement.clientWidth - 155}
        height="100%"
        onLayoutChange={handleLayoutChange}
        draggableHandle=".drag-handle"
        resizeHandles={['se']}
      >
        {renderCards()}
      </GridLayout>
      {selectedChart ? (
        <ModalDialogue
          title={selectedChart?.data?.title}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedChart(null);
          }}
          isModalOpen={isModalOpen}
          width="70%"
          height="800px"
        >
          <div className="h-full">
            {selectedChart?.data?.type === 'heatmap' ? (
              <div className="h-full px-4 pb-4">
                <HeatMapChart
                  keys={selectedChart?.data?.chartData?.labels}
                  data={selectedChart?.data?.chartData?.datasets}
                />
              </div>
            ) : (
              <Chart
                displayLegend={true}
                chartOptions={
                  selectedChart?.data?.chartData?.chartOptions || null
                }
                type={selectedChart?.data?.type}
                data={selectedChart?.data?.chartData}
                dispkayLegend={true}
              />
            )}
          </div>
        </ModalDialogue>
      ) : null}
    </div>
  );
}
