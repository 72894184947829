import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Editor.styles.scss';
import EditorToolbar, { formats, modules } from './EditorToolbar';

const Link = Quill.import('formats/link');
Link.sanitize = function (url) {
  // quill by default creates relative links if scheme is missing.
  if (
    !url.startsWith('http://') &&
    !url.startsWith('https://') &&
    !url.startsWith('/')
  ) {
    return `http://${url}`;
  }
  return url;
};
Quill.register(Link, true);

export const RichEditor = React.forwardRef((props, ref) => {
  const {
    placeholder,
    label,
    required,
    disableImage,
    disableToolbar,
    onChange,
    ...rest
  } = props;
  const myFormats = [...formats];

  if (!disableImage) {
    myFormats.push('image');
  }
  return (
    <div className="text-editor">
      {label && (
        <label
          className={`text-sm font-medium ${
            props?.isStep1Program ? 'my-0' : 'my-1'
          } block text-gray-700`}
        >
          {label} {required && <span className="text-red-600">*</span>}
        </label>
      )}

      <ReactQuill
        preserveWhitespace
        ref={ref}
        placeholder={placeholder || `type anything..`}
        modules={modules}
        formats={myFormats}
        style={{
          background: '#FFFFFF',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          borderRadius: '10px',
        }}
        onChange={(e) => {
          onChange(e);
        }}
        {...rest}
      />
      <EditorToolbar
        disableImage={disableImage}
        disableToolbar={disableToolbar}
      />
    </div>
  );
});
RichEditor.displayName = 'RichEditor';
export default RichEditor;
