import { useState } from 'react';
import CreateDashboard from './createDashboard/CreateDashboard';
import ViewDashboard from './viewDashboard/ViewDashboard';

export default function Dashboard({ module = 'default', containerHeight }) {
  const [mode, setMode] = useState('create');
  return (
    <div className="p-1 w-full h-full">
      {mode === 'create' ? (
        <CreateDashboard module={module} containerHeight={containerHeight} />
      ) : (
        <ViewDashboard module={module} />
      )}
    </div>
  );
}
