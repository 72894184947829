import UserNameAvatar from 'components/atoms/userNameAvatar/UserNameAvatar';
import { useState } from 'react';
import { imageURLCheck } from 'utils/ImageUrlCheck';

const Avatar = (props) => {
  const [errorInAvatar, setErrorInAvatar] = useState(false);
  const handleOnLoad = () => setErrorInAvatar(false);

  const handleOnError = () => setErrorInAvatar(true);
  return (
    <div className="w-10 h-10">
      {!errorInAvatar && (
        <img
          src={imageURLCheck(props.image)}
          alt="user"
          className={`border-2 rounded-3xl w-10 h-10 object-cover ${props.className}`}
          onError={handleOnError}
          onLoad={handleOnLoad}
          {...props}
        />
      )}
      {errorInAvatar && <UserNameAvatar userName={props.userName} {...props} />}
    </div>
  );
};

export default Avatar;
