import PlusButton from 'components/molecules/plusButton/PlusButton';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  setSelectedColumn,
  setSelectedSection,
  setSidePanelTitle,
  templateBuilderSelector,
  toggleShowSidepanel,
  toggleSidePanelType,
} from 'store/reducers/templateBuilderSlice';
import BuilderColumnActions from './builderColumnActions/BuilderColumnActions';
import HeadingComponent from './buildeColumnComponents/headingComponent/HeadingComponent';
import ParagraphComponent from './buildeColumnComponents/paragraphComponent/ParagraphComponent';
import ImageComponent from './buildeColumnComponents/imageComponent/ImageComponent';
import VideoComponent from './buildeColumnComponents/videoComponent/VideoComponent';
import ButtonComponent from './buildeColumnComponents/buttonComponent/ButtonComponent';
import SpacerComponent from './buildeColumnComponents/spacerComponent/SpacerComponent';
import DividerComponent from './buildeColumnComponents/dividerComponent/DividerComponent';
import IHPComponent from './buildeColumnComponents/IHPComponent/IHPComponent';
import HPBComponent from './buildeColumnComponents/hpbComponent/HPBComponent';
import HPComponent from './buildeColumnComponents/hpComponent/HPComponent';
import VHPComponent from './buildeColumnComponents/vhpComponent/VHPComponent';

const BuilderColumn = (props) => {
  const { index, sectionIndex, column, sectionData } = props;
  const dispatch = useDispatch();
  const { selectedColumn, selectedSection } = useSelector(
    templateBuilderSelector,
  );
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const columns = useWatch({
    control,
    name: `data.${sectionIndex}.columns`,
  });
  const sections = useWatch({
    control,
    name: `data`,
  });
  const selectAtomsMolecules = (type) => {
    dispatch(toggleShowSidepanel(true));
    dispatch(toggleSidePanelType(type));
    dispatch(setSidePanelTitle('Add Elements'));
  };

  const onColumnClick = (e) => {
    e?.stopPropagation();
    dispatch(setSelectedColumn(column?.columnId));
    dispatch(setSelectedSection(sectionData?.sectionId));
  };

  const selectedColumnIndex = columns?.findIndex(
    (col) => col?.columnId === selectedColumn,
  );

  const selectedSectionIndex = sections?.findIndex(
    (section) => section?.sectionId === selectedSection,
  );

  const path = `data.${sectionIndex}.columns.${index}.column.attr`;
  const errorPath =
    errors?.data?.[sectionIndex]?.columns?.[index]?.column?.attr;
  const atomsMolecules = (type) => {
    const data = {
      type: type,
      path: path,
      errorPath: errorPath,
      selectAtomsMolecules: selectAtomsMolecules,
    };

    switch (type) {
      case 'Heading':
        return <HeadingComponent data={data} />;
      case 'Paragraph':
        return <ParagraphComponent data={data} />;
      case 'Image':
        return <ImageComponent data={data} />;
      case 'Video':
        return <VideoComponent data={data} />;
      case 'Button':
        return <ButtonComponent data={data} />;
      case 'Spacer':
        return <SpacerComponent data={data} />;
      case 'Divider':
        return <DividerComponent data={data} />;
      case 'IHP':
        return <IHPComponent data={data} />;
      case 'HPB':
        return <HPBComponent data={data} />;
      case 'HP':
        return <HPComponent data={data} />;
      case 'VHP':
        return <VHPComponent data={data} />;
      default:
        return (
          <div className="flex justify-center items-center h-full w-full">
            <PlusButton onTap={() => selectAtomsMolecules('AtomsMolecules')} />
          </div>
        );
    }
  };

  return (
    <div
      className={`relative group border rounded-xl ${
        index === selectedColumnIndex &&
        sectionIndex === selectedSectionIndex &&
        column.type !== 'Spacer' &&
        column.type !== 'Divider'
          ? 'border-primaryLeft-700 border-dashed'
          : column.type !== 'Spacer' &&
            column.type !== 'Divider' &&
            'border-gray-400'
      }   ${
        column.type === 'Spacer' || column.type === 'Divider'
          ? 'h-auto'
          : 'h-80'
      }  w-full flex flex-col `}
      onClick={onColumnClick}
    >
      {atomsMolecules(column.type)}
      {column.type &&
        column.type !== 'Divider' &&
        sectionData.columns.length !== 1 && (
          <div className="hidden group-hover:block absolute bottom-0 bg-white rounded-xl w-full">
            <BuilderColumnActions
              id={column?.columnId}
              index={index}
              parentIndex={sectionIndex}
              path={path}
            />
          </div>
        )}
    </div>
  );
};

export default BuilderColumn;
