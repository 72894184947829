import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import Tabs from 'components/molecules/tabs/Tabs';
import { Details } from 'pages/rangeBuilder/internalRange/internalRangePopUp/Details';
import { VersionHistory } from 'pages/rangeBuilder/internalRange/internalRangePopUp/VersionHistory';
import { useState } from 'react';
import { Employees } from './Employees';

export const FinalRangePopUp = ({
  setViewDetails,
  defaultTabSelected,
  setDefaultTabSelected,
  pivotedColumns,
  pivotedData,
  detailsTableColumns,
  detailsTableValues,
  versionHistory,
  benchmarkColumns,
  activePopUpId,
}) => {
  const [selectedTab, setSelectedTab] = useState(
    defaultTabSelected ? defaultTabSelected : 'Details',
  );

  const tabs = [
    {
      id: '1',
      tabLabel: 'Details',
    },
    {
      id: '2',
      tabLabel: 'Employees',
    },
    {
      id: '3',
      tabLabel: 'Benchmarks',
    },
    {
      id: '4',
      tabLabel: 'Version History',
    },
  ];

  return (
    <div className="fixed z-[53] inset-0 bg-backdrop overflow-y-auto w-full">
      <div
        className={`absolute top-14 right-0 w-3/5 bg-white min-h-screen overflow-y-auto custom-scrollbar`}
      >
        <div className="flex items-center bg-white justify-between w-full gap-4 shadow-md border-b-2 p-2 px-4 fixed top-[54px]">
          <div className="flex flex-row items-center gap-3 justify-start">
            <div
              className="p-2 rounded-lg border-2 cursor-pointer"
              onClick={() => {
                setViewDetails(false);
                setDefaultTabSelected && setDefaultTabSelected('');
              }}
            >
              <ArrowSmallLeftIcon className="w-4 h-4" />
            </div>
            <div className=" flex items-center justify center text-xl font-semibold">
              Pay Range Details
            </div>
          </div>
        </div>
        <div className="p-6 mt-[54px] flex items-center justify-between w-full">
          <p className="font-semibold text-gray-900 w-full">{`${
            pivotedData?.[pivotedColumns?.indexOf('Pay Range Code')]
          } | ${pivotedData?.[pivotedColumns?.indexOf('Pay Range Title')]}`}</p>
        </div>
        <div className="space-y-5 px-6">
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            handleTab={(e) => setSelectedTab(e.tabLabel)}
          />
          {selectedTab === 'Details' && (
            <Details
              pivotedColumns={pivotedColumns}
              pivotedData={pivotedData}
              detailsTableColumns={detailsTableColumns}
              detailsTableValues={detailsTableValues}
              benchmarkColumns={benchmarkColumns}
            />
          )}
          {selectedTab === 'Employees' && (
            <Employees
              activePopUpId={activePopUpId}
              benchmarkColumns={benchmarkColumns}
            />
          )}
          {selectedTab === 'Version History' && (
            <VersionHistory versionHistory={versionHistory} />
          )}
        </div>
      </div>
    </div>
  );
};
