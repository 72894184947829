import { fetchMedia } from 'apiClient/templateBuilder/templateBuilderApi';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { onResponse } from 'utils/toastMessages';

const ViewImageAtom = (props) => {
  const { columnData, height } = props;
  const [imgUrl, setImgUrl] = useState();
  const getImageUrl = async () => {
    try {
      const response = await fetchMedia(columnData?.attr?.mediaId);
      setImgUrl(response.imageUrl);
    } catch (error) {
      onResponse(error.message);
    }
  };

  useEffect(() => {
    getImageUrl();
  }, []);
  const metaData = columnData?.attr?.image?.metaData;
  const navigate = useNavigate();
  return (
    <div className={` w-full flex flex-col`}>
      <div onClick={() => navigate('/' + metaData?.link)}>
        <img
          src={imageURLCheck(imgUrl)}
          alt=""
          className={`rounded-lg ${height ?? 'max-h-96'}  w-full  ${
            metaData?.objectFit ? metaData?.objectFit : 'object-cover'
          }`}
        />
      </div>
    </div>
  );
};

export default ViewImageAtom;
