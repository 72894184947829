export const links = [
  { label: 'Surface home', value: 'surface' },
  { label: 'My compensation', value: 'compensation/rewards-philosophy' },
  { label: 'ESOP', value: '/profile/Esop' },
  { label: 'Benefits dashboard', value: 'benefits/benefits-dashboard' },
  { label: 'Recognition summary', value: 'redeem/rewards' },
  {
    label: 'Profile Summary',
    value: 'profile/5875d55e-4b7e-46fa-bee5-7ff891898053',
  },
  { label: 'Nominees and dependents', value: '/profile/Esop' },
  { label: 'Policies page', value: 'policies/all-policies' },
];

export const headingAlignments = ['start', 'center', 'end', 'justify'];

const generateFontSizeList = (start, end) => {
  const fontSizeList = [];

  for (let i = start; i <= end; i++) {
    fontSizeList.push({ label: i, value: i });
  }

  return fontSizeList;
};

export const fontSize = generateFontSizeList(4, 108);

export const objectFit = [
  { value: 'object-fit', label: 'Fill' },
  { value: 'object-contain', label: 'Contain' },
  { value: 'object-cover', label: 'Cover' },
  { value: 'object-scale-down', label: 'Scale down' },
];
