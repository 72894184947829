import React from 'react';
import './Tooltip.styles.scss';

const ToolTip = ({ children, text, className, right }) => {
  return (
    <div className="tooltip">
      {children}
      <div className={`${right ? 'right' : 'top'} ${className}`}>
        {text}
        <i></i>
      </div>
    </div>
  );
};

export default ToolTip;
