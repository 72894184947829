import { CheckBoxProgram } from 'components/atoms/FormElements/input/Input';
import { useState } from 'react';

export const ReferenceTypeFilter = ({ typeList }) => {
  const [selectedType, setSelectedType] = useState('');
  return (
    <div className="flex items-center gap-2">
      <p className="text-sm font-medium text-gray-600">Reference Type:</p>
      <div className="flex items-center text-sm font-medium text-gray-600 gap-[2px]">
        {typeList?.map((referenceType) => {
          return (
            <CheckBoxProgram
              key={referenceType?.name}
              type="checkbox"
              checked={selectedType === referenceType?.name}
              label={referenceType?.name}
              onChange={() => setSelectedType(referenceType?.name)}
            />
          );
        })}
      </div>
    </div>
  );
};
