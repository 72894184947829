import CloneIcon from 'assets/svg/cloneIcon';
import DeleteIcon from 'assets/svg/deleteIcon';
import DownArrowIcon from 'assets/svg/downArrowIcon';
import UpArrowIcon from 'assets/svg/upArrowIcon';
import React from 'react';
import BuilderColumn from '../builderColumn/BuilderColumn';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  setSelectedSection,
  templateBuilderSelector,
} from 'store/reducers/templateBuilderSlice';
import { useSelector } from 'react-redux';

const BuilderSection = (props) => {
  const { onRemove, index, onSwapUp, onSwapDown, sectionData } = props;
  const dispatch = useDispatch();
  const { control } = useFormContext();
  const { selectedSection } = useSelector(templateBuilderSelector);

  const Columns = useWatch({
    control,
    name: `data.${index}.columns`,
  });
  const sections = useWatch({
    name: 'data',
    control,
  });

  const onSectionClick = (e) => {
    e?.stopPropagation();
    dispatch(setSelectedSection(sectionData?.sectionId));
  };

  const selectedIndex = sections?.findIndex(
    (section) => section?.sectionId === selectedSection,
  );

  return (
    <div
      className={`group ring-1 rounded-xl ${
        selectedIndex === index ? 'ring-primaryLeft-700' : 'ring-gray-200'
      }  w-full p-4 flex-col justify-between hover:shadow-xl shadow-md`}
      onClick={onSectionClick}
    >
      <div
        className={`grid ${
          Columns.length > 1 && 'max-xl:grid-cols-2 max-lg:grid-cols-1  gap-4'
        } grid-cols-${Columns.length}  gap-4`}
      >
        {Columns.map((column, i) => (
          <BuilderColumn
            key={i}
            index={i}
            column={column}
            sectionIndex={index}
            sectionData={sectionData}
          />
        ))}
      </div>
      <div className="group-hover:block hidden">
        <div className=" p-4 h-14 ring-1 ring-gray-200 rounded-lg flex justify-between mt-3">
          <h2 className="text-sm">Editing options</h2>
          <div className="flex gap-2 text-gray-500">
            <UpArrowIcon onClick={() => onSwapUp(index)} />
            <DownArrowIcon onClick={() => onSwapDown(index)} />
            <CloneIcon />
            <DeleteIcon onClick={() => onRemove(index)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuilderSection;
