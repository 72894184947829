import { useAuth0 } from '@auth0/auth0-react';
import { DEFAULT_AVATAR_PATH } from 'apiClient/api.constant';
import { useSelector } from 'react-redux';
import LogOutIcon from '../../assets/svg/logOutIcon';
import ViewProfileIcon from '../../assets/svg/viewProfileIcon';
import Avatar from './Avatar.jsx';
import ProfileItem from './ProfileItem.jsx';

const UserProfile = ({ setOpenProfile }) => {
  const { logout } = useAuth0();
  const user = useSelector((state) => state.user);

  const handleLogout = () => {
    localStorage.clear();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  return (
    <div className="flex flex-col py-2 px-0 items-start absolute top-[130%] right-1 bg-gray-50 w-64 shadow-md h-auto text-center border-2 rounded-lg z-[90]">
      <div className="flex flex-row items-start py-2 px-1.5 px-5 gap-3 isolate w-full border-b-2">
        <div className="flex flex-col items-center p-0 gap-1 w-10 h-10">
          <Avatar
            image={
              user?.userProfileData
                ? user?.userProfileData?.avatar?.value
                : // : 'https://pixlok.com/wp-content/uploads/2022/02/Profile-Icon-SVG-09856789.png'
                  DEFAULT_AVATAR_PATH
            }
            userName={user?.userProfileData?.fullName?.value}
          />
        </div>
        <div className="flex flex-col items-start p-0 truncate">
          <p className="text-sm font-semibold">
            {user?.userProfileData
              ? user?.userProfileData?.displayName?.value
              : 'N/A'}
          </p>
          <p className="text-xs text-gray-500 hover:cursor-pointer hover:underline">
            {user?.userProfileData
              ? user?.userProfileData?.businessEmail?.value
              : 'N/A'}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-start p-0 w-full border-b-2">
        <div
          onClick={() => setOpenProfile(false)}
          className="flex flex-col items-start  px-3"
        >
          <ProfileItem
            link={`/profile/${user?.userProfileData?.userId?.value}`}
            icon={<ViewProfileIcon />}
            value="View Profile"
          />
          {/* <ProfileItem link="/settings" icon={<SettingsIcon />} value="Settings" />
            <ProfileItem
              link="/keyboard-shortcuts"
              icon={<KeyboardShortcutsIcon />}
              value="Keyboard shortcuts"
            /> */}
        </div>
        {/* <div className="flex flex-col items-start w-full  px-0"></div> */}
      </div>
      {/* <div className="flex flex-col items-start p-0 w-full border-b-2">
        <div className="flex flex-col items-start w-full py-1 px-0">
          <div className="flex flex-col items-start py-0.5 px-3">
            <ProfileItem
              link="/company-profile"
              icon={<CompanyProfileIcon />}
              value="Company profile"
            />
            <ProfileItem link="/team" icon={<NetworkLogo />} value="Team" />
            <ProfileItem link="/invite" icon={<InviteColleaguesIcon />} value="Invite colleagues" />
          </div>
        </div>
      </div> */}
      {/* <div className="flex flex-col items-start p-0 w-full border-b-2">
        <div className="flex flex-col items-start w-full py-1 px-0">
          <div className="flex flex-col items-start py-0.5 px-3">
            <ProfileItem link="/changelog" icon={<ChangelogIcon />} value="Changelog" />
            <ProfileItem link="/slack-community" icon={<SlackIcon />} value="Slack community" />
            <ProfileItem link="/support" icon={<SupportIcon />} value="Support" />
            <ProfileItem link="/api" icon={<ApiIcon />} value="API" />
          </div>
        </div>
      </div> */}
      <div className="flex flex-col items-start p-0 w-full">
        <div
          onClick={handleLogout}
          className="flex  items-center w-full py-1 px-5"
        >
          {/* <div className="flex flex-col items-start py-0.5 px-3">
            <div className="flex flex-row items-center px-2 py-1 gap-3">
          
            </div>
          </div> */}
          {/* <div className="flex flex-row items-center cursor-pointer">
          
          </div> */}
          <LogOutIcon />
          <span className="pl-2">Log out</span>
        </div>
      </div>
      {/* <div className="flex flex-col items-start p-0 w-full ">
        <div className="flex  items-center justify-between  px-3">
          <div className="flex items-center p-2 gap-1 text-xs">
            Powered By
            <div className="h-3">
              <img
                src="https://talentmonk.com/wp-content/uploads/2024/04/Horizontal-Logo-e1712650848449.jpg"
                alt=""
                className="w-full h-full "
              />
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col items-start w-full  px-0"></div>
      </div> */}
    </div>
  );
};

export default UserProfile;
