import { getfinalRangeEmployeesData } from 'apiClient/rangeBuilder/rangeBuilder';
import Button from 'components/atoms/button/Button';
import Table from 'components/atoms/table/Table';
import TableHeader from 'components/atoms/tableHeader/TableHeader';
import Search from 'layouts/header/Search';
import { useEffect, useState } from 'react';
import { buttonTabs } from '../../../../config/rangeBuilderData';
import { CompensationTypeFilter } from '../../internalRange/CompensationTypeFilter';
import EmployeeSalaryGraph from './EmployeeSalaryGraph';

export const Employees = ({ activePopUpId, benchmarkColumns }) => {
  const [selectedTab, setSelectedTab] = useState('All');
  const [employeesData, setEmployeesData] = useState([]);
  const [selectedBenchmarkId, setSelectedBenchmarkId] = useState(
    benchmarkColumns?.[0]?.id,
    // 'b424bce4-c487-40eb-848d-38a9fb589021',
  );

  console.log(benchmarkColumns, 'col');

  useEffect(() => {
    (async () => {
      try {
        const response = await getfinalRangeEmployeesData(
          '9a2dba33-19a4-4d37-8449-65f249a4731a',
          // activePopUpId
        );
        setEmployeesData(response);
        console.log(response, 'res');
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const columns = [
    {
      id: '1',
      Header: (
        <TableHeader name="Employee Details" HeaderKey="employeeDetails" />
      ),
      Cell: ({ row }) => (
        <div className="flex items-center gap-3">
          <div>
            <p className="font-semibold text-sm text-gray-900">
              {row.original?.uploadedJson?.Name}
            </p>
            <p className="text-sm text-gray-500">
              {`Emp ID: ${row.original?.uploadedJson?.['Employee Id']}`}
            </p>
          </div>
        </div>
      ),
    },
    {
      id: '2',
      Header: (
        <TableHeader name="Comp Ratio" HeaderKey="compRatio" sort={true} />
      ),
      Cell: ({ row }) => (
        <span className="">
          <p className="text-sm text-gray-600">
            {(
              row.original?.uploadedJson?.[selectedBenchmarkId] /
              row.original?.calculationJson?.[`mid_${selectedBenchmarkId}`]
            ).toFixed(2)}
          </p>
        </span>
      ),
    },
    {
      id: '3',
      Header: (
        <TableHeader name="Compensation" HeaderKey="compensation" sort={true} />
      ),
      Cell: ({ row }) => (
        <span className="">
          <p className="text-sm text-gray-600">{`${row.original?.uploadedJson?.[selectedBenchmarkId]}`}</p>
        </span>
      ),
    },
    {
      id: '4',
      Header: (
        <TableHeader
          name="Internal Range"
          HeaderKey="internalRange"
          sort={true}
        />
      ),
      Cell: ({ row }) => (
        <div className="flex flex-col gap-2">
          <EmployeeSalaryGraph
            data={{
              min: row.original?.calculationJson?.[
                `min_${selectedBenchmarkId}`
              ],
              mid: row.original?.calculationJson?.[
                `mid_${selectedBenchmarkId}`
              ],
              max: row.original?.calculationJson?.[
                `max_${selectedBenchmarkId}`
              ],
            }}
            averageSalary={
              row.original?.calculationJson?.[`mid_${selectedBenchmarkId}`]
            }
          />
          <div className="flex justify-between">
            <p className="font-figtree text-sm font-medium text-gray-600">
              {row.original?.calculationJson?.[`min_${selectedBenchmarkId}`]}
            </p>
            <p className="font-figtree text-sm font-medium text-gray-600">
              {row.original?.calculationJson?.[`mid_${selectedBenchmarkId}`]}
            </p>
            <p className="font-figtree text-sm font-medium text-gray-600">
              {row.original?.calculationJson?.[`max_${selectedBenchmarkId}`]}
            </p>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="space-y-6">
      <div className="border border-gray-200 px-5 py-4 rounded-[10px]">
        <p className="text-sm font-medium text-gray-900">
          Employee Distribution
        </p>
        <div className="flex flex-col gap-4">
          <div className="relative flex items-center h-[20px] bg-gray-100 rounded-lg">
            <div
              className="w-[5%] h-[20px] bg-gray-50 border border-r-0 rounded-l-lg rounded-r-none flex items-center"
              style={{
                borderColor: 'rgba(213, 217, 235, 0.5)',
              }}
            ></div>
            <div
              className="w-[90%] h-[20px] border rounded-lg flex items-center"
              style={{
                backgroundColor: 'rgba(213, 217, 235, 0.5)',
                borderColor: 'rgba(113, 123, 188, 0.5)',
              }}
            >
              <div
                className={`w-2 h-2  bg-black-400 rounded-md absolute`}
                style={{ left: 60 }}
              ></div>
              <div
                className={`w-[17px] h-[12px] text-white text-[9px] text-center justify-center bg-black-400 rounded-md absolute`}
                style={{ left: 260 }}
              >
                12
              </div>
              <div
                className={`w-2 h-2  bg-black-400 rounded-md absolute`}
                style={{ left: 560 }}
              ></div>
            </div>
            <div className="w-[5%] h-[20px] bg-gray-100 border border-l-0 rounded-r-lg rounded-l-none flex items-center"></div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col gap-0.5">
              <p className="font-figtree text-xs font-semibold">1000</p>
              <p className="text-xs font-figtree text-gray-600 font-normal">
                Min
              </p>
            </div>
            <div className="flex flex-col gap-0.5">
              <p className="font-figtree text-xs font-semibold">2000</p>
              <p className="text-xs font-figtree text-gray-600 font-normal">
                Mid
              </p>
            </div>
            <div className="flex flex-col gap-0.5">
              <p className="font-figtree text-xs font-semibold">3000</p>
              <p className="text-xs font-figtree text-gray-600 font-normal">
                Max
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-4">
        <p className="text-sm font-semibold text-gray-900">
          Employees with this band
        </p>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {buttonTabs?.map((tab) => {
              return (
                <Button
                  key={tab?.id}
                  variant={selectedTab === tab?.label ? 'filled' : ''}
                  onClick={() => setSelectedTab(tab?.label)}
                >
                  {tab?.label}
                </Button>
              );
            })}
          </div>
          <div className="flex items-center gap-4">
            <Search />
            <Button variant="outlined">
              {/* <TableFilterIcon /> */}
              Filter
            </Button>
          </div>
        </div>
        <div className="border-y bg-gray-50 border-gray-200 px-8 py-1 flex items-center">
          <CompensationTypeFilter
            typeList={[
              { name: 'Base Pay' },
              { name: 'Variable Pay' },
              { name: 'Others' },
              { name: 'Equity' },
            ]}
          />
        </div>
        <div>
          <Table columns={columns} data={employeesData} />
        </div>
      </div>
    </div>
  );
};
