import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ViewButtonAtom = (props) => {
  const { columnData } = props;

  const metaData = columnData?.attr?.button?.metaData;
  const flex = columnData?.attr?.flex?.justify;

  const navigate = useNavigate();

  return (
    <div
      style={{ justifyContent: flex ?? 'center' }}
      className="w-full flex flex-col items-start"
    >
      <button
        onClick={() => navigate('/' + metaData?.link)}
        style={{
          backgroundColor: metaData?.buttonColor,
          borderColor: metaData?.outlineColor,

          color: metaData.textColor,
        }}
        className={`rounded-lg p-2 border`}
      >
        {columnData.attr.button.value}
      </button>
    </div>
  );
};

export default ViewButtonAtom;
