import { useFormContext } from 'react-hook-form';

const Heading = (props) => {
  const { path, errorPath, selectAtomsMolecules, type } = props.data;
  const { control, register, watch } = useFormContext();
  const [headingColor, headingFontSize, headingAlignment, headingPadding] =
    watch([
      `${path}.heading.metaData.color`,
      `${path}.heading.metaData.fontSize`,
      `${path}.heading.metaData.alignment`,
      `${path}.heading.metaData.padding`,
    ]);
  return (
    <div>
      <input
        style={{
          textAlign: headingAlignment,
          color: headingColor,
          fontSize: headingFontSize ? headingFontSize : '24px',
        }}
        placeholder="Click here to edit text"
        onClick={() => selectAtomsMolecules(type)}
        {...register(`${path}.heading.value`, {
          required: 'This Field is required',
        })}
        type="text"
        className={`outline-none placeholder:text-black  w-full leading-9`}
      />
      {errorPath?.heading?.value && (
        <p className="bg-clip-text bg-gradient-to-br text-transparent from-primaryLeft to-primaryRight">
          {errorPath?.heading?.value?.message + '*'}
        </p>
      )}
    </div>
  );
};

export default Heading;
