import React from 'react';

export const modules = {
  toolbar: {
    container: '#toolbar',
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

export const formats = [
  'bold',
  'italic',
  'underline',
  'align',
  'list',
  'bullet',
  'link',
  // "image",
  'script',
  'blockquote',
];

export const QuillToolbar = ({ disableImage, disableToolbar }) => (
  <div id="toolbar" className="!border-0 rounded-md mt-2">
    {!disableToolbar && (
      <div>
        <span className="ql-formats">
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
        </span>
        <span className="ql-formats">
          <button className="ql-script" value="super" />
          <button className="ql-script" value="sub" />
          <button className="ql-blockquote" />
        </span>
        <span className="ql-formats">
          <select className="ql-align" />
        </span>
        <span className="ql-formats">
          <button className="ql-link" />
          {/* {disableImage ? null : <button className="ql-image" />} */}
        </span>
      </div>
    )}
  </div>
);

export default QuillToolbar;
