import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  deleteLandingPage,
  fetchAllLandingPage,
} from 'apiClient/templateBuilder/templateBuilderApi';
import Button from 'components/atoms/button/Button';
import BackButton from 'components/atoms/button/backButton/BackButton';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../compensation/compPlanning/createPlanning/primaryTabs/planSetup/employeeData/employeeData.scss';
import { onResponse } from 'utils/toastMessages';

export default function LandingPage() {
  const [templateList, setTemplateList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchAllLandingPage()
      .then((res) => {
        setTemplateList(res);
        console.log(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteLandingPage(id);
      onResponse('Template deleted successfully');
      fetchAllLandingPage()
        .then((res) => {
          setTemplateList(res);
        })
        .catch((err) => console.log(err));
    } catch (err) {
      onResponse(err);
    }
  };

  return (
    <div className="h-full py-6 px-8 bg-white">
      <div className="flex justify-between items-center">
        <div className="flex gap-4 items-center">
          <BackButton
            onClick={() => {
              navigate(-1);
            }}
          />
          <h1 className="text-gray-900 text-xl font-semibold ">
            Landing Pages
          </h1>
        </div>
        <div>
          <Button
            className="font-semibold flex items-center gap-2"
            variant="outlined"
            onClick={() => navigate('/landingPages/create-new')}
          >
            <span>Create New Template</span>
          </Button>
        </div>
      </div>
      <div>
        <table className="employeeDataTable ">
          <thead>
            <tr>
              <th className="!text-black">S.no</th>
              <th className="!text-black">Title</th>
              <th className="!text-black">Module</th>
              <th className="!text-black">Status</th>
              <th className="!text-black"></th>
            </tr>
          </thead>
          <tbody>
            {templateList?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item?.title}</td>
                  <td>{item?.module?.name}</td>
                  <td>{item?.isActive ? 'Active' : 'Disabled'}</td>
                  <td>
                    <div className="flex justify-center gap-2">
                      <PencilIcon
                        onClick={() =>
                          navigate(`/landingPages/edit/${item?.id}`)
                        }
                        className="w-5 h-5 cursor-pointer"
                      />
                      <EyeIcon
                        onClick={() =>
                          navigate(`/landingPages/preview/${item?.id}`)
                        }
                        className="w-5 h-5 cursor-pointer"
                      />
                      <TrashIcon
                        className="w-5 h-5 cursor-pointer"
                        onClick={() => handleDelete(item?.id)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
