import { fetchModule } from 'apiClient/templateBuilder/templateBuilderApi';
import ResizableInput from 'components/atoms/FormElements/resizableInput/ResizableInput';
import Select from 'components/atoms/FormElements/select/Select';
import Button from 'components/atoms/button/Button';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setSelectedSection } from 'store/reducers/templateBuilderSlice';
import { v4 } from 'uuid';
import BuilderSection from './builderSection/BuilderSection';
import BuilderSectionLayout from './builderSectionLayout/BuilderSectionLayout';
import { Input } from 'components/atoms/FormElements/input/Input';

function BuilderMainPanel() {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const { append, remove, swap } = useFieldArray({
    control,
    name: `data`,
  });

  const dispatch = useDispatch();

  const pageStructure = useWatch({
    control,
    name: `data`,
  });

  const handleColumns = (index, columns) => {
    setValue(
      `data[${index}].columns`,
      columns === 2
        ? [{ columnId: v4() }, { columnId: v4() }]
        : columns === 3
        ? [{ columnId: v4() }, { columnId: v4() }, { columnId: v4() }]
        : columns === 4
        ? [
            { columnId: v4() },
            { columnId: v4() },
            { columnId: v4() },
            { columnId: v4() },
          ]
        : [{ columnId: v4() }],
    );
  };

  const onAdd = () => {
    const sectionId = v4();
    append({
      sectionId,
      columns: [],
    });
    dispatch(setSelectedSection(sectionId));
  };

  const modifyMedia = (index) => {
    const columns = watch(`data.${index}.columns`);
    const media = watch(`media`);
    columns.forEach((column) => {
      if (media[column.columnId]) {
        delete media[column.columnId];
      }
    });
    setValue(`media`, media);
  };

  const onRemove = (index) => {
    modifyMedia(index);
    const id = watch(`data.${index}.id`);
    if (id) {
      const rowsToDelete = watch(`rowsToDelete`);
      setValue(`rowsToDelete`, [...rowsToDelete, id]);
      const columns = watch(`data.${index}.columns`);
      columns.forEach((column) => {
        if (column?.column?.attr?.mediaId) {
          const mediasToDelete = watch(`mediasToDelete`);
          setValue(`mediasToDelete`, [
            ...mediasToDelete,
            column?.column?.attr?.mediaId,
          ]);
        }
      });
    }
    remove(index);
  };

  const onSwapUp = (index) => {
    if (index - 1 >= 0) {
      swap(index, index - 1);
    }
  };

  const onSwapDown = (index) => {
    if (index + 1 < pageStructure.length) {
      swap(index, index + 1);
    }
  };

  const loadOptions = async (query, loadedOptions, { page }) => {
    const response = await fetchModule(query);
    const options = response?.map((ele) => ({
      label: ele.name,
      value: ele.id,
    }));
    return {
      options,
      hasMore: false,
    };
  };

  return (
    <div>
      <div className="px-8 bg-white shadow-xl ring-1 ring-gray-200 relative rounded-lg h-full -mt-6 mx-6 z-40 h-42 py-8 flex flex-col gap-6">
        <div className=" flex justify-between items-center gap-5 w-full">
          <Controller
            control={control}
            name={`name`}
            rules={{ required: 'Title is required *' }}
            render={({ field }) => (
              <div className="flex flex-col w-full break-words">
                <Input
                  {...field}
                  placeholder="Enter your name"
                  className={
                    'max-w-3xl break-words text-black w-full max-h-28 border-none p-0'
                  }
                />
                {/* <div
                  {...field}
                  contentEditable={true}
                  className={`max-w-3xl break-words text-black w-full max-h-24 border-none outline-gray-300 font-medium text-xl`}
                >
                  {field.value && field.value.length > 0
                    ? field.value
                    : 'Enter your name'}
                </div> */}
                {errors.name && (
                  <p className="bg-clip-text bg-gradient-to-br text-transparent from-primaryLeft to-primaryRight">
                    {errors.name.message}
                  </p>
                )}
              </div>
            )}
          />

          <div className="flex flex-row justify-end items-center p-0 gap-3 w-3/12 h-9">
            <Button>Button</Button>
            <Button variant={'filled'}>Button</Button>
          </div>
        </div>
        <div className="mt-1 w-1/3">
          <Controller
            name="moduleName"
            control={control}
            render={({ field }) => {
              const { value, onChange, ...rest } = field;
              return (
                <Select
                  label="Select Nav Item"
                  additional={{
                    page: 0,
                  }}
                  async={true}
                  loadOptions={loadOptions}
                  noOptionsMessage={() => 'No results found'}
                  onChange={(e) => onChange(e)}
                  value={value}
                  {...rest}
                />
              );
            }}
          />
          <p className="text-red-600 text-sm">{errors?.changeTo?.message}</p>
        </div>

        {pageStructure?.map((ele, index) => {
          return ele?.columns?.length > 0 ? (
            <BuilderSection
              key={index}
              index={index}
              onRemove={onRemove}
              onSwapUp={onSwapUp}
              onSwapDown={onSwapDown}
              sectionData={ele}
            />
          ) : (
            <BuilderSectionLayout
              key={index}
              index={index}
              onClick={handleColumns}
              onRemove={onRemove}
            />
          );
        })}

        <Button
          className=" bg-white w-full flex items-center gap-2 justify-center h-fit text-transparent bg-clip-text bg-gradient-to-br from-primaryLeft to-primaryRight font-medium"
          onClick={() => onAdd()}
        >
          +<span className="text-sm">Add Section</span>
        </Button>
      </div>
    </div>
  );
}

export default BuilderMainPanel;
