import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import EditIcon from 'assets/svg/editIcon';
import { useEffect, useState } from 'react';
import ConfigureDashboard from './Configuration/ConfigureDashboard';
import DashboardCanvas from './DashboardCanvas';

export default function CreateDashboard({ module, containerHeight }) {
  const getLayoutFromLocalStorage = (key) => {
    const savedLayout = localStorage.getItem(key);
    return savedLayout ? JSON.parse(savedLayout) : null;
  };
  const [selectedPage, setSelectedPage] = useState(1);
  const [configFor, setConfigFor] = useState('default');
  const [isSidePanelOpen, setSidePanelOpen] = useState(false);
  const [isPagePanelOpen, setPagePanelOpen] = useState(false);
  const [editingPageIndex, setEditingPageIndex] = useState(null);
  const [dashboardCards, setDashboardCards] = useState([
    { pageIndex: 1, pageName: 'Page 1', data: [] },
  ]);
  const [dashboardSettings, setDashboardSettings] = useState({
    settings: {
      spacing: 10,
      backgroundColor: `#eaeaea`,
      title: 'My Dashboard',
      description:
        'This is a sample dashboard. You can configure it as per your needs.',
    },
    source: {},
    filters: {},
  });

  useEffect(() => {
    if (getLayoutFromLocalStorage('dashboardLayout')?.length > 0) {
      setDashboardCards(getLayoutFromLocalStorage('dashboardLayout'));
    } else {
      setDashboardCards([{ pageIndex: 1, pageName: 'Page 1', data: [] }]);
    }
    if (getLayoutFromLocalStorage('dashboardSettings')) {
      setDashboardSettings(getLayoutFromLocalStorage('dashboardSettings'));
    } else {
      setDashboardSettings({
        settings: {
          spacing: 10,
          backgroundColor: `#eaeaea`,
          title: 'My Dashboard',
          description:
            'This is a sample dashboard. You can configure it as per your needs.',
        },
        source: {},
        filters: {},
      });
    }
  }, []);
  useEffect(() => {
    if (configFor !== 'default') {
      const selectedChart = dashboardCards
        ?.find((item) => item?.pageIndex === selectedPage)
        ?.data.find((item) => item?.i === configFor?.i);
      setConfigFor(selectedChart);
    }
  }, [dashboardCards]);
  //{ i: 'chart1', x: 0, y: 0, w: 6, h: 4, data: null }

  // Function to handle drag end event
  // Function to handle drag end event
  const onDragEnd = (result) => {
    //console.log(result);
    // Check if the drag was completed successfully
    // if (!result.destination) {
    //   return;
    // }
    // const { source, destination } = result;
    // // Ensure source and destination are valid
    // if (!destination) {
    //   return;
    // }
    // // If dropped outside the valid drop area
    // if (
    //   source.droppableId === destination.droppableId &&
    //   source.index === destination.index
    // ) {
    //   return;
    // }
    // // Reorder the dashboardCards array based on drag and drop result
    // const items = Array.from(dashboardCards);
    // const [reorderedItem] = items.splice(source.index, 1);
    // items.splice(destination.index, 0, reorderedItem);
    // // Append a new item after the drag operation
    // setDashboardCards((prevCards) => {
    //   const newCard = {
    //     i: `chart${prevCards.length + 1}`, // Generate a unique id
    //     x: prevCards.length * 6, // Adjust x position based on the length of the array
    //     y: Infinity,
    //     w: 6,
    //     h: 4,
    //   };
    //   return [...items, newCard];
    // });
  };
  const deletePage = (pageIndex) => {
    setDashboardCards((prevCards) => {
      const updatedCards = prevCards.filter(
        (item) => item.pageIndex !== pageIndex,
      );
      return updatedCards;
    });
  };

  const handlePageNameChange = (e, pageIndex) => {
    const newPageName = e.target.value;
    setDashboardCards((prevCards) =>
      prevCards.map((card) =>
        card.pageIndex === pageIndex
          ? { ...card, pageName: newPageName }
          : card,
      ),
    );
  };

  return (
    <div className="relative">
      {/* <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}> */}
      <div className={` flex w-full relative h-screen ${containerHeight} `}>
        <div className=" flex absolute top-0 w-80 z-50 h-full left-30">
          {isPagePanelOpen && (
            <div className="bg-white shadow-lg w-96  p-6 ">
              <p className="text-base font-semibold pb-4 flex justify-between w-full items-center">
                Pages{' '}
                <PlusIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={() =>
                    setDashboardCards([
                      ...dashboardCards,
                      {
                        pageIndex: dashboardCards.length + 1,
                        pageName: `Page ${dashboardCards.length + 1}`,
                        data: [],
                      },
                    ])
                  }
                />
              </p>

              <div className=" p-4  pl-0 h-[87%] overflow-y-auto">
                <div className="flex flex-col gap-6 justify-between">
                  {dashboardCards?.map((item, index) => (
                    <div
                      key={index}
                      className={
                        selectedPage === item?.pageIndex
                          ? 'border-b-2 border-black cursor-pointer flex justify-between'
                          : 'flex justify-between'
                      }
                    >
                      <div onClick={() => setSelectedPage(index + 1)}>
                        {editingPageIndex === item.pageIndex ? (
                          <input
                            type="text"
                            value={item.pageName}
                            onChange={(e) =>
                              handlePageNameChange(e, item.pageIndex)
                            }
                            onBlur={() => setEditingPageIndex(null)}
                            autoFocus
                          />
                        ) : (
                          <span className="cursor-pointer">
                            {item?.pageName}
                          </span>
                        )}
                      </div>
                      <div className="flex justify-between">
                        <EditIcon
                          onClick={() => setEditingPageIndex(item.pageIndex)}
                        />
                        <div>
                          <TrashIcon
                            className="w-5 h-5 cursor-pointer text-gray-500"
                            onClick={() => deletePage(item?.pageIndex)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div
            onClick={() => setPagePanelOpen(!isPagePanelOpen)}
            className="bg-white right-0 justify-center border border-l-0 h-8 w-8 shadow flex items-center border-gray-300 rounded-r-md cursor-pointer relative top-4"
          >
            {isPagePanelOpen ? (
              <ChevronLeftIcon className="h-6 w-6" />
            ) : (
              <ChevronRightIcon className="w-5 h-5" />
            )}
          </div>
        </div>
        <div className="w-full overflow-auto">
          <DashboardCanvas
            configFor={configFor}
            setConfigFor={setConfigFor}
            selectedPage={selectedPage}
            layout={dashboardCards}
            setSidePanelOpen={setSidePanelOpen}
            setLayout={setDashboardCards}
            dashboardSettings={dashboardSettings}
          />
        </div>

        <div
          className={`flex absolute top-0 h-full right-0`}
          // ${
          //     module === 'default' ? 'top-[56px] right-0' : 'right-0'
          //   }
        >
          <div
            onClick={() => setSidePanelOpen(!isSidePanelOpen)}
            className="bg-white justify-center border border-r-0 h-8 w-8 shadow flex items-center border-gray-300 rounded-l-md cursor-pointer relative top-4"
          >
            {isSidePanelOpen ? (
              <ChevronRightIcon className="w-5 h-5" />
            ) : (
              <ChevronLeftIcon className="h-6 w-6" />
            )}
          </div>
          {isSidePanelOpen && (
            <div className=" bg-white  h-full">
              <ConfigureDashboard
                dashboardCards={dashboardCards}
                setDashboardCards={setDashboardCards}
                selectedPage={selectedPage}
                configFor={configFor}
                dashboardSettings={dashboardSettings}
                setDashboardSettings={setDashboardSettings}
              />
            </div>
          )}
        </div>
      </div>

      {/* {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext> */}
    </div>
  );
}
