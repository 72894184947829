import React, { useState, useEffect, useCallback } from 'react';
import { useExpanded, useTable } from 'react-table';
import './Table.styles.scss';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

const Table = (props) => {
  const {
    data,
    columns,
    updateMyData,
    isExpandable,
    isSingleRowExpand,
    renderRowSubComponent,
    isSelectable,
    headerBackgroundColor,
    footer,
    myRow,
    headerClassName,
    rowProps = () => ({}),
    ...rest
  } = props;

  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    footerGroups,
  } = useTable(
    {
      data,
      columns,
      updateMyData,
    },
    isExpandable && useExpanded,
    // (hooks) => {
    //   // Customize the plugin to include a selection column
    //   hooks?.visibleColumns?.push((columns) => [
    //     {
    //       id: "selection",
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
    //       ),
    //       Cell: ({ row }) => <input type="checkbox" {...row.getToggleRowSelectedProps()} />,
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  const toggleRowExpanded = useCallback((rowIndex) => {
    setExpandedRowIndex((prevIndex) =>
      prevIndex === rowIndex ? null : rowIndex,
    );
  }, []);

  useEffect(() => {
    if (isExpandable && isSingleRowExpand) {
      rows.forEach((row, index) => {
        if (index === expandedRowIndex) {
          row.toggleRowExpanded(true);
        } else {
          row.toggleRowExpanded(false);
        }
      });
    }
  }, [expandedRowIndex, rows, isExpandable, isSingleRowExpand]);

  return (
    <React.Fragment>
      <table {...getTableProps()} className="w-full table pb-8">
        <thead className={headerClassName}>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, i) => (
                <th
                  key={i}
                  className={`${
                    headerBackgroundColor ? headerBackgroundColor : ''
                  }`}
                  {...column.getHeaderProps()}
                  style={{
                    minWidth: column.minWidth || 'auto',
                    width: column.width || 'auto',
                    borderRight: column.borderRight || 'auto',
                    borderLeft: column.borderLeft || 'auto',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={i}>
                <tr
                  data-myRow={myRow === i ? 'myRow' : ''}
                  {...row.getRowProps(rowProps(row))}
                  // onClick={() => {
                  //   if ((isExpandable, isSingleRowExpand)) {
                  //     toggleRowExpanded(i);
                  //   }
                  // }}
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        key={i}
                        {...cell.getCellProps()}
                        style={{
                          minWidth: cell.column.minWidth || 'auto',
                          width: cell.column.width || 'auto',
                          borderRight: cell.column.borderRight || 'auto',
                          borderLeft: cell.column.borderLeft || 'auto',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr className="childComp">
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
        {footer && (
          <tfoot>
            {footerGroups?.map((footerGroup, i) => (
              <tr {...footerGroup.getFooterGroupProps()} key={i}>
                {footerGroup?.headers?.map((column, i) => (
                  <td {...column.getFooterProps()} key={i}>
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </React.Fragment>
  );
};

export default Table;
