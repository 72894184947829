import { Disclosure } from '@headlessui/react';
import {
  ChevronDownIcon,
  EyeIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import ClockRewind from 'assets/svg/ClockRewind';
import UploadCloud from 'assets/svg/UploadCloud';
import DatePicker from 'components/atoms/FormElements/datepicker/DatePicker';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import Button from 'components/atoms/button/Button';
import FileUploader from 'pages/policy/create/mainPanel/policySteps/FileUploader';
import { useState } from 'react';

export default function DashboardSource({
  dashboardSettings,
  setDashboardSettings,
}) {
  const handleFileSelect = (file) => {
    // console.log(file, 'filedata');
    const newFile = {
      name: file.name,
      uploadDate: new Date(), // Store the current date as the upload date
    };
    // console.log('newFile', newFile);
    const updatedFiles = [...(dashboardSettings?.source?.files || []), newFile];
    setDashboardSettings((prevSettings) => ({
      ...prevSettings,
      source: {
        ...prevSettings.source,
        files: updatedFiles,
      },
    }));
  };
  console.log(dashboardSettings?.source?.files);

  const handleRemoveFile = (index) => {
    const updatedFiles = dashboardSettings.source.files.filter(
      (_, i) => i !== index,
    );
    setDashboardSettings((prevSettings) => ({
      ...prevSettings,
      source: {
        ...prevSettings.source,
        files: updatedFiles,
      },
    }));
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="flex flex-col gap-4 flex-grow -mr-4 pb-2">
          <FileUploader
            acceptedTypes={['.csv']}
            maxSize={50 * 1024 * 1024}
            subText="CSV or xlsx only ( Max size up to 20MB )"
            onFileSelect={handleFileSelect}
          />
          {dashboardSettings?.source?.files?.map((file, index) => (
            <div
              key={index}
              className="w-full p-4 bg-gray-50 border rounded-lg border-gray-200"
            >
              <Disclosure>
                <Disclosure.Button className="group flex w-full items-center justify-between">
                  <span className="text-gray-900 text-base font-semibold">
                    External Data {index + 1}
                  </span>
                  <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
                </Disclosure.Button>
                <Disclosure.Panel className="flex flex-col gap-2 mt-3">
                  <Input
                    label="File Name"
                    value={file.name}
                    onChange={(e) =>
                      setDashboardSettings((prevSettings) => ({
                        ...prevSettings,
                        source: {
                          ...prevSettings.source,
                          files: prevSettings.source.files.map((f, i) =>
                            i === index ? { ...f, name: e.target.value } : f,
                          ),
                        },
                      }))
                    }
                  />
                  <div className="w-full">
                    <DatePicker
                      label="Upload Date"
                      value={formatDate(file.uploadDate)}
                      readOnly
                    />
                  </div>
                  <div className="w-full">
                    <Select label="Primary Key" />
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <UploadCloud />
                      <EyeIcon className="w-3.5 h-3.5 text-gray-600" />
                      <ClockRewind />
                    </div>
                    <TrashIcon
                      className="w-3.5 h-3.5 text-gray-600 cursor-pointer"
                      onClick={() => handleRemoveFile(index)}
                    />
                  </div>
                </Disclosure.Panel>
              </Disclosure>
            </div>
          ))}
        </div>
        <div className=" sticky bottom-8 flex flex-col pt-3 gap-4 pr-6 bg-white -mr-4">
          <hr className="bg-gray-200" />
          <div className="text-right">
            <Button>Save</Button>
          </div>
        </div>
      </div>
    </>
  );
}
