function checkRoleAccess(rbac, parentFlag, flagName) {
  let access = false;
  let module;
  if (rbac && rbac.length > 0) {
    module = rbac.filter(
      (item) => item.flagName === parentFlag && item.flagValue === true,
    );
    if (
      (parentFlag === 'Surface' ||
        parentFlag === 'SurfaceSurvey' ||
        parentFlag === 'SurfaceAnnouncement') &&
      (flagName === 'ManageCreatesurveys' || flagName === 'ManageAnnouncement')
    ) {
      module = rbac.filter((item) => item.flagName === 'Surface');
    }
    if (module.length > 0) {
      if (module[0].hierarchy?.length > 0) {
        access = module[0].hierarchy.some(
          (item) => item.flagName === flagName && item.flagValue === true,
        );
      }
    } else access = false;
  }
  return access;
}

function checkModuleAccessSidebar(modules = [], flagName, name, userAccess) {
  const moduleArray = [
    ...modules,
    {
      flagName: 'Admin',
      flagValue: true,
    },
    {
      flagName: 'Task',
      flagValue: true,
    },
    {
      flagName: 'Default',
      flagValue: true,
    },
  ];

  let module = false;
  let access = true;
  if (moduleArray && moduleArray.length > 0) {
    module = moduleArray.some(
      (item) => item.flagName === flagName && item.flagValue === true,
    );

    if (flagName === 'SurfaceCommunities') {
      access = checkRoleAccess(userAccess, 'Surface', 'ManageCommunities');
    }
    if (flagName === 'Recognition') {
      access =
        checkRoleAccess(userAccess, 'Recognition', 'Accesstoredeempoints') ||
        checkRoleAccess(userAccess, 'Recognition', 'RecognitionChampions') ||
        checkRoleAccess(
          userAccess,
          'Recognition',
          'Managerecognitionprogramsandawards',
        );
    }
    if (flagName === 'Compensation' && name === 'Compensation') {
      access =
        checkRoleAccess(userAccess, 'Compensation', 'RewardsPhilosophy') ||
        checkRoleAccess(userAccess, 'Compensation', 'ManageCompensationBonus');
    }
    if (flagName === 'Compensation' && name === 'Compensation Planning') {
      access =
        checkRoleAccess(userAccess, 'Compensation', 'CompensationWorksheets') ||
        checkRoleAccess(
          userAccess,
          'Compensation',
          'CompensationWorksheetsAdmin',
        ) ||
        checkRoleAccess(userAccess, 'Compensation', 'IncrementPhilosophy') ||
        checkRoleAccess(userAccess, 'Compensation', 'Managecompensationplans');
    }
    if (flagName === 'Policy') {
      access =
        checkRoleAccess(userAccess, 'Policy', 'ViewCompanyPolicies') ||
        checkRoleAccess(userAccess, 'Policy', 'Managecompanypolicies');
    }
    if (flagName === 'Benefit') {
      access =
        checkRoleAccess(
          userAccess,
          'Benefit',
          'Administerandmanagecompanybenefits',
        ) ||
        checkRoleAccess(userAccess, 'Benefit', 'Accessandviewbenefitsforself');
    }
    if (flagName === 'Esop') {
      access =
        checkRoleAccess(userAccess, 'Profile', 'EsopTab') ||
        checkRoleAccess(userAccess, 'Esop', 'TransactEquity');
    }
    if (flagName === 'Insurance') {
      access =
        checkRoleAccess(userAccess, 'Insurnace', 'EnrolAndClaimInsurance') ||
        checkRoleAccess(userAccess, 'Insurance', 'ManageInsuranceClaim');
    }
  } else module = false;

  return module && access;
}

function checkModuleAccess(modules = [], flagName) {
  const moduleArray = [
    ...modules,
    {
      flagName: 'Admin',
      flagValue: true,
    },
    {
      flagName: 'Task',
      flagValue: true,
    },
    {
      flagName: 'Default',
      flagValue: true,
    },
  ];

  let module = false;
  if (moduleArray && moduleArray.length > 0) {
    module = moduleArray.some(
      (item) => item.flagName === flagName && item.flagValue === true,
    );
  } else module = false;

  return module;
}

const isSurface = (modules) => {
  return (
    checkModuleAccess(modules, 'Surface') ||
    checkModuleAccess(modules, 'Recognition')
  );
};

const isHome = (modules) => {
  // const adminModule = ['Profile', 'Admin', 'Analytics'];
  // return (
  //   (!checkModuleAccess(modules, 'Surface') &&
  //     !checkModuleAccess(modules, 'Recognition')) ||
  //   (!checkModuleAccess(modules, 'Surface') &&
  //     checkModuleAccess(modules, 'Recognition') &&
  //     modules?.filter(
  //       (module) =>
  //         module?.parent === null &&
  //         module?.flagValue &&
  //         !adminModule?.includes(module?.flagName),
  //     )?.length > 1)
  // );
  return true; // !Warning --> The above code is commented just to show both surface and home with all functionalities in demo
};

export {
  checkModuleAccess,
  checkModuleAccessSidebar,
  checkRoleAccess,
  isHome,
  isSurface,
};
