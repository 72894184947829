import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { handleErrorImage } from 'utils/handleErrorImage';

const CompanyProfile = () => {
  const theme = useSelector((state) => state.theme);
  return (
    <div className=" ml-2 w-full ">
      <Link to="/" className="w-full">
        <div className="flex  items-center p-0 w-full h-full ">
          <div className=" w-40 h-10">
            <img
              src={theme.logo}
              alt="logo"
              className="w-full h-full object-contain"
              onError={handleErrorImage}
            />
          </div>

          <p className="pl-2 font-bold text-sm w-full capitalize flex items-center h-8">
            {theme.brandName}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default CompanyProfile;
