import React from 'react';
import { htmlParser } from 'utils/jsUtils';

const ViewParagraphAtom = (props) => {
  const { columnData } = props;

  const flex = columnData?.attr?.flex?.justify;

  return (
    <div
      style={{ justifyContent: flex }}
      className={`w-full flex flex-col break-words`}
    >
      <p>{htmlParser(columnData?.attr?.para?.value)}</p>
    </div>
  );
};

export default ViewParagraphAtom;
