import VerticalEclipse from 'assets/svg/verticalEclipse';
import Avatar from 'layouts/header/Avatar';
import { imageURLCheck } from 'utils/ImageUrlCheck';

export const VersionHistory = ({ versionHistory }) => {
  console.log(versionHistory, 'version');
  const formattedDate = (dateObject) => {
    // Extract day, month, and year components from the Date object
    const day = dateObject.getDate().toString().padStart(2, '0'); // Ensure two digits (padStart)
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = dateObject.getFullYear();

    // Format the components into "dd/mm/yyyy" format
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="space-y-3">
      {versionHistory?.calculationHistory?.map((data) => {
        return (
          <div
            key={data?.id}
            className="flex justify-between border border-gray-200 rounded-xl p-4"
          >
            <div className="space-y-4">
              <p className="text-sm font-semibold text-gray-900">
                {formattedDate(new Date(data?.createdDate))}
              </p>
              <div className="space-y-1">
                <p className="text-gray-500 flex items-center gap-1">
                  <span>Min Salary:</span>
                  <span className="text-gray-900">
                    {data?.minSalary?.toLocaleString()}
                  </span>
                  <span className="line-through">
                    {data?.previousVersion?.minSalary?.toLocaleString()}
                  </span>
                </p>
                <p className="text-gray-500 flex items-center gap-1">
                  <span>Max Salary:</span>
                  <span className="text-gray-900">
                    {data?.maxSalary?.toLocaleString()}
                  </span>
                  <span className="line-through">
                    {data?.previousVersion?.maxSalary?.toLocaleString()}
                  </span>
                </p>
              </div>
              <div className="flex items-center gap-3">
                <Avatar
                  userName={
                    versionHistory?.userData?.filter(
                      (user) => user?.userId === data?.createdBy,
                    )?.[0]?.fullName?.value
                  }
                  src={imageURLCheck(
                    versionHistory?.userData?.filter(
                      (user) => user?.userId === data?.createdBy,
                    )?.[0]?.avatar?.value,
                  )}
                />
                <div>
                  <p className="font-semibold text-sm text-gray-900">
                    {
                      versionHistory?.userData?.filter(
                        (user) => user?.userId === data?.createdBy,
                      )?.[0]?.fullName?.value
                    }
                  </p>
                  <p className="text-sm text-gray-500">
                    {formattedDate(new Date(data?.createdDate))}
                  </p>
                </div>
              </div>
            </div>
            <VerticalEclipse className="w-5 h-5" />
          </div>
        );
      })}
    </div>
  );
};
