import React from 'react';
import ViewImageAtom from './viewColumnAtomsMolecules/viewImageAtom/ViewImageAtom';
import ViewVideoAtom from './viewColumnAtomsMolecules/viewVideoAtom/ViewVideoAtom';
import ViewHeadingAtom from './viewColumnAtomsMolecules/viewHeadingAtom/ViewHeadingAtom';
import ViewButtonAtom from './viewColumnAtomsMolecules/viewButtonAtom/ViewButtonAtom';
import ViewDividerAtom from './viewColumnAtomsMolecules/viewDividerAtom/ViewDividerAtom';
import ViewSpacerAtom from './viewColumnAtomsMolecules/viewSpacerAtom/ViewSpacerAtom';
import ViewIHPMolecule from './viewColumnAtomsMolecules/viewIHPMolecule/ViewIHPMolecule';
import ViewHPMolecule from './viewColumnAtomsMolecules/viewHPMolecule/ViewHPMolecule';
import ViewVHPMolecule from './viewColumnAtomsMolecules/viewVHPMolecule/ViewVHPMolecule';
import ViewHPBMolecule from './viewColumnAtomsMolecules/viewHPBMolecule/ViewHPBMolecule';
import ViewParagraphAtom from './viewColumnAtomsMolecules/viewParagraphAtom/ViewParagraphAtom';

const ViewTemplateSection = (props) => {
  const { columnsData } = props;
  const sortedData = columnsData.columns
    .slice()
    .sort((a, b) => a.column.order - b.column.order);
  return (
    <div
      className={`${
        columnsData.columns.length === 2 &&
        'ring-1 rounded-xl ring-gray-200 p-5 shadow-md'
      }  
w-full flex-col justify-between  `}
    >
      <div
        className={`grid ${
          columnsData.columns.length > 1 &&
          'max-xl:grid-cols-2 max-lg:grid-cols-1 gap-4'
        } grid-cols-${columnsData.columns.length}  ${
          columnsData.columns.length === 2 && 'xl:gap-14  max-xl:gap-7'
        } ${
          columnsData.columns.length > 1 && 'place-items-center'
        } place-content-start`}
      >
        {sortedData.map((column, i) => {
          switch (column.column.attr.type) {
            case 'Heading':
              return <ViewHeadingAtom key={i} columnData={column.column} />;
            case 'Paragraph':
              return <ViewParagraphAtom key={i} columnData={column.column} />;
            case 'Image':
              return <ViewImageAtom key={i} columnData={column.column} />;
            case 'Video':
              return <ViewVideoAtom key={i} columnData={column.column} />;
            case 'Button':
              return <ViewButtonAtom key={i} columnData={column.column} />;
            case 'Divider':
              return <ViewDividerAtom key={i} columnData={column.column} />;
            case 'Spacer':
              return <ViewSpacerAtom key={i} columnData={column.column} />;
            case 'IHP':
              return (
                <ViewIHPMolecule
                  key={i}
                  columnData={column.column}
                  card={columnsData.columns.length > 2 && true}
                />
              );
            case 'HP':
              return (
                <ViewHPMolecule
                  key={i}
                  columnData={column.column}
                  card={columnsData.columns.length > 2 && true}
                />
              );
            case 'HPB':
              return (
                <ViewHPBMolecule
                  key={i}
                  columnData={column.column}
                  card={columnsData.columns.length > 2 && true}
                />
              );
            case 'VHP':
              return (
                <ViewVHPMolecule
                  key={i}
                  columnData={column.column}
                  card={columnsData.columns.length > 2 && true}
                />
              );
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};

export default ViewTemplateSection;
