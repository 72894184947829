import React from 'react';
import { Navigate } from 'react-router-dom';

const ManageBenefits = React.lazy(() =>
  import('pages/benefits/manageBenefits/ManageBenefits').then((module) => ({
    default: module.ManageBenefits,
  })),
);
const ManagePrograms = React.lazy(() =>
  import('pages/benefits/managePrograms/ManagePrograms').then((module) => ({
    default: module.ManagePrograms,
  })),
);
const CreateBenefit = React.lazy(() =>
  import('pages/benefits/createBenefit/CreateBenefit'),
);
const BenefitsDashboard = React.lazy(() =>
  import('pages/benefits/benefitsDashboard/BenefitsDashboard').then(
    (module) => ({
      default: module.BenefitsDashboard,
    }),
  ),
);
const BenefitEnrolment = React.lazy(() =>
  import('pages/benefits/benefitsDashboard/benefitEnrolment/BenefitEnrolment'),
);
const BenefitClaim = React.lazy(() =>
  import('pages/benefits/benefitsDashboard/benefitClaim/BenefitClaim'),
);
const ClaimHistory = React.lazy(() =>
  import('pages/benefits/benefitsDashboard/claimHistory/ClaimHistory').then(
    (module) => ({
      default: module.ClaimHistory,
    }),
  ),
);

const benefitsRoutes = [
  {
    type: 'collapse',
    name: 'benefits',
    key: 'benefits',
    parentId: 'Benefit',
    route: '/benefits/manage-program',
    module: 'Benefit',
    component: <Navigate to="/manage-program/standard" />,
  },
  {
    type: 'collapse',
    name: 'manage-programs',
    key: 'manage-programs',
    parentId: 'Benefit',
    flagName: 'Administerandmanagecompanybenefits',
    route: '/manage-program/:type',
    module: 'Benefit',
    component: <ManagePrograms />,
  },
  {
    type: 'collapse',
    name: 'createBenefit',
    key: 'createBenefit',
    route: '/benefit/create',
    parentId: 'Benefit',
    module: 'Benefit',
    component: <CreateBenefit />,
  },
  {
    type: 'collapse',
    name: 'manage-benefits',
    key: 'manage-benefits',
    parentId: 'Benefit',
    route: '/benefits/manage-benefits',
    flagName: 'Administerandmanagecompanybenefits',
    module: 'Benefit',
    component: <Navigate to="/manage-benefits/active" />,
  },
  {
    type: 'collapse',
    name: 'manage-benefits-type',
    key: 'manage-benefits-type',
    parentId: 'Benefit',
    route: '/manage-benefits/:type',
    flagName: 'Administerandmanagecompanybenefits',
    module: 'Benefit',
    component: <ManageBenefits />,
  },
  {
    type: 'collapse',
    name: 'createBenefit',
    key: 'createBenefit',
    parentId: 'Benefit',
    route: '/benefit/update/:benefitId',
    module: 'Benefit',
    component: <CreateBenefit />,
  },
  {
    type: 'collapse',
    name: 'employeeBenefitsDashboard',
    key: 'employeeBenefitsDashboard',
    parentId: 'Benefit',
    route: '/benefits/benefits-dashboard',
    module: 'Benefit',
    flagName: 'Accessandviewbenefitsforself',
    component: <BenefitsDashboard />,
  },
  {
    type: 'collapse',
    name: 'reportLifeEvent',
    key: 'reportLifeEvent',
    parentId: 'Benefit',
    route: '/benefits/report-life-event',
    module: 'Benefit',
    component: <BenefitsDashboard />,
  },
  {
    type: 'collapse',
    name: 'benefitEnrolment',
    key: 'benefitEnrolment',
    parentId: 'Benefit',
    route: '/benefits/enrolment/:enrolmentId',
    module: 'Benefit',
    component: <BenefitEnrolment />,
  },
  {
    type: 'collapse',
    name: 'benefitClaim',
    key: 'benefitClaim',
    route: '/benefits/claim/:claimId',
    parentId: 'Benefit',
    module: 'Benefit',
    component: <BenefitClaim />,
  },
  {
    type: 'collapse',
    name: 'benefitClaim',
    key: 'benefitClaim',
    route: '/benefits/claim-history',
    parentId: 'Benefit',
    module: 'Benefit',
    component: <ClaimHistory />,
  },
];

export default benefitsRoutes;
