const PencilIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      stroke="url(#pencil_icon)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.67}
      d="M10 3H5.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C1 5.28 1 6.12 1 7.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C3.28 21 4.12 21 5.8 21h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.31C19 18.72 19 17.88 19 16.2V12M7 15h1.675c.489 0 .733 0 .963-.055a2 2 0 0 0 .579-.24c.201-.123.374-.296.72-.642L20.5 4.5a2.121 2.121 0 0 0-3-3l-9.563 9.563c-.346.346-.519.519-.642.72a2 2 0 0 0-.24.579c-.055.23-.055.474-.055.964V15Z"
    />
    <defs>
      <linearGradient
        id="pencil_icon"
        x1={1}
        x2={21.121}
        y1={21}
        y2={21}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--primaryLeft)" />
        <stop offset={1} stopColor="#F70" />
      </linearGradient>
    </defs>
  </svg>
);
export default PencilIcon;
