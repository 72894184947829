import React from 'react';
import AutosizeInput from 'react-input-autosize';
import './ResizableInput.styles.scss';

const ResizableInput = React.forwardRef((props, ref) => {
  const { className, ...rest } = props;

  return (
    <AutosizeInput
      autoComplete="none"
      ref={ref}
      className={`${className}`}
      inputClassName="resizable-input"
      {...rest}
    />
  );
});

export default ResizableInput;
