import React from 'react';

function UserNameAvatar({ userName, className, ...rest }) {
  return (
    <div
      className={`rounded-full border bg-gradient-to-br from-primaryLeft to-primaryRight text-white font-semibold text-sm h-10 w-10 flex justify-center items-center ${className}`}
      {...rest}
    >
      {userName?.split(' ')?.[0]?.[0]}
      {userName?.split(' ')?.[1]?.[0]}
    </div>
  );
}

export default UserNameAvatar;
