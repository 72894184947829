import { PlusIcon } from '@heroicons/react/24/outline';
import {
  fetchCustomPValues,
  fetchFixedPValues,
} from 'apiClient/rangeBuilder/rangeBuilder';
import DeleteFormIcon from 'assets/svg/deleteFormIcon';
import {
  CheckBoxProgram,
  Input,
} from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import FormulaBuilder from 'components/molecules/formulaBuilder/FormulaBuilder';
import {
  calculationReference4Points,
  calculationReferenceAsIndividualPoint,
  calculationReferenceAsMidPoint,
} from 'config/rangeBuilderData';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { CreateNewPValueModal } from './CreateNewPValueModal';

export const SetBenchMarks = ({ planDetails, setPlanDetails }) => {
  const [formulaBuilderVisible, setFormulaBuilderVisible] = useState(false);
  const [fixedPValues, setFixedPValues] = useState([]);
  const [customPValues, setCustomPValues] = useState([]);
  const [displayOptions, setDisplayOptions] = useState([]);
  const [percentilePointsSelected, setPercentilePointsSelected] =
    useState(customPValues);
  const [newPercentileValueVisible, setNewPercentileValueVisible] =
    useState(false);
  const [createNewPValuePopUpVisible, setCreateNewPValuePopUpVisible] =
    useState(false);
  const [calculationsData, setCalculationsData] = useState([]);

  const [benchmarks, setBenchmarks] = useState([
    {
      id: v4(),
      columnName: '',
      isActive: true,
      calculation: {
        formulaString: '',
        displayFormula: '',
        dependencyValueMap: {},
        dependencyChainMap: {},
      },
    },
  ]);

  const getFixedPValues = async () => {
    try {
      const response = await fetchFixedPValues();
      setFixedPValues(
        response?.data?.map((pValue) => {
          return { ...pValue, label: pValue?.name };
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomPValues = async () => {
    try {
      const response = await fetchCustomPValues();
      setCustomPValues(
        response?.data?.map((pValue) => {
          return { ...pValue, label: pValue?.name };
        }),
      );
      setPercentilePointsSelected(response?.data?.map((pValue) => pValue?.id));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getFixedPValues();
      await getCustomPValues();
    })();
  }, []);

  const handleSetBenchmark = (formulaDetails, benchmark) => {
    setBenchmarks(
      benchmarks?.map((data) => {
        if (data?.id === benchmark?.id) {
          return {
            ...data,
            calculation: formulaDetails,
          };
        }
        return data;
      }),
    );
  };

  const handleSetBenchmarkName = (name, benchmark) => {
    setBenchmarks(
      benchmarks?.map((data) => {
        if (data?.id === benchmark?.id) {
          return { ...data, columnName: name };
        }
        return data;
      }),
    );
  };

  const handleNewBenchmark = () => {
    setBenchmarks([
      ...benchmarks,
      {
        id: v4(),
        columnName: '',
        isActive: true,
        calculation: {
          formulaString: '',
          displayFormula: '',
          dependencyValueMap: {},
          dependencyChainMap: {},
        },
      },
    ]);
  };

  const handleRemove = (benchmarkId) =>
    benchmarks?.length > 1 &&
    setBenchmarks(benchmarks?.filter((data) => data?.id !== benchmarkId));

  useEffect(() => {
    if (
      planDetails?.modeOfCalculationReference === '3 point' &&
      planDetails?.modeOfCalculationOutput === 'Range Based on Mid Point'
    ) {
      setDisplayOptions(calculationReferenceAsMidPoint);
    } else if (
      planDetails?.modeOfCalculationReference === '3 point' &&
      planDetails?.modeOfCalculationOutput === 'Individual Reference Points'
    ) {
      setDisplayOptions(calculationReferenceAsIndividualPoint);
    } else if (planDetails?.modeOfCalculationReference === '4 point') {
      setDisplayOptions(calculationReference4Points);
    } else if (planDetails?.modeOfCalculationReference === 'Multi point') {
      setDisplayOptions(
        customPValues?.filter((multiPoints) =>
          percentilePointsSelected?.includes(multiPoints?.id),
        ),
      );
    }
  }, [
    planDetails?.modeOfCalculationReference,
    planDetails?.modeOfCalculationOutput,
    percentilePointsSelected,
  ]);

  const handlePercentilePointSelection = (percentilePoint) => {
    setPercentilePointsSelected(
      percentilePointsSelected?.includes(percentilePoint)
        ? percentilePointsSelected?.filter((point) => point !== percentilePoint)
        : [...percentilePointsSelected, percentilePoint],
    );
  };

  useEffect(() => {
    setPlanDetails({
      ...planDetails,
      benchmarkColumns: benchmarks,
    });
  }, [benchmarks]);

  useEffect(() => {
    setPlanDetails({ ...planDetails, calculations: '' });
  }, [
    planDetails?.modeOfCalculationOutput,
    planDetails?.modeOfCalculationReference,
  ]);

  const handleSetValue = (inputValue, displayOption, benchmark) => {
    console.log(displayOption, 'opt');
    setCalculationsData(
      calculationsData?.map((data) => {
        if (data?.id === benchmark?.id) {
          return {
            ...data,
            benchmark: benchmark?.columnName,
            values: data?.values?.map((value) => {
              if (value?.point === displayOption?.label) {
                return {
                  ...value,
                  percentValue: inputValue?.split('%')?.[0],
                };
              }
              return value;
            }),
          };
        }
        return data;
      }),
    );
  };

  const handleSetOptions = (option, displayOption, benchmark) => {
    if (
      calculationsData?.filter((data) => data?.id === benchmark?.id)?.length
    ) {
      setCalculationsData(
        calculationsData?.map((data) => {
          if (data?.id === benchmark?.id) {
            return {
              ...data,
              benchmark: benchmark?.columnName,
              values: data?.values?.filter(
                (value) => value?.point === displayOption?.label,
              )?.length
                ? data?.values?.map((value) => {
                    if (value?.point === displayOption?.label) {
                      return {
                        point: displayOption?.label,
                        individualReference: option?.id,
                      };
                    }
                    return value;
                  })
                : [
                    ...(data?.values || []),
                    {
                      point: displayOption?.label,
                      individualReference: option?.id,
                    },
                  ],
            };
          }
          return data;
        }),
      );
    } else {
      setCalculationsData([
        ...calculationsData,
        {
          id: benchmark?.id,
          benchmark: benchmark?.columnName,
          values: [
            {
              point: displayOption?.label,
              individualReference: option?.id,
            },
          ],
        },
      ]);
    }
  };

  const handleSetOptionsMidAsReference = (option, displayOption, benchmark) => {
    if (
      calculationsData?.filter((data) => data?.id === benchmark?.id)?.length
    ) {
      setCalculationsData(
        calculationsData?.map((data) =>
          data?.id === benchmark?.id
            ? {
                ...data,
                benchmark: benchmark?.columnName,
                values: data?.values?.map((point) => {
                  return {
                    ...point,
                    benchmark: benchmark?.columnName,
                    individualReference:
                      displayOption?.label === point?.point
                        ? option?.id
                        : 'Mid',
                  };
                }),
              }
            : data,
        ),
      );
    } else {
      setCalculationsData([
        ...calculationsData,
        {
          id: benchmark?.id,
          benchmark: benchmark?.columnName,
          values: [
            {
              point: 'Min',
              individualReference: 'Mid',
              percentValue: '',
            },
            {
              point: 'Max',
              individualReference: 'Mid',
              percentValue: '',
            },
            {
              point: 'Mid',
              individualReference: option?.id,
              percentValue: '',
            },
          ],
        },
      ]);
    }
  };

  const handleSetValueMidAsReference = (value, displayOption, benchmark) => {
    if (
      calculationsData?.filter((data) => data?.id === benchmark?.id)?.length
    ) {
      setCalculationsData(
        calculationsData?.map((data) =>
          data?.id === benchmark?.id
            ? {
                ...data,
                benchmark: benchmark?.columnName,
                values: data?.values?.map((point) => {
                  return {
                    ...point,
                    percentValue:
                      displayOption?.label === point?.point
                        ? value
                        : point?.percentValue,
                  };
                }),
              }
            : data,
        ),
      );
    } else {
      setCalculationsData([
        ...calculationsData,
        {
          id: benchmark?.id,
          benchmark: benchmark?.columnName,
          values: [
            {
              point: 'Min',
              individualReference: 'Mid',
              percentValue: displayOption?.label === 'Min' ? value : '',
            },
            {
              point: 'Max',
              individualReference: 'Mid',
              percentValue: displayOption?.label === 'Max' ? value : '',
            },
            {
              point: 'Mid',
              individualReference: '',
              percentValue: displayOption?.label === 'Mid' ? value : '',
            },
          ],
        },
      ]);
    }
  };

  useEffect(() => {
    setCalculationsData([]);
  }, [
    planDetails?.modeOfCalculationOutput,
    planDetails?.modeOfCalculationReference,
  ]);

  useEffect(() => {
    setPlanDetails({ ...planDetails, calculations: calculationsData });
  }, [calculationsData]);

  return (
    <div className="px-5 pt-6 pb-5 space-y-5 bg-white rounded-xl shadow-md">
      <div className="flex items-center justify-between">
        <p className="text-sm font-semibold text-gray-900 pb-[10px]">
          Set Benchmarks
        </p>
        <p
          className="mr-2 font-medium text-sm text-gray-700 flex items-center cursor-pointer"
          onClick={() => handleNewBenchmark()}
        >
          <PlusIcon className="w-4 h-4 text-gray-700" /> Add New
        </p>
      </div>
      <div className="flex items-center justify-between flex-wrap">
        {benchmarks?.map((benchmark, index) => {
          return (
            <div
              className={`bg-gray-50 p-6 w-full ${
                benchmarks?.length % 2 === 0
                  ? index === benchmarks?.length - 1 ||
                    index === benchmarks?.length - 2
                    ? ''
                    : 'mb-8'
                  : index === benchmarks?.length - 1
                  ? ''
                  : 'mb-8'
              } rounded-lg space-y-3`}
              key={benchmark?.id}
            >
              <div
                className={`w-full ${
                  planDetails?.modeOfCalculationReference === '3 point'
                    ? 'flex items-center gap-6'
                    : 'space-y-6'
                }`}
              >
                <div className="gap-6 flex items-center w-full">
                  <Input
                    placeholder="Enter"
                    label="Display Name"
                    className="!px-3 !py-2"
                    onChange={(event) =>
                      handleSetBenchmarkName(event.target.value, benchmark)
                    }
                  />
                  {!formulaBuilderVisible && (
                    <Input
                      label="Formula"
                      onClick={() => setFormulaBuilderVisible(true)}
                      defaultValue={benchmark?.calculation?.displayFormula}
                      className="!px-3 !py-2"
                      placeholder="Enter Formula"
                    />
                  )}
                  {formulaBuilderVisible && (
                    <FormulaBuilder
                      name="Formula"
                      setModel={setFormulaBuilderVisible}
                      saveAction={(formulaDetails) =>
                        handleSetBenchmark(formulaDetails, benchmark)
                      }
                      moduleName="RangeBuilder"
                      formulaDetails={benchmark?.calculation}
                    />
                  )}
                </div>
                {planDetails?.modeOfCalculationReference === 'Multi point' && (
                  <div className="space-y-[6px]">
                    <p className="text-xs font-medium text-gray-700">
                      Positions
                    </p>
                    {planDetails?.modeOfCalculationReference ===
                      'Multi point' && (
                      <div className="flex items-center px-5 gap-3">
                        {customPValues?.map((percentilePoint) => {
                          return (
                            <CheckBoxProgram
                              key={percentilePoint}
                              label={percentilePoint?.name}
                              checked={percentilePointsSelected?.includes(
                                percentilePoint?.id,
                              )}
                              onChange={() =>
                                handlePercentilePointSelection(
                                  percentilePoint?.id,
                                )
                              }
                            />
                          );
                        })}
                        {newPercentileValueVisible && (
                          <div className="w-20">
                            <Input
                              onChnge={(event) =>
                                setPercentilePointsSelected([
                                  ...percentilePointsSelected,
                                  event.target.value,
                                ])
                              }
                            />
                          </div>
                        )}
                        <p
                          className="gap-2 flex items-center cursor-pointer text-sm font-semibold text-gray-600"
                          onClick={() => setCreateNewPValuePopUpVisible(true)}
                        >
                          <PlusIcon className="w-5 h-5 text-gray-600" />
                          Create New
                        </p>
                      </div>
                    )}
                  </div>
                )}
                <div className="flex items-center gap-6 w-full">
                  {displayOptions?.map((displayOption) => {
                    return (
                      <div
                        key={displayOption?.id}
                        className="flex flex-col space-y-[6px] w-full"
                      >
                        <p className="text-xs font-medium text-gray-700">
                          {displayOption?.label}
                          {planDetails?.modeOfCalculationOutput ===
                            'Range Based on Mid Point' &&
                            displayOption?.label !== 'Mid' && (
                              <span className="font-normal text-gray-500">{` (as a Percentage of Mid)`}</span>
                            )}
                        </p>
                        <div className="flex items-center border border-gray-300 rounded-[10px]">
                          {((planDetails?.modeOfCalculationOutput ===
                            'Range Based on Mid Point' &&
                            displayOption?.label !== 'Min' &&
                            displayOption?.label !== 'Max') ||
                            planDetails?.modeOfCalculationOutput !==
                              'Range Based on Mid Point') && (
                            <Select
                              options={fixedPValues}
                              id="remove-border-select"
                              onChange={(option) =>
                                planDetails?.modeOfCalculationOutput ===
                                'Range Based on Mid Point'
                                  ? handleSetOptionsMidAsReference(
                                      option,
                                      displayOption,
                                      benchmark,
                                    )
                                  : handleSetOptions(
                                      option,
                                      displayOption,
                                      benchmark,
                                    )
                              }
                              // value={
                              //   fixedPValues?.filter(
                              //     (option) =>
                              //       option.value ===
                              //       calculationsData
                              //         ?.filter(
                              //           (data) =>
                              //             data?.compensationType ===
                              //             type?.value,
                              //         )?.[0]
                              //         ?.values?.filter(
                              //           (value) =>
                              //             value?.point === displayOption?.value,
                              //         )?.[0]?.individualReference,
                              //   )?.[0]
                              // }
                              // isDisabled={sameAsBasePaySelectedTypes?.includes(
                              //   type?.value,
                              // )}
                            />
                          )}
                          <Input
                            className={`!py-2 !px-3 ${
                              (planDetails?.modeOfCalculationOutput ===
                                'Range Based on Mid Point' &&
                                displayOption?.label !== 'Min' &&
                                displayOption?.label !== 'Max') ||
                              planDetails?.modeOfCalculationOutput !==
                                'Range Based on Mid Point'
                                ? 'rounded-l-none border-r-0 border-y-0 border-gray-300'
                                : 'border-0'
                            } ${
                              // sameAsBasePaySelectedTypes?.includes(type?.value)
                              //   ? 'bg-gray-50 text-black-300'
                              //   : ''
                              ''
                            }`}
                            onChange={(event) =>
                              planDetails?.modeOfCalculationOutput ===
                              'Range Based on Mid Point'
                                ? handleSetValueMidAsReference(
                                    event.target.value,
                                    displayOption,
                                    benchmark,
                                  )
                                : handleSetValue(
                                    event.target.value,
                                    displayOption,
                                    benchmark,
                                  )
                            }
                            placeholder="Enter"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <p
                className={`flex items-center justify-end gap-[6px] text-sm font-medium text-gary-700  ${
                  benchmarks?.length === 1
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer'
                }`}
                onClick={() => handleRemove(benchmark?.id)}
              >
                <DeleteFormIcon /> Remove
              </p>
            </div>
          );
        })}
      </div>
      {createNewPValuePopUpVisible && (
        <CreateNewPValueModal
          setModalVisible={setCreateNewPValuePopUpVisible}
        />
      )}
    </div>
  );
};
