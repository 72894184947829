import { get, post } from 'apiClient/apiCalls';
import * as CONSTANT from '../api.constant';

export const getDropDownValues = async (code) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/dropdown-values?code=${code}`,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getHiringOffers = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/get-hiring-offers`,
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getHiringVersion = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/get-hiring-version/${id}`,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getHiringProposals = async (versionId, hiringId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${
        CONSTANT.HIRING_V3
      }/hiring/get-hiring-proposal?versionId=${versionId}${
        hiringId ? `&hiringId=${hiringId}` : ''
      }`,
    );
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getCompensationFields = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/get-compensation-fields`,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const createOffer = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/create-hiring-offer`,
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const createHiringVersion = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/create-hiring-version`,
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const deleteHiringVersion = async (id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/delete-hiring-version/${id}`,
      {},
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const deleteHiringProposal = async (id) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/delete-hiring-proposal/${id}`,
      {},
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const updateHiringVersion = async (id, payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/update-hiring-version/${id}`,
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const updateOffer = async (id, payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/update-hiring-offer`,
      // /${id}`,
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getCompensationAmount = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/get-components-hiring`,
      payload,
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};
export const createOfferApprover = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring-approval/create-approval-step`,
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getOfferApprovers = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring-approval/get-approval-step/${id}`,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const exportOffer = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/manage-offer/export-offers`,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

// hiring v2 apis
export const getMasterDropdown = async (query, pageIndex, pageSize, body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/get-dropdown/${query}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      body,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getCandidateFields = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/get-candidate-fields`,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getJobFields = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/get-job-fields`,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getHiringVersionById = async (id, type) => {
  try {
    const url = `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/get-hiring-version-by-id`;
    const queryParam = type === 'new' ? `hiringId=${id}` : `versionId=${id}`;
    const response = await get(`${url}?${queryParam}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getEmployeeFields = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring/get-employee-fields`,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getReleaseOfferDetails = async (versionId, hiringId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/manage-offer/realese-offer-details/${hiringId}/${versionId}`,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getApprovalStatus = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring-approval/get-approval-status/${id}`,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const updateApprovalStatus = async (id, body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/hiring-approval/update-approval-status/${id}`,
      body,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const releaseOffer = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/manage-offer/realese-offer`,
      body,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getOfferTemplate = async () => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/manage-offer/offer-template`,
      {},
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getWorkflowApprovers = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/compensation-plan/workflow-step`,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const previewHiringTemplate = async (body) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.COMPENSATION_V3}/letter/generate-hiring`,
      body,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getSalaryRange = async (payload, jobcodeId, currencyId) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${
        CONSTANT.HIRING_V3
      }/hiring/get-salary-range/${jobcodeId}${
        currencyId ? `/${currencyId}` : ''
      }`,
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

// candidate review routes

export const getCandidateOfferDetails = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/offer/offer-details`,
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const downloadOfferDetails = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.HIRING_V3}/offer/download-offer-letter`,
      payload,
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
