import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';
import { get } from '../apiCalls';

export const getOrgDetails = async (name) => {
  try {
    const res = await get(
      `${CONSTANT.API_URL}/${CONSTANT.MONK_V3}/${CONSTANT.API_ORG_WITH_SUBDOMAIN}?subdomain=${name}`,
    );

    return res.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Unexpected error');
    }
  }
};

export const getOrgSideNav = async () => {
  try {
    const res = await get(`${CONSTANT.API_URL}/${CONSTANT.GET_SIDENAV}`);

    return res.data;
  } catch (err) {
    return err;
  }
};

export const getUserAccess = async () => {
  try {
    const res = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/user-access`,
    );
    return res.data;
  } catch (err) {
    return err;
  }
};
