import React from 'react';
import { useFormContext } from 'react-hook-form';
import Heading from '../../atoms/heading/Heading';
import Paragraph from '../../atoms/paragraph/Paragraph';
import { htmlParser } from 'utils/jsUtils';

const HPComponent = (props) => {
  const { data } = props;
  const { selectAtomsMolecules, type, path } = data;
  const { watch } = useFormContext();
  const [justifyContent, content] = watch([
    `${path}.flex.justify`,
    `${path}.para.value`,
  ]);
  return (
    <div
      onClick={() => selectAtomsMolecules(type)}
      className={`w-full h-full flex flex-col gap-3 p-4 `}
      style={{ justifyContent: justifyContent ?? 'center' }}
    >
      <Heading data={data} />
      {content ? (
        <div
          onClick={() => selectAtomsMolecules(type)}
          className="parent-list-default cursor-pointer flex flex-col  max-h-40 overflow-auto"
        >
          {htmlParser(content)}
        </div>
      ) : (
        <Paragraph data={data} />
      )}
    </div>
  );
};

export default HPComponent;
