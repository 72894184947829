import React from 'react';
import { Navigate } from 'react-router-dom';

const CreatePolicy = React.lazy(() =>
  import('pages/policy/create/CreatePolicy'),
);
const IndividualPolicy = React.lazy(() =>
  import('pages/policy/IndividualPolicy/IndividualPolicy'),
);
const Benefits = React.lazy(() => import('pages/benefits/Benefits'));
const AllPolicies = React.lazy(() =>
  import('pages/policy/allPolicies/AllPolicies').then((module) => ({
    default: module.AllPolicies,
  })),
);

const PolicyRoutes = [
  {
    type: 'collapse',
    name: 'createPolicy',
    key: 'createPolicy',
    route: '/policy/create',
    flagName: 'Managecompanypolicies',
    parentId: 'Policy',
    module: 'Policy',
    component: <CreatePolicy />,
  },
  {
    type: 'collapse',
    name: 'editPolicy',
    key: 'editPolicy',
    route: '/policy/edit/:id',
    flagName: 'Managecompanypolicies',
    parentId: 'Policy',
    module: 'Policy',
    component: <CreatePolicy />,
  },
  {
    type: 'collapse',
    name: 'updatePolicy',
    key: 'updatePolicy',
    parentId: 'Policy',
    route: '/policy/update/:id',
    flagName: 'Managecompanypolicies',
    module: 'Policy',
    component: <CreatePolicy />,
  },
  {
    type: 'collapse',
    name: 'individualPolicy',
    key: 'individualPolicy',
    route: '/policy/:id',
    parentId: 'Policy',
    module: 'Policy',
    component: <IndividualPolicy />,
  },
  {
    type: 'collapse',
    name: 'benefits',
    key: 'benefits',
    parentId: 'Policy',
    route: '/policies/policy',
    flagName: 'Managecompanypolicies',
    module: 'Policy',
    component: <Navigate to="/policies/policy/live" />,
  },
  {
    type: 'collapse',
    name: 'benefits',
    key: 'benefits',
    parentId: 'Policy',
    route: '/policies/manage-policies',
    flagName: 'Managecompanypolicies',
    module: 'Policy',
    component: <Navigate to="/policies/policy/live" />,
  },
  {
    type: 'collapse',
    name: 'policies',
    key: 'policies',
    parentId: 'Policy',
    route: '/policies/:category/:type',
    module: 'Policy',
    component: <Benefits />,
  },
  {
    type: 'collapse',
    name: 'all-policies',
    parentId: 'Policy',
    key: 'all-policies',
    route: 'policies/all-policies',
    flagName: 'ViewCompanyPolicies',
    module: 'Policy',
    component: <AllPolicies />,
  },
];

export default PolicyRoutes;
