import { PlusIcon } from '@heroicons/react/24/outline';
import DeleteFormIcon from 'assets/svg/deleteFormIcon';
import {
  CheckBoxProgram,
  Input,
} from 'components/atoms/FormElements/input/Input';
import Toggle from 'components/atoms/FormElements/toggle/Toggle';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

export const AdditionalColumns = ({
  planDetails,
  setPlanDetails,
  error,
  setError,
}) => {
  const [columns, setColumns] = useState([]);
  const [additionalColumnsVisible, setAdditionalColumnsVisible] =
    useState(false);
  const userData = useSelector((state) => state.user);

  const handleAddEditColumn = (id, value, type) => {
    if (type === 'EDIT') {
      setColumns(
        columns?.map((column) => {
          if (column?.id === id) {
            return { ...column, columnName: value };
          }
          return column;
        }),
      );
    }
    if (type === 'PIVOT') {
      setColumns(
        columns?.map((column) => {
          if (column?.id === id) {
            return { ...column, isPivot: value };
          }
          return column;
        }),
      );
    }
    if (type === 'ADD') {
      setColumns([
        ...columns,
        {
          id: v4(),
          order: columns?.length,
          columnName: '',
          label: `Column ${columns?.length}`,
          createdBy: userData?.userProfileData?.userId?.value,
          isPivot: true,
          isActive: true,
        },
      ]);
    }
  };

  useEffect(() => {
    if (additionalColumnsVisible && !columns?.length) {
      setColumns([
        {
          id: v4(),
          label: 'Column 1',
          order: 1,
          columnName: '',
          // createdBy: userData?.userProfileData?.userId?.value,
          isPivot: true,
          isActive: true,
          calcultaions: null,
        },
      ]);
    }
  }, [additionalColumnsVisible]);

  useEffect(() => {
    setPlanDetails({
      ...planDetails,
      additionalColumns: columns,
    });
  }, [columns]);

  return (
    <div className="px-5 pt-6 pb-5 space-y-5 bg-white rounded-xl shadow-md">
      <div className="flex items-center justify-between">
        <p className="text-sm font-semibold text-gray-900 pb-[10px] flex items-center gap-[10px]">
          Additional Columns
          <Toggle
            onChange={() =>
              setAdditionalColumnsVisible(!additionalColumnsVisible)
            }
            checked={additionalColumnsVisible}
          />
        </p>
        {additionalColumnsVisible && (
          <button
            className="flex items-center justify-center text-sm font-medium text-gray-600"
            onClick={() => handleAddEditColumn('', '', 'ADD')}
          >
            <PlusIcon className="w-4 h-4" /> Add New
          </button>
        )}
      </div>
      {additionalColumnsVisible && (
        <div className="flex flex-wrap items-center gap-8">
          {columns?.map((column, index) => {
            return (
              <div
                key={column?.id}
                className="w-64 space-y-3 text-sm font-medium text-gray-600"
              >
                <Input
                  label={`Column ${index + 1}`}
                  defaultValue={column?.columnName}
                  onChange={(event) =>
                    handleAddEditColumn(column?.id, event.target.value, 'EDIT')
                  }
                />
                <div className="flex items-center justify-between">
                  <CheckBoxProgram
                    checked={column?.isPivot}
                    onChange={(event) =>
                      handleAddEditColumn(
                        column?.id,
                        event.target.checked,
                        'PIVOT',
                      )
                    }
                    label="Include in Pivot"
                  />
                  <DeleteFormIcon
                    className="cursor-pointer"
                    onClick={() =>
                      setColumns(columns?.filter(({ id }) => id !== column?.id))
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
