import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import { createPlan } from 'apiClient/rangeBuilder/rangeBuilder';
import ButtonLoader from 'assets/svg/buttonLoader';
import Button from 'components/atoms/button/Button';
import { setToastMessage } from 'pages/recognition/redemption/helper';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdditionalColumns } from './AdditionalColumns';
import { MethodOfCalculation } from './MethodOfCalculation';
import { PayStructure } from './PayStructure';
import { RangeDetails } from './RangeDetails';
import { SetBenchMarks } from './SetBenchmarks';

/*
Components being reused are:-
    1. Button
*/

export const CreatePlan = () => {
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [checkValidation, setCheckValidation] = useState(false);
  const [error, setError] = useState({});
  const [planDetails, setPlanDetails] = useState({
    compensationType: [],
    additionalColumns: [],
    title: '',
    validFrom: new Date()?.toISOString(),
    validTill: new Date()?.toISOString(),
    modeOfCalculationReference: '3 point',
    modeOfCalculationOutput: 'Range Based on Mid Point',
    createdBy: userData?.userProfileData?.userId?.value,
    calculations: '',
    benchmarkColumns: [],
  });

  const validateData = () => {
    let errorObject = {};
    const { title, calculations, benchmarkColumns, additionalColumns } =
      planDetails;

    if (!title?.length) {
      errorObject = { ...errorObject, title: "Title can't be empty!" };
    }
    errorObject = {
      ...errorObject,
      benchmarkColumns: benchmarkColumns?.map((column) => {
        if (!column?.columnName?.length && column?.calculation?.formula) {
          return {
            id: column?.id,
            columnName: "Column name can't be empty!",
          };
        } else if (
          !column?.calculation?.formula &&
          column?.columnName?.length
        ) {
          return {
            id: column?.id,
            formula: 'Please enter formula.',
          };
        } else if (
          !column?.columnName?.length &&
          !column?.calculation?.formula
        ) {
          return {
            id: column?.id,
            formula: 'Please enter formula.',
            columnName: "Column name can't be empty!",
          };
        }
      }),
    };
    errorObject = {
      ...errorObject,
      additionalColumns: additionalColumns?.map((column) => {
        if (!column?.columnName?.length) {
          return {
            id: column?.id,
            columnName: "Name can't be empty.",
          };
        }
      }),
    };
    setError(errorObject);
    return !Object.keys(errorObject)?.length ? true : false;
  };

  const createBenchmarkPlan = async () => {
    try {
      // if (validateData()) {
      setLoading(true);
      const response = await createPlan({
        ...planDetails,
        additionalColumns: planDetails?.additionalColumns?.map((column) => {
          delete column?.id;
          delete column?.label;
          return { ...column, order: null };
        }),
        benchmarkColumns: planDetails?.benchmarkColumns?.map((benchmark) => {
          delete benchmark?.id;
          return benchmark;
        }),
        calculations: planDetails?.calculations?.map((calculation) => {
          delete calculation?.id;
          return calculation;
        }),
      });
      setToastMessage('Plan successfully created.');
      navigate('/range-builder/plans');
      setLoading(false);
      // }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setToastMessage('Something went wrong!');
    }
  };

  console.log(new Date().toISOString().split('T')[0]);

  return (
    <div className="">
      <div className="flex items-center justify-between py-6 px-8 bg-white">
        <div className="flex flex-row items-center gap-3 justify-start">
          <div className="p-2 rounded-lg border-2 cursor-pointer">
            <ArrowSmallLeftIcon className="w-5 h-5" />
          </div>
          <div className=" flex items-center justify center text-2xl font-semibold">
            Create New
          </div>
        </div>
        <div className="flex items-center gap-5">
          <button
            className="text-sm text-gray-600 font-semibold"
            onClick={() => navigate('/range-builder/plans')}
          >
            Cancel
          </button>
          <Button
            className="text-sm font-semibold"
            variant="filled"
            onClick={() => createBenchmarkPlan()}
          >
            {loading ? (
              <>
                <ButtonLoader /> Creating...
              </>
            ) : (
              'Create New'
            )}
          </Button>
        </div>
      </div>
      <div className="pt-6 pb-8 px-8 space-y-[10px]">
        <RangeDetails
          planDetails={planDetails}
          setPlanDetails={setPlanDetails}
          checkValidation={checkValidation}
          error={error}
          setError={setError}
        />
        <PayStructure
          planDetails={planDetails}
          setPlanDetails={setPlanDetails}
        />
        <MethodOfCalculation
          planDetails={planDetails}
          setPlanDetails={setPlanDetails}
        />
        <SetBenchMarks
          planDetails={planDetails}
          setPlanDetails={setPlanDetails}
          checkValidation={checkValidation}
          error={error}
        />
        <AdditionalColumns
          planDetails={planDetails}
          setPlanDetails={setPlanDetails}
          checkValidation={checkValidation}
          error={error}
          setError={setError}
        />
      </div>
    </div>
  );
};
