import { v4 } from 'uuid';

export const rangeData = [
  {
    id: v4(),
    jobDetails: {
      jobCode: 'SYSTM01',
      jobTitle: 'Associate Consultant',
      jobGrade: 'Level 1',
      currency: 'INR',
      country: 'India',
    },
    basePay: {
      min: '350000',
      mid: '415000',
      max: '575000',
    },
    variablePay: {
      min: '35000',
      mid: '41500',
      max: '57500',
    },
    jobInformation: {
      education: 'Bachelors',
      experience: '0-1 Years',
      jobFamily: 'Tech Consultant',
      industry: 'IT/Tech',
      effectiveDate: '22 Jan 2023',
      status: 'Active',
      min: '350000',
      mid: '415000',
      max: '575000',
    },
  },
  {
    id: v4(),
    jobDetails: {
      jobCode: 'SYSTM02',
      jobTitle: 'Consultant',
      jobGrade: 'Level 2',
      currency: 'INR',
      country: 'India',
    },
    basePay: {
      min: '550000',
      mid: '645000',
      max: '750000',
    },
    variablePay: {
      min: '55000',
      mid: '64500',
      max: '75000',
    },
    jobInformation: {
      education: 'Bachelors',
      experience: '2-4 Years',
      jobFamily: 'Tech Consultant',
      industry: 'IT/Tech',
      effectiveDate: '22 Jan 2023',
      status: 'Active',
      min: '550000',
      mid: '645000',
      max: '750000',
    },
  },
  {
    id: v4(),
    jobDetails: {
      jobCode: 'SYSTM07',
      jobTitle: 'Associate Consultant',
      jobGrade: 'Level 1',
      currency: 'INR',
      country: 'India',
    },
    basePay: {
      min: '123210',
      mid: '123210',
      max: '123210',
    },
    variablePay: {
      min: '123210',
      mid: '123210',
      max: '123210',
    },
    jobInformation: {
      education: 'Masters',
      experience: '2-4 Years',
      jobFamily: 'Tech Consultant',
      industry: 'It/Tech',
      effectiveDate: '22 Jan 2023',
      status: 'Active',
      min: '123210',
      mid: '123210',
      max: '123210',
    },
  },
  {
    id: v4(),
    jobDetails: {
      jobCode: 'SYSTM07',
      jobTitle: 'Associate Consultant',
      jobGrade: 'Level 1',
      currency: 'INR',
      country: 'India',
    },
    basePay: {
      min: '123210',
      mid: '123210',
      max: '123210',
    },
    variablePay: {
      min: '123210',
      mid: '123210',
      max: '123210',
    },
    jobInformation: {
      education: 'Masters',
      experience: '2-4 Years',
      jobFamily: 'Tech Consultant',
      industry: 'It/Tech',
      effectiveDate: '22 Jan 2023',
      status: 'Active',
      min: '123210',
      mid: '123210',
      max: '123210',
    },
  },
  {
    id: v4(),
    jobDetails: {
      jobCode: 'SENG05',
      jobTitle: 'Principal Software Engineer',
      jobGrade: 'L5',
      currency: 'INR',
      country: 'India',
    },
    basePay: {
      min: '2800000',
      mid: '3500000',
      max: '4200000',
    },
    variablePay: {
      min: '200000',
      mid: '300000',
      max: '400000',
    },
    jobInformation: {
      education: "Bachelor's",
      experience: '12+ Years',
      jobFamily: 'Software Engineering',
      industry: 'IT/Tech',
      effectiveDate: '01 Jan 2024',
      status: 'Active',
      min: '3000000',
      mid: '3800000',
      max: '4600000',
    },
  },
  {
    id: v4(),
    jobDetails: {
      jobCode: 'SENG06',
      jobTitle: 'Software Architect',
      jobGrade: 'L6',
      currency: 'INR',
      country: 'India',
    },
    basePay: {
      min: '3500000',
      mid: '4500000',
      max: '5500000',
    },
    variablePay: {
      min: '300000',
      mid: '500000',
      max: '700000',
    },
    jobInformation: {
      education: "Bachelor's, Master's preferred",
      experience: '15+ Years',
      jobFamily: 'Software Engineering',
      industry: 'IT/Tech',
      effectiveDate: '01 Apr 2024',
      status: 'Active',
      min: '3800000',
      mid: '5000000',
      max: '6200000',
    },
  },
  {
    id: v4(),
    jobDetails: {
      jobCode: 'SENG07',
      jobTitle: 'Director of Engineering',
      jobGrade: 'L7',
      currency: 'INR',
      country: 'India',
    },
    basePay: {
      min: '5000000',
      mid: '6500000',
      max: '8000000',
    },
    variablePay: {
      min: '500000',
      mid: '800000',
      max: '1100000',
    },
    jobInformation: {
      education:
        "Master's Degree in Computer Science or related field, MBA preferred",
      experience: '20+ Years',
      jobFamily: 'Software Engineering',
      industry: 'IT/Tech',
      effectiveDate: '01 Jul 2024',
      status: 'Active',
      min: '5500000',
      mid: '7300000',
      max: '9100000',
    },
  },
];
export const inputFields = [
  {
    id: v4(),
    label: 'Title',
    valueKey: 'title',
    type: 'text',
  },
  {
    id: v4(),
    label: 'Valid From',
    valueKey: 'validFrom',
    type: 'date',
  },
  {
    id: v4(),
    label: 'Valid Till',
    valueKey: 'validTill',
    type: 'date',
  },
];

export const tabs = [
  {
    id: '1',
    tabLabel: 'Details',
  },
  {
    id: '2',
    tabLabel: 'Employees',
  },
  {
    id: '3',
    tabLabel: 'Version History',
  },
];

export const jobDetails = [
  {
    label: 'Education',
    value: 'Masters',
  },
  {
    label: 'Experience',
    value: '2-4 Years',
  },
  {
    label: 'job Family',
    value: 'Tech Consultant',
  },
  {
    label: 'Job Grade',
    value: "'Level 1'",
  },
  {
    label: 'Industry',
    value: 'It/Tech',
  },
  {
    label: 'Currency',
    value: 'INR',
  },
];

export const tableColumns = [
  {
    id: v4(),
    name: 'Job Details',
    backgroundColor: '#98A2B3',
    colSpan: 1,
    collapsedColumns: [],
    columns: [],
  },
  {
    id: v4(),
    name: '',
    backgroundColor: '#98A2B3',
    colSpan: 4,
    collapsedColumns: [],
    columns: [],
  },
  {
    id: v4(),
    name: 'Base Pay',
    backgroundColor: '#22CCEE',
    colSpan: 3,
  },
  {
    id: v4(),
    name: 'Variable Pay',
    backgroundColor: '#F63D68',
    colSpan: 3,
  },
  // {
  //   id: v4(),
  //   name: 'Others',
  //   backgroundColor: '#F5A50A',
  //   colSpan: 3,
  // },
  {
    id: v4(),
    name: 'Job Information',
    backgroundColor: '#7A5AF8',
    colSpan: 9,
  },
];

export const payDetailsTableData = [
  {
    id: v4(),
    name: 'Base Pay',
    min: 111230,
    mid: 111230,
    max: 111230,
  },

  {
    id: v4(),
    name: 'Variable Pay',
    min: 111230,
    mid: 111230,
    max: 111230,
  },
  {
    id: v4(),
    name: 'Others',
    min: 111230,
    mid: 111230,
    max: 111230,
  },
];

export const filters = [
  { id: v4(), name: 'All Locations' },
  { id: v4(), name: 'All Job Families' },
  { id: v4(), name: 'All Specializations' },
  { id: v4(), name: 'All Levels' },
  { id: v4(), name: 'All Currencies' },
];

export const versionHistoryData = [
  {
    id: v4(),
    modifiedDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    minSalary: 1200000,
    maxSalary: 1200000,
    employeeName: 'Olivia Rhye',
    dateOfJoining: new Date(),
    previousVersion: {
      startDate: new Date(),
      endDate: new Date(),
      minSalary: 1200000,
      maxSalary: 1200000,
    },
  },
  {
    id: v4(),
    modifiedDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    minSalary: 1200000,
    maxSalary: 1200000,
    employeeName: 'Olivia Rhye',
    dateOfJoining: new Date(),
    previousVersion: {
      startDate: new Date(),
      endDate: new Date(),
      minSalary: 1200000,
      maxSalary: 1200000,
    },
  },
  {
    id: v4(),
    modifiedDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    minSalary: 1200000,
    maxSalary: 1200000,
    employeeName: 'Olivia Rhye',
    dateOfJoining: new Date(),
    previousVersion: {
      startDate: new Date(),
      endDate: new Date(),
      minSalary: 1200000,
      maxSalary: 1200000,
    },
  },
  {
    id: v4(),
    modifiedDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    minSalary: 1200000,
    maxSalary: 1200000,
    employeeName: 'Olivia Rhye',
    dateOfJoining: new Date(),
    previousVersion: {
      startDate: new Date(),
      endDate: new Date(),
      minSalary: 1200000,
      maxSalary: 1200000,
    },
  },
];

export const buttonTabs = [
  {
    id: '1',
    label: 'Below Min',
  },
  {
    id: '2',
    label: 'In Band',
  },
  {
    id: '3',
    label: 'Out of Band',
  },
];

export const employeesData = [
  {
    id: v4(),
    user: {
      displayName: 'Max Samuel',
      empId: 324123,
    },
    compRatio: 1.42,
    currency: 'INR',
    compensationAmount: 123400,
    range: {
      min: 23400,
      mid: 123400,
      max: 223400,
    },
  },
  {
    id: v4(),
    user: {
      displayName: 'Max Samuel',
      empId: 324123,
    },
    compRatio: 0.19,
    currency: 'INR',
    compensationAmount: 123400,
    range: {
      min: 2300,
      mid: 13400,
      max: 22400,
    },
  },
  {
    id: v4(),
    user: {
      displayName: 'Max Samuel',
      empId: 324123,
    },
    compRatio: 1.11,
    currency: 'INR',
    compensationAmount: 123400,
    range: {
      min: 3400,
      mid: 13400,
      max: 23400,
    },
  },
];

export const mainTabs = [
  {
    id: '1',
    tabLabel: 'Internal Data',
  },
  {
    id: '2',
    tabLabel: 'External Data',
  },
  {
    id: '3',
    tabLabel: 'Job Matching',
  },
  {
    id: '4',
    tabLabel: 'Final Range',
  },
  {
    id: '5',
    tabLabel: 'Employees',
  },
];

export const compensationTypes = [
  {
    id: v4(),
    label: 'Base Pay',
    description: 'Only include Base Compensation',
    value: 'BasePay',
  },
  {
    id: v4(),
    label: 'Variable Pay',
    description: 'Include Base Pay and Variable Pay',
    value: 'VariablePay',
  },
  {
    id: v4(),
    label: 'Equity',
    description: 'Include Allowances etc',
    value: 'Equity',
  },
  {
    id: v4(),
    label: 'Others',
    description: 'Include Total Cash Compensation and Equity',
    value: 'Others',
  },
];

export const calculationReferenceAsMidPoint = [
  {
    id: '1',
    label: 'Mid',
    value: 'Mid',
  },
  {
    id: '2',
    label: 'Min',
    value: 'Min',
  },
  {
    id: '3',
    label: 'Max',
    value: 'Max',
  },
];

export const calculationReferenceAsIndividualPoint = [
  {
    id: '1',
    label: 'Min',
    value: 'Min',
  },
  {
    id: '2',
    label: 'Mid',
    value: 'Mid',
  },
  {
    id: '3',
    label: 'Max',
    value: 'Max',
  },
];

export const calculationReference4Points = [
  {
    id: '1',
    label: 'Q1',
    value: 'Q1',
  },
  {
    id: '2',
    label: 'Q2',
    value: 'Q2',
  },
  {
    id: '3',
    label: 'Q3',
    value: 'Q3',
  },
  {
    id: '4',
    label: 'Q4',
    value: 'Q4',
  },
];

export const marketStandardPercentilePoint = [
  {
    id: '1',
    label: 'P10',
    value: 'P10',
  },
  {
    id: '2',
    label: 'P25',
    value: 'P25',
  },
  {
    id: '3',
    label: 'P33',
    value: 'P33',
  },
  {
    id: '4',
    label: 'P50',
    value: 'P50',
  },
  {
    id: '5',
    label: 'P66',
    value: 'P66',
  },
  {
    id: '6',
    label: 'P75',
    value: 'P75',
  },
  {
    id: '7',
    label: 'P99',
    value: 'P99',
  },
];

export const calculationReferenceMultiPoints = [
  {
    id: '1',
    label: 'P10',
    value: 'P10',
  },
  {
    id: '2',
    label: 'P25',
    value: 'P25',
  },
  {
    id: '3',
    label: 'P33',
    value: 'P33',
  },
  {
    id: '4',
    label: 'P50',
    value: 'P50',
  },
  {
    id: '5',
    label: 'P66',
    value: 'P66',
  },
  {
    id: '6',
    label: 'P75',
    value: 'P75',
  },
  {
    id: '7',
    label: 'P99',
    value: 'P99',
  },
];

export const benchmarkPlansTabs = [
  {
    id: '1',
    label: 'All',
  },
  {
    id: '2',
    label: 'Live',
  },
  {
    id: '3',
    label: 'In-Progress',
  },
  {
    id: '4',
    label: 'Inactive',
  },
];

export const benchmarkPlansData = {
  status: 200,
  message: 'Ok',
  data: {
    planData: [
      {
        id: '224a44aa-b73a-45c6-8490-469c2b890be1',
        rangeId: 4,
        title: 'First-plan-2',
        startDate: '2011-12-19T15:28:46.493Z',
        endDate: '2025-10-05T14:48:00.000Z',
        compensationType: [
          '4309f228-2083-41a6-803c-06fcfbb86ae5',
          '9dc9711a-9fe2-4cc7-85a3-f0bd6c216c4c',
          'fcf66d34-4ccd-4524-9dab-f3c2133e9ae9',
        ],
        modeCalculationReference: 'ThreePoint',
        calcModeOutput: 'IndividualPercentilePointReferences',
        status: 'Inactive',
        rangeFormTypes: [
          {
            id: '7ef9f2e8-5dae-45cd-97ab-5ae9715b7147',
            planId: '224a44aa-b73a-45c6-8490-469c2b890be1',
            planType: 'Internal',
            fileName: 'employee_data.csv',
            isActive: true,
          },
        ],
        histories: [
          {
            id: 'b1eaae36-fca5-49fa-af6d-27185103bf75',
            rangeFormId: '224a44aa-b73a-45c6-8490-469c2b890be1',
            rangeFormIdNumber: 4,
            title: 'First-plan-2',
            startDate: '2011-12-19T15:28:46.493Z',
            endDate: '2025-10-05T14:48:00.000Z',
            compensationType: [
              '4309f228-2083-41a6-803c-06fcfbb86ae5',
              '9dc9711a-9fe2-4cc7-85a3-f0bd6c216c4c',
              'fcf66d34-4ccd-4524-9dab-f3c2133e9ae9',
            ],
            modeCalculationReference: 'ThreePoint',
            calcModeOutput: 'IndividualPercentilePointReferences',
            status: 'Inactive',
            createdBy: '2289a21d-f039-4e50-82f4-f4366659d38e',
            createdDate: '2024-06-06T06:03:00.723Z',
            createdByUser: {
              id: '2289a21d-f039-4e50-82f4-f4366659d38e',
              uid: 'auth0|650d69276ea825ef4f5f2346',
              employeeId: 'EA000001',
              email: 'Kunal.Chandra@talentmonk.com',
              isActive: true,
              employeeFieldData: [
                {
                  id: 'ac1a0bdc-d585-4974-a050-3a82f9d75068',
                  fieldId: 'c20f3559-76ae-454a-8255-c0aa5c80477c',
                  value: 'Elena Vang',
                  userId: '001e25d4-b2ca-4fdf-a1a4-f381a4523c28',
                  effectiveDate: '2024-03-01T16:44:43.163Z',
                  updatedDate: '2024-03-01T16:44:43.163Z',
                },
                {
                  id: 'ae21b914-761a-4d5c-a9ad-751693fc120d',
                  fieldId: '011cd189-f738-4cc5-b063-d7d044b4af5b',
                  value:
                    '/a1SVji5edZYTPPqa/avatar/1a50c4fe-2b0f-4339-817c-0655a4bf499f.jpg',
                  userId: '001e25d4-b2ca-4fdf-a1a4-f381a4523c28',
                  effectiveDate: '2024-03-01T16:44:43.163Z',
                  updatedDate: '2024-03-01T16:44:43.163Z',
                },
              ],
            },
          },
        ],
      },
      {
        id: 'debcb6bd-8f51-4f8d-a949-52274ff69803',
        rangeId: 3,
        title: 'First-plan-2',
        startDate: '2011-12-19T15:28:46.493Z',
        endDate: '2025-10-05T14:48:00.000Z',
        compensationType: [
          '4309f228-2083-41a6-803c-06fcfbb86ae5',
          '9dc9711a-9fe2-4cc7-85a3-f0bd6c216c4c',
          'fcf66d34-4ccd-4524-9dab-f3c2133e9ae9',
        ],
        modeCalculationReference: 'ThreePoint',
        calcModeOutput: 'IndividualPercentilePointReferences',
        status: 'Inactive',
        rangeFormTypes: [
          {
            id: 'debcb6bd-8f51-4f8d-a949-52274ff69803',
            planId: 'debcb6bd-8f51-4f8d-a949-52274ff69803',
            planType: 'Internal',
            fileName: 'employee_data.csv',
            isActive: true,
          },
        ],
        histories: [
          {
            id: 'f908c498-bbbd-4458-90cb-4dd95619e21e',
            rangeFormId: 'debcb6bd-8f51-4f8d-a949-52274ff69803',
            rangeFormIdNumber: 3,
            title: 'First-plan-2',
            startDate: '2011-12-19T15:28:46.493Z',
            endDate: '2025-10-05T14:48:00.000Z',
            compensationType: [
              '4309f228-2083-41a6-803c-06fcfbb86ae5',
              '9dc9711a-9fe2-4cc7-85a3-f0bd6c216c4c',
              'fcf66d34-4ccd-4524-9dab-f3c2133e9ae9',
            ],
            modeCalculationReference: 'ThreePoint',
            calcModeOutput: 'IndividualPercentilePointReferences',
            status: 'Inactive',
            createdBy: '2289a21d-f039-4e50-82f4-f4366659d38e',
            createdDate: '2024-06-06T02:50:31.709Z',
            createdByUser: {
              id: '2289a21d-f039-4e50-82f4-f4366659d38e',
              uid: 'auth0|650d69276ea825ef4f5f2346',
              employeeId: 'EA000001',
              email: 'Kunal.Chandra@talentmonk.com',
              isActive: true,
              employeeFieldData: [
                {
                  id: 'ac1a0bdc-d585-4974-a050-3a82f9d75068',
                  fieldId: 'c20f3559-76ae-454a-8255-c0aa5c80477c',
                  value: 'Elena Vang',
                  userId: '001e25d4-b2ca-4fdf-a1a4-f381a4523c28',
                  effectiveDate: '2024-03-01T16:44:43.163Z',
                  updatedDate: '2024-03-01T16:44:43.163Z',
                },
                {
                  id: 'ae21b914-761a-4d5c-a9ad-751693fc120d',
                  fieldId: '011cd189-f738-4cc5-b063-d7d044b4af5b',
                  value:
                    '/a1SVji5edZYTPPqa/avatar/1a50c4fe-2b0f-4339-817c-0655a4bf499f.jpg',
                  userId: '001e25d4-b2ca-4fdf-a1a4-f381a4523c28',
                  effectiveDate: '2024-03-01T16:44:43.163Z',
                  updatedDate: '2024-03-01T16:44:43.163Z',
                },
              ],
            },
          },
        ],
      },
      {
        id: '37fc83dd-26f1-4d49-9ab0-b94fefa53525',
        rangeId: 2,
        title: 'First-plan-1',
        startDate: '2011-12-19T15:28:46.493Z',
        endDate: '2025-10-05T14:48:00.000Z',
        compensationType: [
          '4309f228-2083-41a6-803c-06fcfbb86ae5',
          '9dc9711a-9fe2-4cc7-85a3-f0bd6c216c4c',
          'fcf66d34-4ccd-4524-9dab-f3c2133e9ae9',
        ],
        modeCalculationReference: 'ThreePoint',
        calcModeOutput: 'IndividualPercentilePointReferences',
        status: 'Inactive',
        rangeFormTypes: [],
        histories: [
          {
            id: 'b797d0ac-87e8-48af-8f21-1197451bffb2',
            rangeFormId: '37fc83dd-26f1-4d49-9ab0-b94fefa53525',
            rangeFormIdNumber: 2,
            title: 'First-plan-1',
            startDate: '2011-12-19T15:28:46.493Z',
            endDate: '2025-10-05T14:48:00.000Z',
            compensationType: [
              '4309f228-2083-41a6-803c-06fcfbb86ae5',
              '9dc9711a-9fe2-4cc7-85a3-f0bd6c216c4c',
              'fcf66d34-4ccd-4524-9dab-f3c2133e9ae9',
            ],
            modeCalculationReference: 'ThreePoint',
            calcModeOutput: 'IndividualPercentilePointReferences',
            status: 'Inactive',
            createdBy: '2289a21d-f039-4e50-82f4-f4366659d38e',
            createdDate: '2024-06-05T09:40:03.245Z',
            createdByUser: {
              id: '2289a21d-f039-4e50-82f4-f4366659d38e',
              uid: 'auth0|650d69276ea825ef4f5f2346',
              employeeId: 'EA000001',
              email: 'Kunal.Chandra@talentmonk.com',
              isActive: true,
              employeeFieldData: [
                {
                  id: 'ac1a0bdc-d585-4974-a050-3a82f9d75068',
                  fieldId: 'c20f3559-76ae-454a-8255-c0aa5c80477c',
                  value: 'Elena Vang',
                  userId: '001e25d4-b2ca-4fdf-a1a4-f381a4523c28',
                  effectiveDate: '2024-03-01T16:44:43.163Z',
                  updatedDate: '2024-03-01T16:44:43.163Z',
                },
                {
                  id: 'ae21b914-761a-4d5c-a9ad-751693fc120d',
                  fieldId: '011cd189-f738-4cc5-b063-d7d044b4af5b',
                  value:
                    '/a1SVji5edZYTPPqa/avatar/1a50c4fe-2b0f-4339-817c-0655a4bf499f.jpg',
                  userId: '001e25d4-b2ca-4fdf-a1a4-f381a4523c28',
                  effectiveDate: '2024-03-01T16:44:43.163Z',
                  updatedDate: '2024-03-01T16:44:43.163Z',
                },
              ],
            },
          },
        ],
      },
      {
        id: '49150992-0c6e-4221-8830-40ee067941fc',
        rangeId: 1,
        title: 'First-plan-1',
        startDate: '2011-12-19T15:28:46.493Z',
        endDate: '2025-10-05T14:48:00.000Z',
        compensationType: [
          '4309f228-2083-41a6-803c-06fcfbb86ae5',
          '9dc9711a-9fe2-4cc7-85a3-f0bd6c216c4c',
          'fcf66d34-4ccd-4524-9dab-f3c2133e9ae9',
        ],
        modeCalculationReference: 'ThreePoint',
        calcModeOutput: 'IndividualPercentilePointReferences',
        status: 'Inactive',
        rangeFormTypes: [],
        histories: [
          {
            id: 'dde8d324-5e1a-48de-91a5-c9781122064e',
            rangeFormId: '49150992-0c6e-4221-8830-40ee067941fc',
            rangeFormIdNumber: 1,
            title: 'First-plan-1',
            startDate: '2011-12-19T15:28:46.493Z',
            endDate: '2025-10-05T14:48:00.000Z',
            compensationType: [
              '4309f228-2083-41a6-803c-06fcfbb86ae5',
              '9dc9711a-9fe2-4cc7-85a3-f0bd6c216c4c',
              'fcf66d34-4ccd-4524-9dab-f3c2133e9ae9',
            ],
            modeCalculationReference: 'ThreePoint',
            calcModeOutput: 'IndividualPercentilePointReferences',
            status: 'Inactive',
            createdBy: '2289a21d-f039-4e50-82f4-f4366659d38e',
            createdDate: '2024-06-05T09:37:17.325Z',
            createdByUser: {
              id: '2289a21d-f039-4e50-82f4-f4366659d38e',
              uid: 'auth0|650d69276ea825ef4f5f2346',
              employeeId: 'EA000001',
              email: 'Kunal.Chandra@talentmonk.com',
              isActive: true,
              employeeFieldData: [
                {
                  id: 'ac1a0bdc-d585-4974-a050-3a82f9d75068',
                  fieldId: 'c20f3559-76ae-454a-8255-c0aa5c80477c',
                  value: 'Elena Vang',
                  userId: '001e25d4-b2ca-4fdf-a1a4-f381a4523c28',
                  effectiveDate: '2024-03-01T16:44:43.163Z',
                  updatedDate: '2024-03-01T16:44:43.163Z',
                },
                {
                  id: 'ae21b914-761a-4d5c-a9ad-751693fc120d',
                  fieldId: '011cd189-f738-4cc5-b063-d7d044b4af5b',
                  value:
                    '/a1SVji5edZYTPPqa/avatar/1a50c4fe-2b0f-4339-817c-0655a4bf499f.jpg',
                  userId: '001e25d4-b2ca-4fdf-a1a4-f381a4523c28',
                  effectiveDate: '2024-03-01T16:44:43.163Z',
                  updatedDate: '2024-03-01T16:44:43.163Z',
                },
              ],
            },
          },
        ],
      },
    ],
    countdata: {
      '224a44aa-b73a-45c6-8490-469c2b890be1': 2,
      'debcb6bd-8f51-4f8d-a949-52274ff69803': 3,
      '37fc83dd-26f1-4d49-9ab0-b94fefa53525': 10,
      '49150992-0c6e-4221-8830-40ee067941fc': 3,
    },
  },
};

export const percentilePoints = [
  'P10',
  'P25',
  'P33',
  'P50',
  'P66',
  'P75',
  'P99',
];

// External Data --> Config and Utils

export const customStyles = {
  control: (base, state) => {
    const isSelectNewProgram =
      state.selectProps.id === 'react-select-new-program';
    const isBudgetAllocationField =
      state.selectProps.id === 'budget-allocation-field';
    const shouldRemoveBorder =
      state.selectProps.id === 'remove-border-select' ||
      state.selectProps.id === 'react-select-letter-header';

    return {
      ...base,
      '&:hover': {},
      boxShadow: state.isFocused ? null : null, // borderImage: state.isFocused // ? "linear-gradient(to right, var(--primaryLeft), var(--primaryRight)) 1" //  : "rgb(229 231 235);",
      borderImageSlice: 1,
      borderColor: shouldRemoveBorder
        ? 'transparent'
        : state.isFocused
        ? '#888'
        : 'rgb(209 213 219);',
      borderWidth: shouldRemoveBorder ? 0 : 1,
      borderRadius: '0.5rem',
      fontSize: '13px',
      fontWeight: 500,
      color: '#101828',
      fontFamily: 'Figtree',
      outline: state.isFocused ? null : null,
      backgroundClip: 'padding-box, border-box',
      height: '19px',
      minHeight: '19px',
      marginTop: 0,
      width: isSelectNewProgram ? '100%' : base.width,
    };
  },
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled
      ? '#F9F9F9'
      : state.isSelected
      ? '#F9F9F9'
      : state.isFocused
      ? '#eee'
      : undefined,

    color: state.isDisabled ? '#98A2B3' : '#101828',
    fontWeight: 500,
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Figtree',
    position: 'relative',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    // "&:hover": { backgroundColor: "#white" },
    '&::after': {
      width: '10px',
      content: state?.isSelected ? '"✔️"' : '""', // Unicode checkmark symbol
      position: 'absolute',
      top: '0.5rem',
      right: '1rem',
    },
  }),

  menu: (provided, state) => {
    const isSelectNewProgram =
      state.selectProps.id === 'react-select-new-program';
    return {
      ...provided,
      boxShadow:
        '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      width: isSelectNewProgram ? '100%' : provided.width,
      zIndex: 999,
    };
  },
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '8px',
      visibility: 'hidden',
    },
    '&:hover::-webkit-scrollbar': {
      visibility: 'visible',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '1rem',
      visibility: 'hidden',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      background: '#d0d5dd',
      visibility: 'visible',
    },
  }),

  menuPortal: (provided, state) => ({
    ...provided,
    zIndex: 99999,
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }), // dropdownIndicator: () => ({ //  display: "none", // }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    borderRadius: '20px',
    display: 'inline-flex',
    margin: '2px',
    padding: '2px',
    background:
      'linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, var(--primaryLeft), var(--primaryRight)) border-box',
    color: 'black',
    border: '2px solid transparent',
    '& #react-select-hide': {
      display: 'none',
    },
    '& #e-card-image': {
      height: '25px',
      width: '40px',
    },
    '& #e-card-title': {
      fontSize: '12px',
    },
    '& #e-card-description': {
      display: 'none',
    },
  }),

  multiValueLabel: (provided) => ({
    ...provided,
    color: 'black',
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    color: 'black',
    paddingLeft: '4px',
    paddingRight: '4px',
    borderRadius: '50%',
    backgroundColor: 'white',
    ':hover': {
      backgroundColor: 'white',
    },
  }),

  singleValue: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: '#667085',
    fontFamily: 'Figtree',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '19px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
    fontSize: '13px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '16px',
    fontSize: '13px',
    padding: '0 8px',
  }),
};
