import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import TableFilterIcon from 'assets/svg/tableFilterIcon';
import ToolTip from 'components/atoms/tooltip/Tooltip';
import { useLocation, useNavigate } from 'react-router-dom';

const TableHeader = (props) => {
  const { name, HeaderKey, sort, helpText } = props;
  const { search } = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(search);
  const sortBy = searchParams.get('sortBy');
  const sortType = searchParams.get('sortType');

  const onLabelClick = () => {
    if (sort) {
      if (HeaderKey === sortBy) {
        searchParams.set('pageSize', 10);
        searchParams.set('pageIndex', 0);
        searchParams.set('sortType', sortType === 'asc' ? 'desc' : 'asc');
        searchParams.set('sortBy', sortBy);
        navigate({
          search: searchParams.toString(),
        });
      } else {
        searchParams.set('pageSize', 10);
        searchParams.set('pageIndex', 0);
        searchParams.set('sortType', 'asc');
        searchParams.set('sortBy', HeaderKey);
        navigate({
          search: searchParams.toString(),
        });
      }
    }
  };

  return (
    <div className="flex items-center gap-2">
      <span
        className={`${sort && 'cursor-pointer'} capitalize`}
        onClick={() => onLabelClick()}
      >
        {name}
      </span>
      {helpText && (
        <span>
          <ToolTip text={helpText}>
            <QuestionMarkCircleIcon className="h-4 w-4 cursor-pointer" />
          </ToolTip>
        </span>
      )}
      {HeaderKey === sortBy && sortType === 'desc' && <TableFilterIcon />}
      {HeaderKey === sortBy && sortType === 'asc' && (
        <TableFilterIcon className="rotate-180" />
      )}
    </div>
  );
};

export default TableHeader;
