import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline';
import KebabIcon from 'assets/svg/kebabIcon';
import Tabs from 'components/molecules/tabs/Tabs';
import React, { useState } from 'react';
import Details from './detailsTab/Details';
import Benchmarks from './benchmarks/Benchmarks';
import VersionHistory from './versionHistory/VersionHistory';

export const tabs = [
  {
    id: '1',
    tabLabel: 'Details',
  },
  {
    id: '2',
    tabLabel: 'Benchmarks',
  },
  {
    id: '3',
    tabLabel: 'Version History',
  },
];

const ViewDetailsPopUp = ({
  isViewDetailsPopUp,
  setIsViewDetailsPopUp,
  benchmarkWithColSpan,
  subHeadings,
  pivotMap,
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs?.[0]?.tabLabel);

  return (
    <div className="fixed z-[53] inset-0 bg-backdrop overflow-y-auto w-full">
      <div
        className={`absolute top-14 right-0 w-3/5 bg-white min-h-screen overflow-y-auto custom-scrollbar`}
      >
        <div className="flex items-center bg-white justify-between w-full gap-4 shadow-md border-b p-2 px-4 fixed top-[54px]">
          <div className="flex flex-row items-center gap-3 justify-start">
            <div
              className="p-2 rounded-lg border-2 cursor-pointer"
              onClick={() => {
                setIsViewDetailsPopUp(null);
                // setDefaultTabSelected && setDefaultTabSelected('');
              }}
            >
              <ArrowSmallLeftIcon className="w-4 h-4" />
            </div>
            <div className=" flex items-center justify center text-xl font-semibold">
              Source Data Details
            </div>
          </div>
        </div>
        <div className="flex w-full justify-between p-6 mt-[54px]">
          <p className="font-figtree font-semibold text-base">
            {isViewDetailsPopUp?.data?.[0]} | {isViewDetailsPopUp?.data?.[1]}
          </p>
          <KebabIcon className="cursor-pointer" />
        </div>
        <div className="space-y-5 px-6">
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            handleTab={(e) => setSelectedTab(e.tabLabel)}
          />
          {selectedTab === tabs?.[0]?.tabLabel && (
            <Details
              pivotedColumns={subHeadings?.slice(
                0,
                benchmarkWithColSpan?.[1]?.colSpan + 1,
              )}
              pivotedData={isViewDetailsPopUp?.data?.slice(
                0,
                benchmarkWithColSpan?.[1]?.colSpan + 1,
              )}
              columns={[
                { name: 'Position', value: 'Position' },
                ...(subHeadings
                  ?.slice(
                    benchmarkWithColSpan?.[1]?.colSpan + 1,
                    benchmarkWithColSpan?.[1]?.colSpan +
                      1 +
                      benchmarkWithColSpan?.[2]?.colSpan,
                  )
                  ?.map((column) => {
                    return {
                      name: column,
                      value: column,
                    };
                  }) ?? []),
              ]}
              data={benchmarkWithColSpan
                ?.filter((data, index) => index > 1)
                ?.map((col, ind) => {
                  const detailsData = isViewDetailsPopUp?.data ?? [];
                  return {
                    id: col?.id,
                    data: [
                      col?.columnName?.split('_')?.[0],
                      ...(detailsData?.filter(
                        (data, i) =>
                          i >=
                            benchmarkWithColSpan?.[1]?.colSpan +
                              1 +
                              ind * benchmarkWithColSpan?.[ind + 2]?.colSpan &&
                          i <
                            benchmarkWithColSpan?.[1]?.colSpan +
                              1 +
                              (ind + 1) *
                                benchmarkWithColSpan?.[ind + 2]?.colSpan,
                      ) ?? []),
                    ],
                  };
                })}
              rangeDataId={isViewDetailsPopUp?.id}
            />
          )}
          {selectedTab === tabs?.[1]?.tabLabel && <Benchmarks />}
          {selectedTab === tabs?.[2]?.tabLabel && (
            <VersionHistory
              isViewDetailsPopUp={isViewDetailsPopUp}
              pivotMap={pivotMap}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewDetailsPopUp;
