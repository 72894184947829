import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { colorArrayShade1 } from '../constants';
import { RangeTable } from '../jobMatching/rangeTable/RangeTable';
import { FinalRangePopUp } from './finalRangePopUp/FinalRangePopUp';

export const ViewFinalRangeData = ({ finalRangeData }) => {
  const [benchmarkWithColSpan, setbenchmarkWithColSpan] = useState();
  const [subHeadings, setSubHeadings] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [pivotedColumns, setPivotedColumns] = useState([]);
  const [sidePopUpVisible, setSidePopUpVisible] = useState(false);
  const [activePopUpId, setActivePopUpId] = useState('');

  useEffect(
    () =>
      setPivotedColumns([
        ...Object.keys(finalRangeData?.calculations?.[0]?.pivotedJson || {}),
        'Benchmarks',
        'Employees',
      ]),
    [],
  );

  useEffect(() => {
    setbenchmarkWithColSpan([
      { id: v4(), columnName: 'Job Details', colSpan: 1 },
      { id: v4(), columnName: '', colSpan: pivotedColumns?.length - 1 },
      ...(Object.entries(finalRangeData?.benchMarkMap || {}) || [])
        .map((benchmark) => ({
          id: benchmark?.[0],
          columnName: benchmark?.[1],
        }))
        .map((benchmark, index) => ({
          ...benchmark,
          colSpan: Object.keys(
            finalRangeData?.calculations?.[0]?.finalCalc || {},
          )
            ?.map((key) => key.split('_'))
            ?.filter((item) => item?.[1] === benchmark?.id)?.length,
          backgroundColor: colorArrayShade1[index],
        })),
    ]);
  }, [pivotedColumns]);

  useEffect(() => {
    let headings = [];
    benchmarkWithColSpan
      ?.slice(2, benchmarkWithColSpan?.length)
      ?.map((benchmark) =>
        Object.keys(finalRangeData?.calculations?.[0]?.finalCalc || {})
          ?.map((key) => key.split('_'))
          ?.filter((item) => item?.[1] === benchmark?.id),
      )
      ?.map((heading) => {
        headings = [...headings, ...(heading?.map((item) => item?.[0]) || [])];
      });
    setSubHeadings([...pivotedColumns, ...headings]);
  }, [benchmarkWithColSpan]);

  useEffect(() => {
    const data = finalRangeData?.calculations?.map((calculation) => {
      const pivotData = [
        ...(Object.values(calculation?.pivotedJson) || []),
        calculation?.benchmarkCount || 0,
        calculation?.empCount || 0,
      ];
      let benchmarkData = [];
      benchmarkWithColSpan
        ?.slice(2, benchmarkWithColSpan?.length)
        ?.map((benchmark) => {
          benchmarkData = [
            ...benchmarkData,
            ...(Object.entries(
              finalRangeData?.calculations?.[0]?.finalCalc || {},
            )
              ?.filter((key) => benchmark?.id === key?.[0]?.split('_')?.[1])
              ?.map((item) => item?.[1]) || []),
          ];
        });
      return {
        id: calculation?.id,
        data: [...pivotData, ...benchmarkData],
      };
    });

    setTableData(data);
  }, [benchmarkWithColSpan]);

  console.log(
    Object.entries(finalRangeData?.calculations?.[0]?.finalCalc || {})
      ?.filter(
        (key) =>
          benchmarkWithColSpan?.slice(2, benchmarkWithColSpan?.length)?.[0]
            ?.id === key?.[0]?.split('_')?.[1],
      )
      ?.map((item) => item?.[1]),
    'testing',
  );

  return (
    <>
      <RangeTable
        benchmarkWithColSpan={benchmarkWithColSpan}
        subHeadings={subHeadings}
        tableData={tableData}
        selectedRowId={selectedRowId}
        setSelectedRowId={setSelectedRowId}
        setViewDetails={setSidePopUpVisible}
        setActivePopUpId={setActivePopUpId}
        type="Final Range"
      />
      {sidePopUpVisible && (
        <FinalRangePopUp
          pivotedColumns={pivotedColumns}
          pivotedData={tableData
            ?.filter((data) => data?.id === activePopUpId)?.[0]
            ?.data?.slice(0, pivotedColumns?.length)}
          detailsTableColumns={[
            'Position',
            ...(Object.keys(finalRangeData?.calculations?.[0]?.finalCalc || {})
              .map((key) => key.split('_'))
              .filter(
                (item) =>
                  item?.[1] ===
                  benchmarkWithColSpan?.slice(
                    2,
                    benchmarkWithColSpan?.lengh,
                  )?.[0]?.id,
              )
              .map((item) => item[0]) || []),
          ]}
          detailsTableValues={benchmarkWithColSpan
            .slice(2, benchmarkWithColSpan?.length)
            .map((benchmark) => {
              return [
                [benchmark?.columnName],
                ...(Object.entries(
                  finalRangeData?.calculations?.filter(
                    (calculation) => calculation?.id === activePopUpId,
                  )?.[0]?.finalCalc || {},
                )
                  ?.filter((key) => benchmark?.id === key?.[0]?.split('_')?.[1])
                  ?.map((item) => [item?.[1]]) || []),
              ];
            })}
          setViewDetails={setSidePopUpVisible}
          activePopUpId={activePopUpId}
          benchmarkColumns={benchmarkWithColSpan.slice(
            2,
            benchmarkWithColSpan?.length,
          )}
        />
      )}
    </>
  );
};
