import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';
import { del, get, patch, post, put } from '../apiCalls';

export const getCountryList = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-nominee/get-global-country`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
export const getNomineeDocumentListBasedOnCounty = async (countryName) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${
        CONSTANT.API_BENEFITS_V2
      }/employee-nominee/get-employee-document?countryName=${
        countryName || 'India'
      }`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

export const fetchEmployeeById = async (authId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/get-employee-fields-data/${authId}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
export const fetchCustomFieldEmployeeById = async (authId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/get-custom-field/${authId}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// update employee
export const updateEmployeeById = async (authId, data) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/update-employee-fields/${authId}`,
      data,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// update social links on employee
export const updateEmployeeSocialLinks = async (data) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/update-social-links`,
      data,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// fetch my peers
export const fetchMyPeersById = async (authId, page, limit) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/get-my-peers/${authId}`;
    if (limit) {
      url += `?pageIndex=${page}&pageSize=${limit}`;
    }
    const response = await get(url);
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// fetch my reportees
export const fetchMyReportessById = async (authId, page, limit) => {
  try {
    let url = `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/get-my-direct-reportees/${authId}`;
    if (limit) {
      url += `?pageIndex=${page}&pageSize=${limit}`;
    }
    const response = await get(url);
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

export const fetchTargetUserAccess = async (userId) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_RBAC_V3}/target-user-access?targetUserId=${userId}`,
    );
    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! target user access fetch failed');
    }
  }
};

// fetch Skills
export const fetchMySkillsById = async (authId, page, limit) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/skills/${authId}?pageIndex=${page}&pageSize=${limit}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// fetch insterests

export const fetchMyInterestsById = async (authId, page, limit) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/interests/${authId}?pageIndex=${page}&pageSize=${limit}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

export const fetchAllInterests = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/interests`,
    );
    console.log(response);
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

export const fetchAllSkills = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/skills`,
    );
    console.log(response);
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// search skills
export const searchSkills = async (search, page, limit) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/skills?pageIndex=${page}&pageSize=${limit}&skill=${search}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
export const searchValues = async (search, page, limit) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/values?pageIndex=${page}&pageSize=${limit}&value=${search}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! values fetch failed');
    }
  }
};
// search interests

export const searchInterests = async (search, page, limit) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/interests?pageIndex=${page}&pageSize=${limit}&interest=${search}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// update about
export const updateAbout = async (data) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/${CONSTANT.API_EMP}/update-about-info`,
      data,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// get All employee flags
export const fetchAllEmployeeFlags = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_COMPENSATION_V2}/${CONSTANT.API_EMPLOYEE}/get-flags`,
      payload,
    );
    const resultData = response;
    return resultData;
  } catch (error) {
    return error;
  }
};
export const updateEmployeeFlags = async (payload) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_COMPENSATION_V2}/${CONSTANT.API_EMPLOYEE}/update-employee-flag`,
      payload,
    );
    const resultData = response;
    return resultData;
  } catch (error) {
    return error;
  }
};
// get employee flags
export const fetchEmployeeFlags = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_COMPENSATION_V2}/${CONSTANT.API_EMPLOYEE}/flags/${id}`,
    );
    const resultData = response;
    return resultData;
  } catch (error) {
    return error;
  }
};
export const fetchEmployeeFlagsHistory = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_COMPENSATION_V2}/${CONSTANT.API_EMPLOYEE}/history-flags/${id}`,
    );
    const resultData = response;
    return resultData;
  } catch (error) {
    return error;
  }
};

// fetch internal Work
export const getInternalWork = async (id, planId) => {
  let URL = `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/get-internal-work?id=${id}`;
  if (planId) {
    URL += `&planId=${planId}`;
  }
  try {
    const response = await get(URL);
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// fetch badges

export const fetchMyBadges = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/get-badge?id=${id}`,
    );

    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Badge fetch failed');
    }
  }
};

// fetch values

export const fetchMyValues = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/get-values?id=${id}`,
    );

    return response.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Values fetch failed');
    }
  }
};

// fectch recommendations

export const getRecommendation = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/get-recommendation?id=${id}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// create recommendations
export const addRecommendations = async (data) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/create-recommendation`,
      data,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// fetch External Work

export const getExternalWork = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${
        CONSTANT.API_SURFACE_V2
      }/profile/get-external-work${id ? `?id=${id}` : ''}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// create external work

export const addExternalWork = async (data) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/create-external-work`,
      data,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// edit external work

export const editExternalWork = async (data) => {
  try {
    const response = put(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/edit-external-work`,
      data,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// get Project

export const getProjects = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/get-project${
        id ? `?id=${id}` : ''
      }`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// add Project
export const addProjects = async (data) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/create-project`,
      data,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// edit project

export const updateProjects = async (data) => {
  try {
    const response = await put(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/edit-project`,
      data,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// get Education

export const getEducations = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/education/get-all-education?id=${id}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// add Education

export const addEducation = async (data, type) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/education/create-education`,
      data,
      type,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// EDIT Education

export const editEducation = async (data, type, id) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/education/update-education/${id}`,
      data,
      type,
    );
    return response;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// get all certificates

export const getAllCertificates = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/certificate/get-all-certificate?id=${id}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// add certificates

export const addCertificate = async (data, type) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/certificate/create-certificate`,
      data,
      type,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// edit certificates

export const editCertificate = async (data, type, id) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/certificate/update-certificate/${id}`,
      data,
      type,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// get my hierarchy

export const getMyHierarhy = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/get-my-hierarchy?id=${id}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// get my office presence

export const getMyOfficePresence = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/get-office-presence?id=${id}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// update the ofice presence

export const updateOfficePresence = async (data) => {
  try {
    const response = await put(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/edit-office-presence`,
      data,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// update user image

export const updateUserImage = async (data, type) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/edit-profile-image`,
      data,
      type,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// update banner image
export const updateCoverImage = async (data, type) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/edit-banner-image`,
      data,
      type,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// Create-nominee

export const addNominee = async (data, type) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-nominee/create-employee-nominee`,
      data,
      type,
    );
    const resultData = response;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
export const addNomineeBenefit = async (data) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-nominee/create-employee-nominee-purpose`,
      data,
    );
    return response?.data;
  } catch (err) {
    if (err) {
      throw new AxiosError(err);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
export const getNomineeHeaders = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-nominee/get-employee-nominee-purpose/${id}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// get all nominee
export const getNominees = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-nominee/get-all-employee-nominee?userId=${id}&pageSize=15&pageIndex=0`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// delete a nominee
export const deleteNominee = async (id) => {
  try {
    const response = await del(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-nominee/delete-employee-nominee/${id}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

export const deleteWork = async (employeeWork, id) => {
  try {
    const response = await del(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/profile/delete-work/${employeeWork}/${id}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

// get a nominee
export const getANominee = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-nominee/get-employee-nominee/${id}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// update a Nominee
export const updateNominee = async (id, data, type) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-nominee/update-employee-nominee/${id}`,
      data,
      type,
    );
    const resultData = response;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// Create-Dependent

export const addDependent = async (data) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-dependent/create-employee-dependent`,
      data,
    );
    const resultData = response;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// get all Dependent
export const getDependent = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-dependent/get-all-employee-dependent?userId=${id}&pageSize=15&pageIndex=0`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// delete a Dependent
export const deleteDependent = async (id) => {
  try {
    const response = await del(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-dependent/delete-employee-dependent/${id}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// get a Dependent
export const getADependent = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-dependent/get-employee-dependent/${id}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
// update a Dependent
export const updateDependent = async (id, data, type) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_BENEFITS_V2}/employee-dependent/update-employee-dependent/${id}`,
      data,
      type,
    );
    const resultData = response;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
