import { Loader } from 'components/atoms/loader/Loader';
import MarketData from 'pages/rangeBuilder/externalData/viewExternalData/viewDetailsPopUp/marketData/MarketData';
import { formatDate } from 'pages/recognition/orderHistory/helper';
import { useGetAgingConfig } from 'query/rangeBuilder/jobMatching/jobMatching';
import React from 'react';
import { useParams } from 'react-router-dom';

const Details = ({
  pivotedColumns,
  pivotedData,
  columns,
  data,
  rangeDataId,
}) => {
  const { id: planId } = useParams();
  const { data: agingConfigData, isLoading: isAgingConfigLoading } =
    useGetAgingConfig(planId, rangeDataId);
  return (
    <div>
      {isAgingConfigLoading ? (
        <div
          className="flex w-full items-center justify-center"
          style={{
            height: 'calc(100vh - 228px)',
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-5">
          <div className="rounded-lg border border-gray-200 space-y-6 pb-6">
            <p className="py-[10px] px-5 bg-gray-50 font-semibold rounded-t-lg">
              Job Details
            </p>
            <div className="flex items-center flex-wrap gap-8">
              {pivotedColumns?.map((column, index) => {
                return (
                  <div key={column} className="w-[21.7%] pl-5 space-y-1">
                    <p className="text-xs font-medium text-gray-600">
                      {column}
                    </p>

                    <p className="text-sm font-medium text-gray-700">
                      {pivotedData[index]}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          {!isAgingConfigLoading && agingConfigData?.length > 0 && (
            <div className="rounded-lg border border-gray-200 space-y-6 pb-6">
              <p className="py-[10px] px-5 bg-gray-50 font-semibold rounded-t-lg">
                Ageing
              </p>
              <div className="flex items-center flex-wrap gap-8">
                <div className="w-[21.7%] pl-5 space-y-1">
                  <p className="text-xs font-medium text-gray-600">{'Rate'}</p>
                  <p className="text-sm font-medium text-gray-700">
                    {agingConfigData?.[0]?.rate}
                  </p>
                </div>
                <div className="w-[21.7%] pl-5 space-y-1">
                  <p className="text-xs font-medium text-gray-600">
                    {'Frequency'}
                  </p>
                  <p className="text-sm font-medium text-gray-700">
                    {agingConfigData?.[0]?.frequency}
                  </p>
                </div>
                <div className="w-[21.7%] pl-5 space-y-1">
                  <p className="text-xs font-medium text-gray-600">
                    {'Start Date'}
                  </p>
                  <p className="text-sm font-medium text-gray-700">
                    {formatDate(agingConfigData?.[0]?.startDate)}
                  </p>
                </div>
                <div className="w-[21.7%] pl-5 space-y-1">
                  <p className="text-xs font-medium text-gray-600">
                    {'End Date'}
                  </p>
                  <p className="text-sm font-medium text-gray-700">
                    {formatDate(agingConfigData?.[0]?.endDate)}
                  </p>
                </div>
              </div>
            </div>
          )}
          <>
            <MarketData columns={columns} data={data} />
          </>
        </div>
      )}
    </div>
  );
};

export default Details;
