// CompensationRoutes.js
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const AdhocApprover = lazy(() =>
  import('pages/compensation/adhocApprover/AdhocApprover'),
);
const ComingSoon = lazy(() => import('pages/admin/comingSoon/ComingSoon'));
const CompPlanSummary = lazy(() =>
  import('pages/compensation/compPlanSummary/CompPlanSummary'),
);
const CreatePlanning = lazy(() =>
  import('pages/compensation/compPlanning/createPlanning/CreatePlanning'),
);
const ManagePlanning = lazy(() =>
  import('pages/compensation/compPlanning/managePlanning/ManagePlanning'),
);
const CompensationPlanWorksheet = lazy(() =>
  import(
    'pages/compensation/compensationPlanWorksheet/CompensationPlanWorksheet'
  ),
);
const RewardPhilosophy = lazy(() =>
  import('pages/compensation/rewardsPhilosophy/RewardPhilosophy'),
);
const CompSnapShot = lazy(() =>
  import('pages/profile/profileModules/compensation/compSnapshot/CompSnapshot'),
);

const CompensationRoutes = [
  {
    type: 'collapse',
    name: 'ManageCompensation',
    key: 'compensation',
    parentId: 'Compensation',
    module: 'Compensation',
    subModule: 'Planning',
    flagName: 'Managecompensationplans',
    route: '/compensation/manage-plan',
    component: (
      <Navigate
        to={`/compensation/manage-plan/current?pageSize=10&pageIndex=0&sortType=desc&sortBy=modifiedDate`}
        replace
      />
    ),
  },
  {
    type: 'collapse',
    name: 'ManageCompensationTempo',
    key: 'compensationTempo',
    parentId: 'Compensation',
    module: 'Compensation',
    subModule: 'Planning',
    route: '/compensation/manage-plan/:type',
    flagName: 'Managecompensationplans',
    component: <ManagePlanning />,
  },
  {
    type: 'collapse',
    name: 'ManageCompensationTempo',
    key: 'compensationTempo',
    parentId: 'Compensation',
    module: 'Compensation',
    subModule: 'Planning',
    route: '/compensation/compensation-worksheets',
    flagName: 'CompensationWorksheets',
    component: (
      <Navigate
        to={`/compensation/compensation-worksheets/current?pageSize=10&pageIndex=0&sortType=desc&sortBy=modifiedDate`}
        replace
      />
    ),
  },
  {
    type: 'collapse',
    name: 'CompensationWorksheet',
    key: 'CompensationWorksheet',
    parentId: 'Compensation',
    module: 'Compensation',
    subModule: 'Planning',
    route: '/compensation/compensation-worksheets/:type',
    flagName: 'CompensationWorksheets',
    component: <CompensationPlanWorksheet />,
  },
  {
    type: 'collapse',
    name: 'ManageCompensationTempo',
    key: 'compensationTempo',
    parentId: 'Compensation',
    module: 'Compensation',
    subModule: 'Planning',
    route: '/compensation/compensation-worksheets',
    flagName: 'CompensationWorksheetsAdmin',
    component: (
      <Navigate
        to={`/compensation/compensation-worksheets/current?pageSize=10&pageIndex=0&sortType=asc&sortBy=name`}
        replace
      />
    ),
  },
  {
    type: 'collapse',
    name: 'CompensationWorksheet',
    key: 'CompensationWorksheet',
    parentId: 'Compensation',
    module: 'Compensation',
    subModule: 'Planning',
    route: '/compensation/compensation-worksheets/:type',
    flagName: 'CompensationWorksheetsAdmin',
    component: <CompensationPlanWorksheet />,
  },
  {
    type: 'collapse',
    name: 'ManageCompensation',
    key: 'compensation',
    parentId: 'Compensation',
    module: 'Compensation',
    subModule: 'Planning',
    route: '/compensation/manage-plan/create',
    flagName: 'Managecompensationplans',
    component: <CreatePlanning />,
  },
  {
    type: 'collapse',
    name: 'UpdateCompensation',
    key: 'compensation',
    parentId: 'Compensation',
    module: 'Compensation',
    subModule: 'Planning',
    route: '/compensation/manage-plan/update',
    flagName: 'Managecompensationplans',
    component: <CreatePlanning />,
  },
  {
    type: 'collapse',
    name: 'CompPlanSummary',
    key: 'compPlanSummary',
    parentId: 'Compensation',
    module: 'Compensation',
    subModule: 'Planning',
    flagName: 'CompensationWorksheets',
    route: '/compensation/plan-summary',
    component: <CompPlanSummary />,
  },
  {
    type: 'collapse',
    name: 'CompPlanSummary',
    key: 'compPlanSummary',
    parentId: 'Compensation',
    module: 'Compensation',
    subModule: 'Planning',
    flagName: 'CompensationWorksheetsAdmin',
    route: '/compensation/plan-summary',
    component: <CompPlanSummary />,
  },
  {
    type: 'collapse',
    name: 'adhocApprover',
    key: 'adhocApprover',
    parentId: 'Compensation',
    module: 'Compensation',
    route: '/adhoc-approver/:id',
    component: <AdhocApprover />,
  },
  {
    type: 'collapse',
    name: 'rewardsPhilosophyPage',
    key: 'rewardsPhilosophyPage',
    parentId: 'Compensation',
    module: 'Compensation',
    route: '/compensation/rewards-philosophy',
    flagName: 'RewardsPhilosophy',
    component: <RewardPhilosophy />,
  },
  {
    type: 'collapse',
    name: 'comp-snapshot',
    key: 'comp-snapshot',
    route: '/compensation/snapshot/:id',
    parentId: null,
    module: 'Compensation',
    component: <CompSnapShot />,
  },
  {
    type: 'collapse',
    name: 'Increment Philosophy',
    key: 'Increment Philosophy',
    route: '/compensation/increment-philosophy',
    parentId: 'Admin',
    flagName: 'Viewinsights',
    module: 'Admin',
    component: <ComingSoon />,
  },
  {
    type: 'collapse',
    name: 'Transact Now',
    key: 'Transact Now',
    route: '/compensation/transact-now',
    parentId: 'ESOP',
    flagName: 'TransactEquity',
    module: 'ESOP',
    component: <ComingSoon />,
  },
  {
    type: 'collapse',
    name: 'Bonus Calculations',
    key: 'Bonus Calculations',
    route: '/compensation/bonus-calculations',
    parentId: 'Admin',
    flagName: 'ManageCompensationBonus',
    module: 'Compensation',
    component: <ComingSoon />,
  },
];

export default CompensationRoutes;
