import React from 'react';
import { useFormContext } from 'react-hook-form';
import VideosAtom from '../../atoms/videoatom/VideoAtom';
import Heading from '../../atoms/heading/Heading';
import Paragraph from '../../atoms/paragraph/Paragraph';
import { htmlParser } from 'utils/jsUtils';

const VHPComponent = (props) => {
  const { data } = props;
  const { selectAtomsMolecules, type, path } = data;
  const { watch } = useFormContext();
  const content = watch(`${path}.para.value`);
  return (
    <div
      onClick={() => selectAtomsMolecules(type)}
      className="w-full h-full flex flex-col gap-3 justify-between p-4 cursor-pointer"
    >
      <div className="h-36 w-full flex justify-center items-center">
        <VideosAtom data={data} />
      </div>
      <div className="h-28 px-4">
        <Heading data={data} />
        {content ? (
          <div
            onClick={() => selectAtomsMolecules(type)}
            className="parent-list-default flex flex-col h-20 overflow-auto"
          >
            {' '}
            {htmlParser(content)}{' '}
          </div>
        ) : (
          <Paragraph data={data} />
        )}
      </div>
    </div>
  );
};

export default VHPComponent;
