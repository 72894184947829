import { RadioButton } from 'components/atoms/FormElements/input/Input';
import { useEffect, useState } from 'react';

export const MethodOfCalculation = ({ planDetails, setPlanDetails }) => {
  const [referencePointSelected, setReferencePointSelected] =
    useState('3 point');
  const [outputSelected, setOutputSelected] = useState(
    'Range Based on Mid Point',
  );

  const referencePoints = [
    {
      id: '1',
      label: '3 Point (Min-mid-Max)',
      description: 'Min, Mid and Max',
      value: '3 point',
    },
    {
      id: '2',
      label: '4 Point (Quartile)',
      description: 'Q1, Q2, Q3 and Q4',
      value: '4 point',
    },
    {
      id: '3',
      label: 'Multi Points (Percentile)',
      description: 'P10, P25, P33, P50, P75 etc',
      value: 'Multi point',
    },
  ];

  const output = [
    {
      id: '1',
      label: 'Range Based on Mid Point',
      description: 'Min and Max as a Percentage of Mid Point ',
      value: 'Range Based on Mid Point',
    },
    {
      id: '1',
      label: 'Individual Percentile Point References',
      description: 'Min, Mid and Max as Individual References',
      value: 'Individual Percentile Point References',
    },
  ];

  useEffect(() => {
    setPlanDetails({
      ...planDetails,
      modeOfCalculationReference: referencePointSelected,
      modeOfCalculationOutput: outputSelected,
    });
  }, [referencePointSelected, outputSelected]);

  return (
    <div className="px-5 pt-6 pb-5 space-y-5 bg-white rounded-xl shadow-md">
      <p className="text-sm font-semibold text-gray-900 pb-[10px]">
        Method of Caluculation
      </p>
      <div className="space-y-4">
        <div className="border border-gray-200 rounded-[10px] space-y-5 pb-5">
          <p className="bg-gray-50 px-5 py-[10px] border-b border-gray-200 text-sm font-medium text-gray-900">
            Reference Points
          </p>
          <div className="flex items-center gap-8">
            {referencePoints?.map((referencePoint) => {
              return (
                <div
                  key={referencePoint?.id}
                  className="text-sm font-medium text-gray-700 w-1/5"
                >
                  <RadioButton
                    name="referencePoints"
                    id={referencePoint?.label}
                    checked={referencePoint?.value === referencePointSelected}
                    label={referencePoint?.label}
                    onChange={() =>
                      setReferencePointSelected(referencePoint?.value)
                    }
                    value={referencePoint?.value}
                  />
                  <p className="ml-10 !text-gray-600 !text-sm !font-normal">
                    {referencePoint?.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        {referencePointSelected === '3 point' && (
          <div className="border border-gray-200 rounded-[10px] space-y-5 pb-5">
            <p className="bg-gray-50 px-5 py-[10px] border-b border-gray-200 text-sm font-medium text-gray-900">
              Output
            </p>
            <div className="flex items-center gap-8">
              {output?.map((option) => {
                return (
                  <div
                    key={option?.id}
                    className="text-sm font-medium text-gray-700 w-1/4"
                  >
                    <RadioButton
                      name="output"
                      id={option?.label}
                      value={option?.value}
                      checked={option?.value === outputSelected}
                      label={option?.label}
                      onChange={() => setOutputSelected(option?.value)}
                    />
                    <p className="ml-10 !text-gray-600 !text-sm !font-normal">
                      {option?.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
