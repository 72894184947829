import Toggle from 'components/atoms/FormElements/toggle/Toggle';
import Carousel from 'components/atoms/carousel/Carousel';
import RecognitionChampionCard from 'pages/surface/recognitionChampionCard/RecognitionChampionCard';
import { useGetAllAnnouncement } from 'query/surface/announcement';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setViewingAs } from 'store/reducers/themeSlice';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { checkModuleAccess } from 'utils/accessControl';
import { AppsList } from './AppsList';
import { PendingTasks } from './PendingTasks';
import QuickLinks from './QuickLinks';
import Announcements from './panel2/Annoucements';
import Badges from './panel2/Badges';
import People from './panel2/People';

export const AlternateScreenV2 = () => {
  const theme = useSelector((state) => state.theme);
  const { data: announcements } = useGetAllAnnouncement(10, 0, {});
  const [announcementDetail, setAnnouncementDetail] = useState([]);
  const [isMyTeamActive, setIsMyTeamActive] = useState(
    theme.viewingAs?.includes('Manager'),
  );
  const [isAdminActive, setIsAdminActive] = useState(
    theme.viewingAs?.includes('Admin'),
  );
  const user = useSelector((state) => state.user);
  const modules = useSelector((state) => state.theme?.rbac);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [activePopUpId, setActivePopUpId] = useState('');
  const dispatch = useDispatch();

  const handleMyTeamToggle = (event) => {
    setIsMyTeamActive(event.target.checked);
    event.target.checked
      ? dispatch(setViewingAs([...theme.viewingAs, 'Manager']))
      : dispatch(
          setViewingAs(theme.viewingAs?.filter((item) => item !== 'Manager')),
        );
  };

  const handleAdminToggle = (event) => {
    setIsAdminActive(event.target.checked);
    event.target.checked
      ? dispatch(setViewingAs([...theme.viewingAs, 'Admin']))
      : dispatch(
          setViewingAs(theme.viewingAs?.filter((item) => item !== 'Admin')),
        );
  };

  useEffect(() => {
    if (user?.accessToken) {
      const slides = announcements?.data
        ?.map((announcement) => {
          if (announcement?.banner && announcement?.banner?.isActive) {
            return {
              url: imageURLCheck(`${announcement?.banner?.imageUrl}`),
              thumbnailUrl: `/announcement/${announcement?.id}`,
            };
          }
        })
        .filter((ele) => ele);
      setAnnouncementDetail(slides);
    }
  }, [announcements?.data, user?.accessToken]);

  return (
    <div className="px-8 py-6 space-y-9 w-full">
      <div className="flex items-center justify-between">
        <p className="text-gray-900 font-semibold text-2xl">
          Hello,
          <span className="bg-gradient text-transparent bg-clip-text">
            {` ${user?.userProfileData?.fullName?.value}`}
          </span>
        </p>
        <div className="flex items-center gap-4 font-medium text-sm">
          <div className="flex items-center gap-2">
            <p>My Team</p>
            <Toggle checked={isMyTeamActive} onChange={handleMyTeamToggle} />
          </div>
          <div className="flex items-center gap-2">
            <p>Admin</p>
            <Toggle checked={isAdminActive} onChange={handleAdminToggle} />
          </div>
        </div>
      </div>
      <div className="flex w-full gap-6">
        <div className="w-3/4 space-y-4">
          <div>
            {announcementDetail && announcementDetail.length ? (
              <Carousel
                slides={announcementDetail}
                autoSlideInterval={6000}
                rounded="rounded-xl"
                border
              />
            ) : null}
          </div>
          <AppsList
            label="My Apps"
            type="MyApps"
            navItems={theme.sidenav}
            setPopUpVisible={setPopUpVisible}
            popUpVisible={popUpVisible}
            activePopUpId={activePopUpId}
            setActivePopUpId={setActivePopUpId}
          />
          {isMyTeamActive && (
            <AppsList
              label="Team Apps"
              type="TeamApps"
              setPopUpVisible={setPopUpVisible}
              popUpVisible={popUpVisible}
              navItems={theme.sidenav}
              activePopUpId={activePopUpId}
              setActivePopUpId={setActivePopUpId}
            />
          )}
          {isAdminActive && (
            <AppsList
              label="Admin Control"
              type="AdminControl"
              navItems={theme.sidenav}
              setPopUpVisible={setPopUpVisible}
              popUpVisible={popUpVisible}
              activePopUpId={activePopUpId}
              setActivePopUpId={setActivePopUpId}
            />
          )}
          <QuickLinks />
        </div>

        <div className="flex flex-col gap-6 w-1/4">
          <PendingTasks />
          <People isMyTeamActive={isMyTeamActive} />
          {checkModuleAccess(modules, 'SurfaceAnnouncement') && (
            <Announcements />
          )}
          {checkModuleAccess(modules, 'Recognition') && (
            <>
              <Badges />
              <RecognitionChampionCard isClickable />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
