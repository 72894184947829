import ViewTemplateSection from './viewTemplateSection/ViewTemplateSection';

const ViewTemplatePanel = ({ landingPageData }) => {
  // const { landingPageData } = useSelector(templateBuilderSelector);
  const sortedData = landingPageData.data
    .slice()
    .sort((a, b) => a.order - b.order);

  return (
    <div>
      <div className="px-8 bg-white ring-1 ring-gray-200 shadow-xl relative rounded-lg h-full -mt-6 mx-6 z-40 h-42 py-8 flex flex-col gap-6">
        <div className=" flex justify-between items-center gap-5">
          <h1 className="text-2xl font-semibold">{landingPageData.title}</h1>
          <div className="flex flex-row justify-end items-center p-0 gap-3 w-3/12 h-9">
            {/* <Button>Button</Button>
            <Button variant={"filled"}>Button</Button> */}
          </div>
        </div>
        <div className="w-full">
          {sortedData.map((row, index) => (
            <ViewTemplateSection key={index} columnsData={row} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewTemplatePanel;
