import AboutCompanyIcon from 'assets/svg/aboutCompany';
import AccidentalIcon from 'assets/svg/accidental';
import AllCommunitiesIcon from 'assets/svg/allCommunities';
import BonusCalculationsIcon from 'assets/svg/bonusCalculations';
import CompensationPlanningIcon from 'assets/svg/compensationPlanning';
import DashboardIcon from 'assets/svg/dashboard';
import EquityIcon from 'assets/svg/equity';
import EquitySummaryIcon from 'assets/svg/equitySummary';
import ForecastAndSimulationIcon from 'assets/svg/forecastAndSimulation';
import HealthInsuranceIcon from 'assets/svg/healthInsurance';
import HiringIntelligenceIcon from 'assets/svg/hiringIntelligence';
import { HomeIcon } from 'assets/svg/homeIcon';
import IncrementPhilosophyIcon from 'assets/svg/incrementPhilosophy';
import InsightsIcon from 'assets/svg/insights';
import InsuranceIcon from 'assets/svg/insurance';
import LettersIcon from 'assets/svg/letters';
import LifeInsuranceIcon from 'assets/svg/lifeInsurance';
import ManageBenefitsIcon from 'assets/svg/manageBenefits';
import ManageCompensationPlanIcon from 'assets/svg/manageCompensationPlan';
import ManageFormsIcon from 'assets/svg/manageForms';
import ManageLetterTemplatesIcon from 'assets/svg/manageLetterTemplates';
import ManageOfferIcon from 'assets/svg/manageOffer';
import ManagePoliciesIcon from 'assets/svg/managePolicies';
import ManageProgramsIcon from 'assets/svg/managePrograms';
import ManageRecognitionIcon from 'assets/svg/manageRecognition';
import ManageWorkflowsIcon from 'assets/svg/manageWorkflows';
import { MarketBenchmarkingIcon } from 'assets/svg/marketBenchmarking';
import MastersIcon from 'assets/svg/masters';
import MyClaimsIcon from 'assets/svg/myClaims';
import MyCommunitiesIcon from 'assets/svg/myCommunities';
import MyRecognitionIcon from 'assets/svg/myRecognition';
import OffCycleIncrementsIcon from 'assets/svg/offCycleIcrements';
import PermissionControlsIcon from 'assets/svg/permissionControls';
import PermissionRolesIcon from 'assets/svg/permissionRoles';
import PoliciesLogo from 'assets/svg/policiesLogo';
import QuickLinkIcon from 'assets/svg/quickLinkIcon';
import RedeemRewardsIcon from 'assets/svg/redeemRewards';
import ReportsIcon from 'assets/svg/reports';
import SettingIcon from 'assets/svg/settingIcon';
import StatementsIcon from 'assets/svg/statements';
import SummaryIcon from 'assets/svg/summary';
import TotalRewardsPhilosophyIcon from 'assets/svg/totalRewardsPhilosophy';
import TotalRewardsSummaryIcon from 'assets/svg/totalRewardsSummary';
import TransactNowIcon from 'assets/svg/transactNow';
import UserGroupsIcon from 'assets/svg/userGroups';
import ViewBenefitsIcon from 'assets/svg/viewBenefits';
import ViewPoliciesIcon from 'assets/svg/viewPolicies';
import { useSelector } from 'react-redux';
import BenefitsLogo from '../../../assets/svg/benefitsLogo';
import CelebrateIcon from '../../../assets/svg/celebrate';
import Classified from '../../../assets/svg/classified';
import CloseIcon from '../../../assets/svg/close';
import CompensationLogo from '../../../assets/svg/compensation';
import DashboardLogo from '../../../assets/svg/dashboardLogo';
import EsopLogo from '../../../assets/svg/esopLogo';
import EventsLogo from '../../../assets/svg/eventsLogo';
import GroupsLogo from '../../../assets/svg/groupsLogo';
import MarketInrelligence from '../../../assets/svg/marketInrelligence';
import MarketPlanning from '../../../assets/svg/marketPlanning';
import MarketplaceLogo from '../../../assets/svg/marketplaceLogo';
import NetworkLogo from '../../../assets/svg/networkLogo';
import NominateIcon from '../../../assets/svg/nominate';
import PostIcon from '../../../assets/svg/post';
import PraiseIcon from '../../../assets/svg/praise';
import RecognitionLogo from '../../../assets/svg/recognitionLogo';
import SurfaceHomeLogo from '../../../assets/svg/surfaceHomeLogo';
import SurveysLogo from '../../../assets/svg/surveysLogo';
import WellnessLogo from '../../../assets/svg/wellnessLogo';

const GetSvgIcon = ({
  iconName,
  isActive,
  isHovered,
  isSubDropDown,
  isQuickLinks,
  width,
  height,
  activeColor,
}) => {
  const { hoverIconColor, activeIconColor } = useSelector(
    (state) => state.theme,
  );
  const white = '#FFFFFF';
  if (isQuickLinks) {
    return <QuickLinkIcon isActive={isActive} activeColor={white} />;
  }

  switch (iconName) {
    case 'Surface':
      return (
        <SurfaceHomeLogo
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Network':
      return <NetworkLogo isActive={isActive} />;
    case 'Events':
      return (
        <EventsLogo
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Communities':
      return (
        <GroupsLogo
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Marketplace':
      return <MarketplaceLogo isActive={isActive} />;
    // case "Insights":
    //   return <DashboardLogo isActive={isActive} />;
    case 'Survey':
      return (
        <SurveysLogo
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Recognition':
      return (
        <RecognitionLogo
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Total Rewards':
      return (
        <CompensationLogo
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Benefits':
      return (
        <BenefitsLogo
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Policies':
      return (
        <PoliciesLogo
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    // case "Insurance":
    //   return <InsuranceLogo isActive={isActive} />;
    case 'Esop':
      return <EsopLogo isActive={isActive} />;
    case 'Wellness':
      return <WellnessLogo isActive={isActive} />;
    // case "Marketplace":
    //   return <MarketplaceKartLogo isActive={isActive} />;
    case 'Market Intelligence':
      return <MarketInrelligence isActive={isActive} />;
    case 'Market Planning':
      return <MarketPlanning isActive={isActive} />;
    case 'Classified':
      return <Classified isActive={isActive} />;
    case 'Post':
      return <PostIcon isActive={isActive} />;
    case 'Praise':
      return <PraiseIcon isActive={isActive} />;
    case 'Celebrate':
      return <CelebrateIcon isActive={isActive} />;
    case 'Nominate':
      return <NominateIcon isActive={isActive} />;
    case 'Close':
      return <CloseIcon isActive={isActive} />;
    case 'Company Settings':
      return <SettingIcon width={width} height={height} isActive={isActive} />;
    // case "Reports":
    //   return <img src={ReportIcon} alt="reports-icon" className="w-[18px] h-[18px]" />;
    case 'My Communities':
      return (
        <MyCommunitiesIcon
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
          width={width}
          height={height}
        />
      );
    case 'All Communities':
      return (
        <AllCommunitiesIcon
          isActive={isActive}
          width={width}
          height={height}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'My Recognition':
      return (
        <MyRecognitionIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Redeem Rewards':
      return (
        <RedeemRewardsIcon
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
          width={width}
          height={height}
        />
      );
    case 'Manage Recognition':
      return (
        <ManageRecognitionIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Manage Forms':
      return (
        <ManageFormsIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Total Rewards Philosophy':
      return (
        <TotalRewardsPhilosophyIcon
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
          width={width}
          height={height}
        />
      );
    case 'Total Rewards Summary':
      return (
        <TotalRewardsSummaryIcon
          isActive={isActive}
          width={width}
          height={height}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Statements':
      return (
        <StatementsIcon
          isActive={isActive}
          width={width}
          height={height}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Forecast and Simulation':
      return (
        <ForecastAndSimulationIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Bonus Calculations':
      return (
        <BonusCalculationsIcon
          isActive={isActive}
          width={width}
          height={height}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Compensation Planning':
      return (
        <CompensationPlanningIcon
          width={width}
          height={height}
          isActive={isActive}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
          activeColor={isSubDropDown ? activeIconColor : white}
        />
      );
    case 'Off-cycle Increments':
      return (
        <OffCycleIncrementsIcon
          isActive={isActive}
          activeColor={activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Manage Compensation Plan':
      return (
        <ManageCompensationPlanIcon
          isActive={isActive}
          width={width}
          height={height}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Increment Philosophy':
      return (
        <IncrementPhilosophyIcon
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
          width={width}
          height={height}
        />
      );
    case 'Hiring Intelligence':
      return (
        <HiringIntelligenceIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Manage Offers':
      return (
        <ManageOfferIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Equity':
      return (
        <EquityIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Home':
      return (
        <HomeIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Equity Summary':
      return (
        <EquitySummaryIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Transact Now':
      return (
        <TransactNowIcon
          isActive={isActive}
          width={width}
          height={height}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'View Policies':
      return (
        <ViewPoliciesIcon
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
          width={width}
          height={height}
        />
      );
    case 'Manage Policies':
      return (
        <ManagePoliciesIcon
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
          width={width}
          height={height}
        />
      );
    case 'View Dashboard':
      return (
        <ViewBenefitsIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Manage Programs':
      return (
        <ManageProgramsIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Manage Benefits':
      return (
        <ManageBenefitsIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Insurance':
      return (
        <InsuranceIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Dashboard':
      return (
        <DashboardIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Summary':
      return (
        <SummaryIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Health Insurance':
      return (
        <HealthInsuranceIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Accidental':
      return (
        <AccidentalIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Life Insurance':
      return (
        <LifeInsuranceIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'My Claims':
      return (
        <MyClaimsIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Quick Link 1':
      return <QuickLinkIcon isActive={isActive} activeColor={white} />;
    case 'Quick Link 2':
      return <QuickLinkIcon isActive={isActive} activeColor={white} />;
    case 'Letters':
      return (
        <LettersIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Manage Letter Templates':
      return (
        <ManageLetterTemplatesIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Benchmark':
      return (
        <MarketBenchmarkingIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Insights':
      return (
        <InsightsIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Reports':
      return (
        <ReportsIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Masters':
      return (
        <MastersIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'Permission Controls':
      return (
        <PermissionControlsIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
    case 'User Groups':
      return (
        <UserGroupsIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Permission Roles':
      return (
        <PermissionRolesIcon
          width={width}
          height={height}
          isActive={isActive}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'Manage Workflows':
      return (
        <ManageWorkflowsIcon
          isActive={isActive}
          width={width}
          height={height}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    case 'About Company':
      return (
        <AboutCompanyIcon
          isActive={isActive}
          width={width}
          height={height}
          activeColor={white}
        />
      );
    case 'Recognition Champions':
      return (
        <DashboardLogo
          isLinkActive={isActive}
          width={width}
          height={height}
          activeColor={activeColor ? activeColor : activeIconColor}
          isHovered={isHovered}
          hoveredColor={hoverIconColor}
        />
      );
    // case "Permission controls":
    //   return (
    //     <img
    //       src={imageURLCheck("/assets/permissionControlLogo.png")}
    //       alt="permission controls icon"
    //       className="w-[18px] h-[18px]"
    //     />
    //   );
    // case "Masters":
    //   return (
    //     <img
    //       src={imageURLCheck("/assets/database-table.png")}
    //       alt="masters icon"
    //       className="w-[18px] h-[18px]"
    //     />
    //   );
    default:
      return (
        <SurfaceHomeLogo
          width={width}
          height={height}
          isActive={isActive}
          activeColor={white}
        />
      );
  }
};

export default GetSvgIcon;
