import {
  CheckIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { getCandidateOfferDetails } from 'apiClient/hiringOffer/hiringOffer';
import EyeIcon from 'assets/svg/eyeIcon';
import Toggle from 'components/atoms/FormElements/toggle/Toggle';
import ProjectData from 'pages/profile/profileModules/compensation/ProjectData';
import TableRowSection from 'pages/profile/profileModules/compensation/TableRowSection';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { imageURLCheck } from 'utils/ImageUrlCheck';
const initialData = {
  firstYear: {
    basePay: 0,
    variablePay: 0,
    benefitsPay: 0,
  },
  secondYear: {
    basePay: 0,
    variablePay: 0,
    benefitsPay: 0,
  },
  thirdYear: {
    basePay: 0,
    variablePay: 0,
    benefitsPay: 0,
  },
  fourthYear: {
    basePay: 0,
    variablePay: 0,
    benefitsPay: 0,
  },
};
const ReviewOffer = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const value1 = queryParams.get('value');
  const value = `U2FsdGVkX1+gFK6zYdSRz/m141IPdoT0e4kNYWLh0wGyJ5+GBc+hChU7/y4++/UjChPM61PWsBLAfwLJ00yA9KZ/Ew0dBHYol8jkLmQzKfkeHX1HGOYdIZXnYaw/ty9ddbwJP4J3rzjCJMCWzOECsg==`;
  const [realizedWealth, setRealizedWealth] = useState(false);
  const [showAmountInMillions, setShowAmountInMillions] = useState(false);
  const [yearData, setYearData] = useState(initialData);
  const [grantsArray, setGrantsArray] = useState([0, 0, 0, 0]);
  const methods = useForm({
    defaultValues: {},
    shouldFocusError: false,
  });
  const firstYearSum = Math.ceil(
    (yearData.firstYear?.basePay || 0) +
      (yearData.firstYear?.variablePay || 0) +
      (yearData.firstYear?.benefitsPay || 0),
    //   +
    //   (allowLtiGrants ? grantsArray[0] || 0 : 0) +
    //   (allowOneTimePayment
    //     ? parseInt(simulationProject?.oneTimePayYear1 || 0)
    //     : 0),
  );
  const secondYearSum = Math.ceil(
    (yearData.secondYear?.basePay || 0) +
      (yearData.secondYear?.variablePay || 0) +
      (yearData.secondYear?.benefitsPay || 0),
    //   +
    //   (allowLtiGrants ? grantsArray[1] || 0 : 0) +
    //   (allowOneTimePayment
    //     ? parseInt(simulationProject?.oneTimePayYear2 || 0)
    //     : 0),
  );

  const thirdYearSum = Math.ceil(
    (yearData.thirdYear?.basePay || 0) +
      (yearData.thirdYear?.variablePay || 0) +
      (yearData.thirdYear?.benefitsPay || 0),
    //   +
    //   (allowLtiGrants ? grantsArray[2] || 0 : 0),
  );

  const fourthYearSum = Math.ceil(
    (yearData.fourthYear?.basePay || 0) +
      (yearData.fourthYear?.variablePay || 0) +
      (yearData.fourthYear?.benefitsPay || 0),
    //   +
    //   (allowLtiGrants ? grantsArray[3] || 0 : 0),
  );
  const fetchCandidateOfferDetails = async (body) => {
    try {
      const data = await getCandidateOfferDetails(body);
      return data;
    } catch (err) {
      console.log(err);
      return [];
    }
  };
  useEffect(() => {
    const payload = {
      value: value,
    };
    fetchCandidateOfferDetails(payload)
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  }, []);

  return (
    <FormProvider {...methods}>
      {' '}
      <div className="flex flex-col w-full">
        <div className="w-full h-[250px] z-30">
          <img
            src={imageURLCheck(
              'https://t4.ftcdn.net/jpg/04/95/28/65/360_F_495286577_rpsT2Shmr6g81hOhGXALhxWOfx1vOQBa.jpg',
            )}
            className="w-full h-full object-cover"
            alt="coverImage"
            // onError={handleErrorImage}
          />
        </div>
        <div className="flex flex-col w-full px-4 py-6 gap-4 bg-white border-b">
          <div className="flex items-center justify-between w-full  bg-white">
            <div className="flex flex-col gap-1">
              <p className="text-lg font-semibold text-gray-600">Pay-mix</p>
              <p className="text-sm text-gray-600">
                Your Total Compensation is{' '}
                <span className="font-semibold text-black">
                  INR 26,00,000/yr
                </span>
              </p>
            </div>
            <div className="flex flex-col gap-1 justify-end items-end">
              <p className="flex items-center text-base text-gray-700">
                <Toggle
                  checked={realizedWealth}
                  onChange={(e) => {
                    // setShowWithRealizedWealth(!showWithRealizedWealth);
                    setRealizedWealth(e?.target?.checked);
                  }}
                />
                <span className="pr-1">With realized wealth</span>{' '}
                <QuestionMarkCircleIcon className="w-4 h-4" />
              </p>
              <p className="text-sm text-gray-600">
                effective date 21 Jan, 2023
              </p>
            </div>
          </div>
          <div className="flex w-full h-10 bg-white">
            {/* {tableDataWithEsops?.map((item, index) => {
          const calculatedWidth = `${(
            (item?.amountYear / totalAmount) *
            100
          )?.toFixed(PERCENT_DECIMAL)}%`;
          const isFirst = index === 0;
          const isLast = index === tableDataWithEsops.length - 1;

          return ( */}
            <>
              <Tooltip id="tooltip-value-percentage" place="top" type="light" />
              <div
                className={
                  `h-full border flex items-center justify-start grow px-2 text-white font-semibold text-xs cursor-pointer`
                  //   +
                  //   (isFirst ? ' rounded-l-lg' : '') +
                  //   (isLast ? ' rounded-r-lg' : '')
                }
                data-tooltip-id="tooltip-value-percentage"
                // data-tooltip-content={`${item?.name} ${parseFloat(
                //   calculatedWidth,
                // )?.toFixed(PERCENT_DECIMAL)} %`}
                // key={item?.name}
                style={{
                  width: 20,
                  //   width: calculatedWidth,
                  //   backgroundColor: `${item?.colorCode}`,
                }}
              >
                <span className="bg-gray-600 bg-opacity-40 py-1 px-2 rounded-3xl overflow-hidden truncate ">
                  {/* {parseFloat(calculatedWidth)?.toFixed(PERCENT_DECIMAL)} % */}
                  20 %
                </span>
              </div>
            </>
            {/* );
        })} */}
          </div>
        </div>
        <div className="flex w-full">
          <div className="p-4 w-4/12">
            {' '}
            <div className="bg-white p-4 flex flex-col gap-2 border shadow-md rounded-lg w-full">
              <p className="text-xl font-semibold">Compensation Details</p>
              <div className=" p-4 flex items-center justify-between bg-gray-50 border rounded-lg w-full">
                <div className=" flex items-center justify-center w-1/2">
                  <p className="font-semibold text-start">Pay Component</p>
                </div>
                <div className=" flex items-center justify-center w-1/2">
                  <p className="font-semibold text-start">Amount/Year</p>
                </div>
              </div>
              <div className=" p-4 flex flex-col bg-white border rounded-lg gap-2 w-full">
                <div className="flex items-center justify-between w-full">
                  <div className=" flex items-center justify-center w-1/2">
                    <p className="font-semibold text-start">Base Salary</p>
                  </div>
                  <div className=" flex items-center justify-center w-1/2">
                    <p className="font-semibold text-start">INR 24,00,000</p>
                  </div>
                </div>
                <div className="p-4 border rounded-lg flex flex-col gap-2">
                  <div className="flex items-center justify-between w-full">
                    <div className=" flex items-center justify-center w-1/2">
                      <p className=" text-start">Component</p>
                    </div>
                    <div className=" flex items-center justify-center w-1/2">
                      <p className=" text-start">INR 6,00,000</p>
                    </div>
                  </div>
                </div>
                <div className="p-4 border rounded-lg flex flex-col gap-2">
                  <div className="flex items-center justify-between w-full">
                    <div className=" flex items-center justify-center w-1/2">
                      <p className=" text-start">Component</p>
                    </div>
                    <div className=" flex items-center justify-center w-1/2">
                      <p className=" text-start">INR 6,00,000</p>
                    </div>
                  </div>
                </div>
                <div className="p-4 border rounded-lg flex flex-col gap-2">
                  <div className="flex items-center justify-between w-full">
                    <div className=" flex items-center justify-center w-1/2">
                      <p className=" text-start">Component</p>
                    </div>
                    <div className=" flex items-center justify-center w-1/2">
                      <p className=" text-start">INR 6,00,000</p>
                    </div>
                  </div>
                </div>
                <div className="p-4 border rounded-lg flex flex-col gap-2">
                  <div className="flex items-center justify-between w-full">
                    <div className=" flex items-center justify-center w-1/2">
                      <p className=" text-start">Component</p>
                    </div>
                    <div className=" flex items-center justify-center w-1/2">
                      <p className=" text-start">INR 6,00,000</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white w-8/12 p-4 flex flex-col gap-4">
            <div className="flex items-center justify-between border rounded-lg shadow-md p-4">
              <p className="font-semibold text-lg">Your Response</p>
              <div className="flex gap-2">
                <div className="flex items-center gap-2 p-2 rounded-lg border border-gray-400 text-gray-600">
                  <EyeIcon className="w-4 h-4" /> View Offer Letter
                </div>{' '}
                <div className="flex items-center gap-2 p-2 rounded-lg border border-green text-green">
                  <CheckIcon className="w-5 h-5 text-green" /> Accept
                </div>{' '}
                <div className="flex items-center gap-2 p-2 rounded-lg border border-red-600 text-red-600">
                  <XMarkIcon className="w-5 h-5 text-red-600" /> Reject
                </div>
              </div>
            </div>
            <p className="font-semibold text-xl">Simulate Growth</p>
            <div className="bg-gray-100 rounded-lg p-4 flex flex-col gap-2">
              <div className="flex flex-col">
                <p className="font-semibold text-lg">Description</p>
                <p className="text-gray-700">
                  Simulate your growth with the company and project your income
                  on the basis of your assumption.
                </p>
              </div>{' '}
              <div className="flex flex-col">
                <p className="font-semibold text-lg">Desclaimer</p>
                <p className="text-gray-700">
                  The simulation is purely based on your assumption. It will
                  have no impact on the actual value and growth.
                </p>
              </div>
            </div>
            <div className="flex gap-4 w-full">
              <div className="w-2/6 p-4 border rounded-lg bg-gray-100">
                {' '}
                <ProjectData
                  projectTitle="Company Name"
                  project={'reviewOffer'}
                  // ltiGrant={ltiGrant}
                />
              </div>
              <div className="w-4/6 rounded-lg shadow-md bg-white p-4 flex flex-col gap-4">
                {' '}
                <div className="flex items-center justify-between gap-4">
                  {/* <p className="font-medium text-gray-600 text-sm">The Values are shown in Lac(s)</p> */}
                  <p className="font-medium text-gray-600 text-sm"></p>
                  <div className="flex items-center gap-2">
                    <span className="text-sm font-medium text-gray-700">
                      {' '}
                      Lacs{' '}
                    </span>
                    <Toggle
                      checked={showAmountInMillions}
                      onChange={() => {
                        setShowAmountInMillions(!showAmountInMillions);
                      }}
                    />{' '}
                    <span className="text-sm font-medium text-gray-700">
                      {' '}
                      Millions{' '}
                    </span>
                  </div>
                </div>
                {/* <Chart
                  type="bar"
                  data={
                    selectedSideTab === 'Comparision'
                      ? dataForComparisionChart
                      : dataForChart
                  }
                  chartOptions={chartOptions}
                /> */}
                <div className="flex flex-col gap-2 w-full">
                  <div className="flex gap-2">
                    <div className="w-2/12 p-2 rounded-lg bg-gray-100 border border-gray-300 flex items-center justify-center text-gray-900 text-sm font-semibold">
                      Comparision
                    </div>
                    <div className="w-8/12 p-2 rounded-lg bg-gray-100 border border-gray-300 grid grid-cols-4 gap-2">
                      {/* {chartLabels.map((year) => (
                        <div
                          className=" flex items-center justify-center text-sm text-gray-900 font-semibold"
                          key={year}
                        >
                          {year}
                        </div>
                      ))} */}
                    </div>
                    <div className="w-2/12 p-2 rounded-lg bg-gray-100 border border-gray-300 flex items-center justify-center text-sm text-gray-900 font-semibold">
                      Company
                    </div>
                  </div>
                  <>
                    <TableRowSection
                      title="Base"
                      data={[
                        yearData.firstYear?.basePay || 0,
                        yearData.secondYear?.basePay || 0,
                        yearData.thirdYear?.basePay || 0,
                        yearData.fourthYear?.basePay || 0,
                      ]}
                      selectedSideTab={'Simulation'}
                      simulationTitle="Current"
                      ComparisionTitle="Potential"
                    />
                    <TableRowSection
                      title="Variable"
                      data={[
                        yearData.firstYear?.variablePay || 0,
                        yearData.secondYear?.variablePay || 0,
                        yearData.thirdYear?.variablePay || 0,
                        yearData.fourthYear?.variablePay || 0,
                      ]}
                      selectedSideTab={'Simulation'}
                      simulationTitle="Current"
                      ComparisionTitle="Potential"
                    />
                    {/* {allowLtiGrants && (
                      <TableRowSection
                        title="ESOPs"
                        data={grantsArray}
                        selectedSideTab={'Simulation'}
                        simulationTitle="Current"
                        ComparisionTitle="Potential"
                      />
                    )} */}
                    <TableRowSection
                      title="Benefits"
                      data={[
                        yearData.firstYear?.benefitsPay || 0,
                        yearData.secondYear?.benefitsPay || 0,
                        yearData.thirdYear?.benefitsPay || 0,
                        yearData.fourthYear?.benefitsPay || 0,
                      ]}
                      selectedSideTab={'Simulation'}
                      simulationTitle="Current"
                      ComparisionTitle="Potential"
                    />
                    {/* {allowOneTimePayment && (
                      <TableRowSection
                        title="Onetime"
                        data={[
                          parseInt(simulationProject?.oneTimePayYear1 || 0),
                          parseInt(simulationProject?.oneTimePayYear2 || 0),
                          0,
                          0,
                        ]}
                        selectedSideTab={'Simulation'}
                        simulationTitle="Current"
                        ComparisionTitle="Potential"
                      />
                    )} */}
                    <TableRowSection
                      title="Total"
                      data={[
                        firstYearSum || 0,
                        secondYearSum || 0,
                        thirdYearSum || 0,
                        fourthYearSum || 0,
                      ]}
                      selectedSideTab={'Simulation'}
                      simulationTitle="Current"
                      ComparisionTitle="Potential"
                    />
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default ReviewOffer;
