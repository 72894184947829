import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import ColorPicker from 'components/atoms/FormElements/colorPicker/ColorPicker';
import { Input } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import TextBox from 'components/atoms/FormElements/textBox/TextBox';
import Button from 'components/atoms/button/Button';
import { useState } from 'react';

export default function DashboardSettings({
  dashboardSettings,
  setDashboardSettings,
}) {
  const [colorValue, setColorValue] = useState('#eaeaea');
  const pixelOptions = [
    { value: '8', label: '8px' },
    { value: '10', label: '10px' },
    { value: '16', label: '16px' },
    { value: '24', label: '24px' },
    { value: '32', label: '32px' },
    { value: '40', label: '40px' },
    { value: '48', label: '48px' },
  ];
  const saveLayoutToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };
  const handleChange = (data, value) => {
    var newData = {
      ...dashboardSettings?.settings,
      [data]: value,
    };
    setDashboardSettings((prevSettings) => ({
      ...prevSettings,
      settings: newData,
    }));

    saveLayoutToLocalStorage('dashboardSettings', {
      ...dashboardSettings,
      settings: newData,
    });
  };

  return (
    <div className="relative h-full flex flex-col">
      <div className="flex flex-col h-full justify-between">
        <div className=" h-4/5 overflow-y-auto ">
          <div className="flex h-full flex-col gap-6 justify-start ">
            <div className="w-full p-4 bg-gray-50 border rounded-lg border-gray-200">
              <Disclosure>
                <Disclosure.Button className="group flex w-full items-center justify-between">
                  <span className="text-gray-900 text-base font-semibold">
                    Sheet Properties
                  </span>
                  <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
                </Disclosure.Button>
                <Disclosure.Panel className="flex flex-col gap-2 mt-3">
                  <hr className="bg-gray-200"></hr>
                  <div className="flex flex-col gap-4">
                    <Input
                      label="Title"
                      value={dashboardSettings?.settings?.title}
                      onChange={(e) => handleChange('title', e?.target?.value)}
                    />
                    <div className="flex flex-col">
                      <TextBox
                        label="Description"
                        rows={4}
                        className="border-gray-300 "
                        value={dashboardSettings?.settings?.description}
                        onChange={(e) => {
                          handleChange('description', e?.target?.value);
                        }}
                      />
                    </div>
                  </div>
                </Disclosure.Panel>
              </Disclosure>
            </div>
            <div className="w-full p-4 bg-gray-50 border rounded-lg border-gray-200">
              <Disclosure>
                <Disclosure.Button className="group flex w-full items-center justify-between">
                  <span className="text-gray-900 text-base font-semibold">
                    Styling
                  </span>
                  <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
                </Disclosure.Button>
                <Disclosure.Panel className="flex flex-col gap-2 mt-3">
                  <hr className="bg-gray-200"></hr>
                  <div>
                    <Select
                      label="Grid Spacing"
                      value={{
                        label: dashboardSettings?.settings?.spacing + 'px',
                        value: dashboardSettings?.settings?.spacing,
                      }}
                      onChange={(e) => {
                        handleChange('spacing', Number(e?.value));
                      }}
                      options={pixelOptions}
                    />
                  </div>
                </Disclosure.Panel>
              </Disclosure>
            </div>
            <div className="w-full p-4 bg-gray-50 border rounded-lg border-gray-200">
              <Disclosure>
                <Disclosure.Button className="group flex w-full items-center justify-between">
                  <span className="text-gray-900 text-base font-semibold">
                    Background
                  </span>
                  <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
                </Disclosure.Button>
                <Disclosure.Panel className="flex flex-col gap-2 mt-3">
                  <hr className="bg-gray-200"></hr>
                  <div>
                    <ColorPicker
                      label="Background Color"
                      value={
                        dashboardSettings?.settings?.backgroundColor ||
                        '#eaeaea'
                      }
                      onChange={(e) => {
                        handleChange('backgroundColor', e?.target?.value);
                      }}
                    />
                  </div>
                </Disclosure.Panel>
              </Disclosure>
            </div>
          </div>
        </div>
        <div className=" h-20 absolute w-full bottom-0 flex flex-col pt-3 gap-4  bg-white ">
          <hr className="bg-gray-200" />
          <div className="text-right">
            <Button>Save</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
