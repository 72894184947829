import AlignIcon from 'assets/svg/AlignIcon';
import ColorPicker from 'components/atoms/FormElements/colorPicker/ColorPicker';
import PropertySelect from 'components/atoms/linkSelect/PropertySelect';
import PropertyInput from 'components/atoms/propertyInput/PropertyInput';
import {
  fontSize,
  headingAlignments,
  links,
} from 'config/templateBuilderConfig';
import { Controller, useFormContext } from 'react-hook-form';

const HeadingPropertiesSidePanel = (props) => {
  const { setValue, watch } = useFormContext();
  const { control, path } = props;
  return (
    <div className="flex flex-col">
      <Controller
        control={control}
        name={`${path}.heading.value`}
        render={({ field }) => {
          return <PropertyInput label={'Text'} className={'mb-2'} {...field} />;
        }}
      />

      <div className="my-2">
        <Controller
          control={control}
          name={`${path}.heading.metaData.link`}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <PropertySelect
                options={links}
                creatable={true}
                label={'Link'}
                onChange={(e) => onChange(e?.value)}
              />
            );
          }}
        />
      </div>
      <div className="my-2">
        <Controller
          control={control}
          name={`${path}.heading.metaData.fontSize`}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <PropertySelect
                options={fontSize}
                label={'Font Size'}
                onChange={(e) => onChange(e?.value)}
              />
            );
          }}
        />
      </div>
      <div className="mt-2 flex justify-between">
        <p className="text-sm font-medium leading-5 text-gray-700 my-1 mb-2.5">
          Alignment
        </p>
        <div className="flex gap-3">
          {headingAlignments.map((align, index) => (
            <div
              key={index}
              onClick={() =>
                setValue(`${path}.heading.metaData.alignment`, align)
              }
              className="cursor-pointer"
            >
              <AlignIcon name={align} />
            </div>
          ))}
        </div>
      </div>
      <div className="my-2">
        <Controller
          control={control}
          name={`${path}.heading.metaData.color`}
          render={({ field }) => {
            const { value, onChange, ...rest } = field;
            return (
              <ColorPicker
                htmlFor={'headingColor'}
                value={value}
                label={'Font Color'}
                onChange={(e) => onChange(e.target.value)}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default HeadingPropertiesSidePanel;
