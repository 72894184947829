import PopUp from 'components/atoms/popUp/PopUp';
import React from 'react';

const FiltersPopUp = ({
  options,
  handleSelectedFilter,
  column,
  setFiltersPopUp,
}) => {
  return (
    <PopUp right="5" closePopup={() => {}}>
      <div className="w-full min-w-32 p-2 space-y-2 max-h-60 overflow-y-scroll">
        <div className="w-full px-4 h-full">
          {options?.map((option) => {
            return (
              <p
                className="cursor-pointer flex items-center text-sm font-medium text-gray-700 gap-2 py-1"
                onClick={() => {
                  handleSelectedFilter(option, column?.value ?? column);
                  setFiltersPopUp(null);
                }}
                key={option?.value}
              >
                {option?.value}
              </p>
            );
          })}
        </div>
      </div>
    </PopUp>
  );
};

export default FiltersPopUp;
