import React, { useEffect } from 'react';
import '../../reports/reportFilterDialogue/FilterDialogue.styles.scss';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  templateBuilderSelector,
  toggleShowSidepanel,
} from 'store/reducers/templateBuilderSlice';
import AtomsMoleculesSidePanel from './sidePanelContent/atomsMoleculesSidePanel/AtomsMoleculesSidePanel';
import CloseIcon from 'assets/svg/close';
import EditAtomsMoleculesSidePanel from './sidePanelContent/editAtomsMoleculesSidePanel/EditAtomsMoleculesSidePanel';

function BuilderSidePanel() {
  const dispatch = useDispatch();
  const { showSidepanel, sidePanelType, sidePanelTitle } = useSelector(
    templateBuilderSelector,
  );

  const onCancel = () => {
    dispatch(toggleShowSidepanel(false));
  };

  useEffect(() => {
    if (showSidepanel) {
      document.body.classList.add('overflow-y-hidden');
    } else {
      document.body.classList.remove('overflow-y-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-y-hidden');
    };
  }, [showSidepanel]);

  const sidePanel = (type) => {
    switch (type) {
      case 'AtomsMolecules':
        return <AtomsMoleculesSidePanel />;
      case 'IHP':
        return (
          <div className="flex flex-col gap-4">
            <EditAtomsMoleculesSidePanel type={'Image'} />
            <EditAtomsMoleculesSidePanel type={'Heading'} />
            <EditAtomsMoleculesSidePanel type={'Paragraph'} />
          </div>
        );
      case 'HPB':
        return (
          <div className="flex flex-col gap-4">
            <EditAtomsMoleculesSidePanel type={'Heading'} />
            <EditAtomsMoleculesSidePanel type={'Paragraph'} />
            <EditAtomsMoleculesSidePanel type={'Button'} />
          </div>
        );
      case 'HP':
        return (
          <div className="flex flex-col gap-4">
            <EditAtomsMoleculesSidePanel type={'Heading'} />
            <EditAtomsMoleculesSidePanel type={'Paragraph'} />
          </div>
        );
      case 'VHP':
        return (
          <div className="flex flex-col gap-4">
            <EditAtomsMoleculesSidePanel type={'Video'} />
            <EditAtomsMoleculesSidePanel type={'Heading'} />
            <EditAtomsMoleculesSidePanel type={'Paragraph'} />
          </div>
        );
      default:
        return <EditAtomsMoleculesSidePanel type={type} />;
    }
  };

  return (
    <React.Fragment>
      {showSidepanel && (
        <div
          className={`w-screen h-screen fixed top-0 left-0 z-[999] flex justify-end `}
        >
          <div
            className={`w-[392px] bg-white opacity-100 modalAnimate h-full relative border-l-2 shadow-lg`}
            data-state={showSidepanel}
          >
            <div className="px-6 shadow-sm">
              <div className="py-5 flex justify-start items-center gap-3">
                <div
                  className="border border-gray-300 p-2.5 w-9 h-9 flex items-center justify-center rounded-lg shadow-sm cursor-pointer"
                  onClick={() => onCancel()}
                >
                  <CloseIcon />
                </div>
                <p className="font-medium font-figtree text-gray-600">
                  {sidePanelTitle}
                </p>
              </div>
            </div>
            <div className="p-4">{sidePanel(sidePanelType)}</div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default BuilderSidePanel;
