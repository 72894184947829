import parse from 'html-react-parser';
import xss from 'xss';

export const debounce = (func, time) => {
  let timer;
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func(args);
    }, time);
  };
};

export const throttleFunction = (func, delay) => {
  let prev = 0;
  return (...args) => {
    const now = new Date().getTime();
    if (now - prev > delay) {
      prev = now;
      return func(...args);
    }
  };
};

export function isJSON(str) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

export const convertJsonToCsv = (jsonData) => {
  const headers = Object.keys(jsonData[0]);
  const csv = jsonData.map((row) =>
    headers.map((header) => JSON.stringify(row[header])).join(','),
  );
  return [headers.join(','), ...csv].join('\n');
};

export const downloadCsv = (csvData, fileName) => {
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileName}.csv`;
  link.click();
};
const htmlTags = [
  'a',
  'abbr',
  'address',
  'area',
  'article',
  'aside',
  'audio',
  'b',
  'base',
  'bdi',
  'bdo',
  'blockquote',
  'body',
  'br',
  'button',
  'canvas',
  'caption',
  'cite',
  'code',
  'col',
  'colgroup',
  'data',
  'datalist',
  'dd',
  'del',
  'details',
  'dfn',
  'dialog',
  'div',
  'dl',
  'dt',
  'em',
  'embed',
  'fieldset',
  'figcaption',
  'figure',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'head',
  'header',
  'hr',
  'html',
  'i',
  'iframe',
  'img',
  'input',
  'ins',
  'kbd',
  'label',
  'legend',
  'li',
  'link',
  'main',
  'map',
  'mark',
  'meta',
  'meter',
  'nav',
  'noscript',
  'object',
  'ol',
  'optgroup',
  'option',
  'output',
  'p',
  'param',
  'picture',
  'pre',
  'progress',
  'q',
  'rp',
  'rt',
  'ruby',
  's',
  'samp',
  'script',
  'section',
  'select',
  'small',
  'source',
  'span',
  'strong',
  'style',
  'sub',
  'summary',
  'sup',
  'table',
  'tbody',
  'td',
  'template',
  'textarea',
  'tfoot',
  'th',
  'thead',
  'time',
  'title',
  'tr',
  'track',
  'u',
  'ul',
  'var',
  'video',
  'wbr',
];
// const options = {
//   whiteList: {
//     span: ['id', 'style'],
//     table: ['id', 'style'],
//     // div: ['id', 'style'],
//     // p: ['id', 'style'],
//   },
// };
const generateWhiteList = (tags, attributes) => {
  const whiteList = {};
  tags.forEach((tag) => {
    whiteList[tag] = attributes;
  });
  return whiteList;
};

const options = {
  whiteList: generateWhiteList(htmlTags, ['id', 'style']),
};

export const htmlParser = (string) => {
  const scriptRemovedString = xss(string, options);

  return parse(scriptRemovedString, {
    ...options,
  });
};

export const htmlWithXss = (string) => {
  return xss(string, options);
};

export const truncateText = (html, maxLength) => {
  if (!html || typeof html !== 'string') return '';

  // Parse HTML content
  const doc = new DOMParser().parseFromString(html, 'text/html');

  // Extract text content
  let textContent = doc.body.textContent || '';

  // Truncate if needed
  if (textContent.length > maxLength) {
    textContent = `${textContent.slice(0, maxLength)}...`;
  }

  return textContent;
};

export const checkValidImage = (
  images,
  allowedImages = ['jpeg', 'png', 'jpg', 'gif'],
) => {
  const allowedFileTypes = allowedImages.map((fileType) =>
    fileType.toLowerCase(),
  );
  if (Array.isArray(images)) {
    for (let i = 0; i < images.length; i++) {
      const file = images[i];
      const fileType = file.name.split('.').pop().toLowerCase();

      if (!allowedFileTypes.includes(fileType)) {
        return false;
      }
    }
  } else {
    const fileType = images.name.split('.').pop().toLowerCase();
    if (!allowedFileTypes.includes(fileType)) {
      return false;
    }
  }

  return true;
};

export const checkValidVideos = (videos, allowedVideos = ['mp4']) => {
  const allowedFileTypes = allowedVideos.map((fileType) =>
    fileType.toLowerCase(),
  );
  if (Array.isArray(videos)) {
    for (let i = 0; i < videos.length; i++) {
      const file = videos[i];
      const fileType = file.name.split('.').pop().toLowerCase();

      if (!allowedFileTypes.includes(fileType)) {
        return false;
      }
    }
  } else {
    const fileType = videos.name.split('.').pop().toLowerCase();
    if (!allowedFileTypes.includes(fileType)) {
      return false;
    }
  }

  return true;
};

export const checkValidCSV = (files) => {
  if (Array.isArray(files)) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = file.name.split('.').pop().toLowerCase();

      if (fileType !== 'csv') {
        return false;
      }
    }
  } else {
    const fileType = files.name.split('.').pop().toLowerCase();
    if (fileType !== 'csv') {
      return false;
    }
  }

  return true;
};
export const hslToHex = (h, s, l) => {
  s /= 100;
  l /= 100;
  let c = (1 - Math.abs(2 * l - 1)) * s;
  let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  let m = l - c / 2;
  let r = 0;
  let g = 0;
  let b = 0;
  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  const toHex = (component) => {
    const hex = component.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};
export const hexToHsl = (hex) => {
  // Convert hex to RGB
  let r = 0;
  let g = 0;
  let b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  // Convert RGB to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s = 0;
  let l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);
  return { h: h, s: s, l: l };
  // return `hsl(${h}, ${s}%, ${l}%)`;
};
export const getGradientMidColor = (color1, color2, percent) => {
  const hexToRgb = (hex) => {
    let bigint = parseInt(hex.slice(1), 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    return [r, g, b];
  };

  const rgbToHex = (r, g, b) => {
    const componentToHex = (c) => {
      let hex = c.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
  };

  const [r1, g1, b1] = hexToRgb(color1);
  const [r2, g2, b2] = hexToRgb(color2);

  const midR = Math.round(r1 + percent * (r2 - r1));
  const midG = Math.round(g1 + percent * (g2 - g1));
  const midB = Math.round(b1 + percent * (b2 - b1));

  return rgbToHex(midR, midG, midB);
};
