import {
  fetchAllLandingPage,
  fetchLandingPage,
  fetchMedia,
  landingPageApi,
  landingPageConfiguration,
  updateLandingPage,
  uploadMedia,
} from 'apiClient/templateBuilder/templateBuilderApi';
import Button from 'components/atoms/button/Button';
import { Loader } from 'components/atoms/loader/Loader';
import ImageInput from 'components/molecules/imageInput/ImageInput';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  setLandingPageData,
  setModeLabel,
  templateBuilderSelector,
} from 'store/reducers/templateBuilderSlice';
import { imageURLCheck } from 'utils/ImageUrlCheck';
import { handleImageChange } from 'utils/hanldeImageSize';
import { onResponse } from 'utils/toastMessages';
import { v4 } from 'uuid';
import BuilderMainPanel from './builderMainPanel/BuilderMainPanel';
import BuilderSidePanel from './buillderSidePanel/BuilderSidePanel';
import ViewTemplate from './viewTemplate/ViewTemplate';

function TemplateBuilder() {
  const methods = useForm({
    criteriaMode: 'all',
    defaultValues: {
      name: '',
      banner: '',
      moduleName: '',
      data: [],
      media: {},
      rowsToDelete: [],
      mediasToDelete: [],
    },
  });

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;
  const dispatch = useDispatch();
  const { landingPageData, modeLabel } = useSelector(templateBuilderSelector);
  const [isProgressing, setIsProgressing] = useState(false);

  let media = watch(`media`);
  let myMap = new Map(Object.entries(media));

  const uploadMediaSetMediaId = async (data) => {
    try {
      if (data?.data?.length !== 0) {
        var condition = true;
        data?.data?.forEach((section, index) => {
          if (section?.columns?.length === 0) {
            condition = false;
            return onResponse(`data in section at index ${index} is empty`);
          }
        });
        if (condition) {
          setIsProgressing(true);
          for (let [key, value] of myMap) {
            let mediaFile = myMap.get(key);
            let landingPageData = {
              name: 'landing page media' + mediaFile?.name.toString(),
              media: {
                type: mediaFile?.type?.startsWith('image/') ? 'image' : 'video',
                fileSettings: {
                  caption: 'landing page media' + mediaFile?.name + 'caption',
                },
              },
            };

            const reqData = new FormData();
            reqData?.append('landingPageData', JSON.stringify(landingPageData));
            reqData?.append('media', myMap.get(key));

            const responseData = await uploadMedia(reqData);
            myMap.set(key, responseData.id);
          }
          modeLabel === 'Edit'
            ? updateLandingPageData(data)
            : createLandingPage(data);
        }
      } else {
        setIsProgressing(false);
        return onResponse('Form is not valid');
      }
    } catch (error) {
      setIsProgressing(false);
      onResponse(error.message);
    }
  };

  const updateLandingPageData = async (pageStructure) => {
    try {
      const moduleData = watch(`moduleName`);
      const bannerData = watch(`banner`);

      let data = {
        title: watch('name'),
        sideNavId: moduleData['value'],
      };
      const reqData = new FormData();
      if (bannerData?.type?.startsWith('image/')) {
        reqData?.append('banner', watch(`banner`));
        data['banner'] = {
          type: 'image',
          fileSettings: {
            caption: `Banner image of module that has id ${moduleData[0].id}`,
          },
        };
      }
      reqData?.append('landingPageData', JSON.stringify(data));

      const landingPage = await fetchAllLandingPage(0, 1, watch(`name`));
      const landingPageResponse = await updateLandingPage(
        landingPage[0].id,
        reqData,
      );

      publish(pageStructure, landingPage[0].id);
    } catch (error) {
      onResponse(error.message);
      setIsProgressing(false);
    }
  };

  const publish = async (data, landingPageId) => {
    let pageStructure = data.data;
    pageStructure.forEach((section, sectionIndex) => {
      let columns = section.columns;
      pageStructure[sectionIndex].order = sectionIndex + 1;
      pageStructure[sectionIndex].id = pageStructure[sectionIndex]?.id ?? '';
      delete pageStructure[sectionIndex].sectionId;
      columns = columns
        .slice()
        .sort((a, b) => a?.column?.order - b?.column?.order);
      columns.forEach((column, index) => {
        if (column.column) {
          pageStructure[sectionIndex].columns[index].column.order = index + 1;
          pageStructure[sectionIndex].columns[index].column.id =
            pageStructure[sectionIndex]?.columns[index]?.column?.id ?? '';
          pageStructure[sectionIndex].columns[index].column.componentMappingId =
            pageStructure[sectionIndex]?.columns[index]?.column
              ?.componentMappingId ?? '';
          pageStructure[sectionIndex].columns[index].column.attr.type =
            pageStructure[sectionIndex].columns[index].type;
          if (column?.column?.attr?.image || column?.column?.attr?.video) {
            pageStructure[sectionIndex].columns[index].column.attr.mediaId =
              pageStructure[sectionIndex]?.columns[index]?.column?.attr
                ?.mediaId ?? myMap.get(column.columnId);
            delete pageStructure[sectionIndex].columns[index].column?.attr
              ?.image?.src;
            delete pageStructure[sectionIndex].columns[index].column?.attr
              ?.video?.src;
          }
          delete pageStructure[sectionIndex].columns[index].columnId;
          delete pageStructure[sectionIndex].columns[index].type;
        } else {
          pageStructure[sectionIndex].columns.splice(
            index,
            columns.length - index,
          );
        }
      });
    });

    try {
      const sortedPageStructure = pageStructure
        .slice()
        .sort((a, b) => a.order - b.order);
      let landingPageDatatoUpload = {
        rowsToDelete:
          modeLabel === 'Edit' ? [...new Set(watch(`rowsToDelete`))] : [],
        mediasToDelete:
          modeLabel === 'Edit' ? [...new Set(watch(`mediasToDelete`))] : [],
        landingPageId: landingPageId,
        data: sortedPageStructure,
      };
      const reponseData = await landingPageConfiguration(
        landingPageDatatoUpload,
      );
      if (reponseData.statusCode === 201) {
        onResponse(
          `Your landing page ${
            modeLabel === 'Edit' ? 'updated' : 'published'
          }  successfully`,
        );
      }
      dispatch(setModeLabel('Render'));
      setIsProgressing(false);
    } catch (error) {
      onResponse(error.message);
      setIsProgressing(false);
    }
  };

  const createLandingPage = async (pageStructure) => {
    try {
      const module = watch(`moduleName`);
      let data = {
        title: watch('name'),
        sideNavId: module['value'],
        banner: {
          type: 'image',
          fileSettings: {
            caption: `Banner image of module that has id ${module['value']}`,
          },
        },
      };
      const reqData = new FormData();
      reqData?.append('landingPageData', JSON.stringify(data));
      reqData?.append('banner', watch('banner'));
      const landingPageResponse = await landingPageApi(reqData);
      publish(pageStructure, landingPageResponse.id);
    } catch (error) {
      setIsProgressing(false);
      onResponse(error.message);
    }
  };

  const fetchLandingPageData = async () => {
    try {
      const landingPageResponse = await fetchAllLandingPage(
        0,
        1,
        watch(`name`),
      );
      const response = await fetchLandingPage(landingPageResponse[0].id);
      if (response) {
        dispatch(setLandingPageData(response));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getImageUrl = async (path) => {
    try {
      const response = await fetchMedia(watch(`${path}.mediaId`));
      return response.imageUrl;
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchLandingPageData();
    resetData();
  }, [modeLabel]);

  const resetData = () => {
    let sortedData = null;
    if (landingPageData) {
      sortedData = landingPageData.data
        .slice()
        .sort((a, b) => a.order - b.order);
    }
    if (modeLabel === 'Edit') {
      setValue(`name`, landingPageData?.title);
      setValue(`banner`, imageURLCheck(landingPageData?.banner?.imageUrl));
      setValue(`moduleName`, {
        label: landingPageData?.sideNav?.name,
        value: landingPageData?.sideNav?.id,
      });
      sortedData.forEach((item, index, array) => {
        const sortedColumns = item.columns
          .slice()
          .sort((a, b) => a.column.order - b.column.order);
        array[index] = { ...item, columns: sortedColumns };
      });
      setValue(`data`, sortedData);
      for (let i = 0; i < sortedData.length; i++) {
        setValue(`data[${i}].sectionId`, v4());

        for (let j = 0; j < sortedData[i].columns.length; j++) {
          setValue(
            `data[${i}].columns[${j}].type`,
            sortedData[i].columns[j].column.attr.type,
          );

          setValue(`data[${i}].columns[${j}].columnId`, v4());
          const path = sortedData[i].columns[j].column.attr;
          const setPath = `data[${i}].columns[${j}].column.attr`;

          if (path?.image || path?.video) {
            getImageUrl(setPath).then((value) => {
              if (path?.image) {
                setValue(`${setPath}.image.src`, imageURLCheck(value));
              } else {
                setValue(`${setPath}.video.src`, imageURLCheck(value));
              }
            });
          }
        }
      }
    }
  };

  const validationRules = {
    banner: {
      validate: (file) => {
        if (!file) {
          return 'Banner image is required';
        }

        return true;
      },
    },
  };

  return isProgressing ? (
    <div className="h-screen w-full flex justify-center items-center">
      <Loader />
    </div>
  ) : landingPageData && modeLabel === 'Render' ? (
    <ViewTemplate landingPageData={landingPageData} />
  ) : modeLabel === 'Edit' || modeLabel === 'Create' ? (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(uploadMediaSetMediaId)}>
        <div className="relative max-h-full max-w-full overflow-y-scroll">
          <div className="bg-white h-full w-full">
            <div className="h-80 flex relative">
              <div className="absolute z-50 flex gap-3 top-4 right-5">
                <Button
                  variant={'outlined'}
                  onClick={() => {
                    modeLabel === 'Edit' && dispatch(setModeLabel('Render'));
                  }}
                >
                  Cancel
                </Button>
                <Button variant={'outlined'}>Unpublish</Button>
                <Button variant={'filled'} type="submit">
                  {modeLabel === 'Edit' ? 'Update' : ' Publish'}
                </Button>
              </div>
              <Controller
                name="banner"
                control={control}
                rules={validationRules.banner}
                render={({ field }) => {
                  const { onChange, ...rest } = field;
                  return (
                    <div className="relative w-full">
                      <ImageInput
                        height="80"
                        objectFit="cover"
                        htmlFor="banner"
                        description={
                          'JPEG, Jpg, AVIF, SVG, WebP, PNG only ( Max size up to 25MB )'
                        }
                        accept=".png,.jpg,.jpeg,.svg,.gif,.avif,.webp"
                        onChange={(e) => {
                          if (handleImageChange(e.target.files[0], 25)) {
                            onChange(e.target.files[0]);
                          }
                        }}
                        {...rest}
                      />
                      {errors.banner && (
                        <p className="absolute top-0 m-4 bg-clip-text bg-gradient-to-br text-transparent from-primaryLeft to-primaryRight">
                          {errors.banner.message + '*'}
                        </p>
                      )}
                    </div>
                  );
                }}
              />
            </div>
            <div className="rounded-xl -mt-10 pb-1 bg-white h  m-auto">
              <BuilderMainPanel />
            </div>
          </div>
          <BuilderSidePanel />
        </div>
      </form>
    </FormProvider>
  ) : (
    <div className="h-screen w-full flex justify-center items-center">
      <Loader />
    </div>
  );
}

export default TemplateBuilder;
