import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { FormulaCalculator } from '@talent-monk/formula';
import { getFormulaChain } from 'apiClient/formula/formula';
import CloseIcon from 'assets/svg/close';
import Button from 'components/atoms/button/Button';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import Tabs from '../tabs/Tabs';
import Functions from './Formula/Function';
import Validate from './Formula/Validate';

const tabs = [
  { id: 1, tabLabel: 'Fields' },
  { id: 2, tabLabel: 'Functions' },
  { id: 3, tabLabel: 'Validate' },
];

export default function FormulaBuilder({
  setModel,
  saveAction,
  moduleName,
  sourceId,
  selectedColumn,
  formulaDetails = {},
}) {
  const [active, setActive] = useState('Fields');
  const [cursorPos, setCursorPos] = useState(0);
  // const [dataSourceLoading, setDataSourceLoading] = useState(false);
  const [endCursorPos, setCursorPosEnd] = useState(null);
  const [formulaObj, setFormulaObj] = useState({
    formulaString: '',
    displayFormula: '',
    dependencyValueMap: {},
    // databaseDependencies: {},
    dependencyChainMap: {},
  });
  const [isValidated, setValidation] = useState(false);
  const [data, setData] = useState([]);
  const [isValidationFunctions, setIsValidationFunctions] = useState(false);
  const getColumns = () => {
    const columnData = Object?.entries(formulaObj?.dependencyChainMap)?.reduce(
      (prev, [key, value]) => {
        return {
          ...prev,
          [`[${value?.data?.alias}]`]: key,
        };
      },
      {},
    );
    return columnData;
  };

  const replaceNameWithId = (formulaObj) => {
    let temp = '';
    let colStarted = false;
    const columnMap = getColumns();
    let backendFormula = '';
    const formulaObject = { ...formulaObj };
    for (let x of formulaObject.displayFormula) {
      if (x === '[') {
        temp = '';
        backendFormula += '[';
        colStarted = true;
      } else if (x === ']' && temp !== '') {
        let col = temp;
        backendFormula += `"${columnMap[`[${col}]`]}"]`;
        colStarted = false;
        temp = '';
      } else {
        temp += x;
        if (!colStarted) {
          backendFormula += x;
        }
      }
    }
    formulaObject.formulaString = backendFormula;
    return formulaObject;
  };
  useEffect(() => {
    setFormulaObj(formulaDetails);
  }, [formulaDetails]);
  // useEffect(() => {
  //   if (formulaObj?.dependencyChainMap) {
  //     setFormulaObj((prev) => ({
  //       ...prev,
  //       dependencyChainMap: {
  //         ...prev.dependencyChainMap,
  //         ...formulaObj.dependencyChainMap,
  //       },
  //     }));
  //   }
  // }, [formulaObj?.dependencyChainMap]);

  useEffect(() => {
    if (formulaObj?.displayFormula) {
      const containsValidationFunction = /_VLOOKUP|_MATCH|_INDEX/.test(
        formulaObj?.displayFormula,
      );
      setIsValidationFunctions(containsValidationFunction);
    } else {
      setIsValidationFunctions(false);
    }
    setFormulaObj((prev) => ({
      ...prev,
      formulaString: replaceNameWithId(formulaObj)?.formulaString,
    }));
  }, [formulaObj?.displayFormula]);

  const addFunction = (syntax) => {
    setValidation(false);
    setFormulaObj((prev) => {
      let displayFormula =
        prev.displayFormula.slice(0, cursorPos) +
        syntax +
        prev.displayFormula.slice(endCursorPos);
      return {
        ...prev,
        displayFormula: displayFormula,
      };
    });
  };
  const onChangeHandler = (e) => {
    setValidation(false);
    setCursorPos(e.target.selectionStart);
    setCursorPosEnd(e.target.selectionEnd);
    setFormulaObj((prev) => {
      let displayFormula = e.target.value;
      return {
        ...prev,
        displayFormula: displayFormula,
      };
    });
  };

  function fetchNextDataSource(data) {
    const body = {
      info: {
        sourceName: data.info.nextSourceName,
      },
      data: data.data,
    };
    // console.log(body, data?.data?.alias);
    fetchDataSource(body, data?.data?.alias);
  }

  function findAndUpdateChild(item, parentAlias, childData) {
    if (item?.data?.alias === parentAlias) {
      return { ...item, child: childData };
    }
    if (item.child) {
      const updatedChild = item?.child?.map((childItem) =>
        findAndUpdateChild(childItem, parentAlias, childData),
      );
      return { ...item, child: updatedChild };
    }
    return item;
  }
  function fetchDataSource(body, parentAlias) {
    // setDataSourceLoading(true);
    getFormulaChain(body)
      .then((res) => {
        if (res?.values && res?.values?.length > 0) {
          const childData = res?.values?.map((item) => {
            return { ...item, child: [] };
          });
          if (parentAlias) {
            const updatedData = data?.map((item) =>
              findAndUpdateChild(item, parentAlias, childData),
            );
            setData(updatedData);
          } else {
            setData(childData);
          }
        }
      })
      .catch((err) => console.log(err));
    // .finally(() => setDataSourceLoading(false));
  }

  useEffect(() => {
    const body = {
      info: {
        sourceName: 'initial',
      },
      data: {
        moduleName: moduleName,
        sourceId: sourceId ? sourceId : undefined,
        allowedDataTypes:
          moduleName === 'CompensationBudgets' ? ['Amount'] : undefined,
      },
    };
    fetchDataSource(body);
  }, []);

  const onValidationChange = (id, value) => {
    setFormulaObj((prevFormulaObj) => {
      return {
        ...prevFormulaObj,
        dependencyValueMap: {
          ...prevFormulaObj.dependencyValueMap,
          [id]: value,
        },
      };
    });
  };

  const onChangeDropDown = async (value) => {
    // setFormulaObj((prev) => {
    //   const aliasExists = Object?.values(prev)?.some(
    //     (item) => item?.data?.alias === value?.data?.alias,
    //   );
    //   if (!aliasExists) {
    //     const newKey = v4();
    //     return { ...prev, [newKey]: value };
    //   }
    //   return prev;
    // });
    setValidation(false);
    setFormulaObj((prev) => {
      let displayFormula = prev.displayFormula;
      let beforeCursor = prev.displayFormula.charAt(cursorPos - 1);
      if (beforeCursor === '[') {
        displayFormula =
          displayFormula.slice(0, cursorPos) +
          `${value?.data?.alias}` +
          displayFormula.slice(endCursorPos);
      } else {
        displayFormula =
          displayFormula.slice(0, cursorPos) +
          `[${value?.data?.alias}]` +
          displayFormula.slice(endCursorPos);
      }
      return {
        ...prev,
        displayFormula: displayFormula,
      };
    });
    setFormulaObj((prev) => {
      const aliasExists = Object.values(prev.dependencyChainMap).some(
        (item) => item === value.data.alias,
      );
      if (!aliasExists) {
        const newKey = v4();
        const newDependencyChainMap = {
          ...prev.dependencyChainMap,
          [newKey]: value,
        };
        const newFormulaObj = {
          ...prev,
          dependencyChainMap: newDependencyChainMap,
        };
        return newFormulaObj;
      }
      return prev;
    });
  };
  const setPositions = (e) => {
    setCursorPos(e.target.selectionStart);
    setCursorPosEnd(e.target.selectionEnd);
  };
  const exportComponents = (active) => {
    switch (active) {
      case 'Fields':
        return (
          <div className="flex flex-col gap-2 p-4">
            {data &&
              data?.length > 0 &&
              data?.map((item, index) => {
                return (
                  <div className="w-full border rounded-lg p-1" key={index}>
                    {expandableDisclosure(item, 'formulaBuilder')}
                  </div>
                );
              })}
          </div>
        );
      case 'Validate':
        return (
          <Validate
            formulaObj={formulaObj}
            variables={FormulaCalculator.getDependenciesFromFormula(
              formulaObj.formulaString,
            )}
            onValidationChange={onValidationChange}
            // selectedColumn={selectedColumn}
            saveAction={saveAction}
            dataSource={data}
            // columns={columns}
            setValidation={setValidation}
          />
        );
      case 'Functions':
        return <Functions addFunction={addFunction} moduleName={moduleName} />;
      default:
        return null;
    }
  };
  const expandableDisclosure = (item, usedIn) => {
    return (
      <Disclosure className="w-full">
        {({ open }) => (
          <div className="w-full">
            <div className="flex items-center justify-between px-4 py-2 w-full">
              <p
                className={`font-semibold text-start ${
                  item.info.canSelect ? 'cursor-pointer' : ''
                }`}
                onClick={() => {
                  if (item.info.canSelect) {
                    // setSearchQuery(item.data.alias);
                    onChangeDropDown(item);
                  }
                }}
              >
                {item?.data?.alias}
              </p>
              <Disclosure.Button
                className={'cursor-pointer'}
                onClick={() => {
                  fetchNextDataSource(item);
                }}
              >
                <ChevronDownIcon
                  className={`w-5 h-5 ${open ? 'rotate-180' : ''}`}
                />
              </Disclosure.Button>
            </div>
            <Disclosure.Panel className="text-gray-500">
              {item?.child?.length > 0 ? (
                <div className="p-2 ">
                  {item?.child?.map((item) => (
                    <div key={item?.id}>
                      <div className="p-2 hover:bg-transparent  flex justify-between">
                        {item?.info?.canExpand ? (
                          <div className="w-full border rounded-lg p-1">
                            {expandableDisclosure(item, 'formulaBuilder')}
                          </div>
                        ) : (
                          <p
                            onClick={() => {
                              if (item.info.canSelect) {
                                // setSearchQuery(item.data.alias);
                                onChangeDropDown(item);
                              }
                            }}
                            className="flex items-center gap-3 w-full capitalize cursor-pointer"
                          >
                            {item.data.alias}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="bg-gray-50 p-4"> No options </div>
              )}
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    );
  };

  return (
    <div
      style={{ background: 'rgba(0, 0, 0, 0.6)' }}
      className={`w-full transition-all h-full top-0 bottom-0 left-0 right-0 fixed z-[100] bg-gray-400 flex justify-center items-center`}
    >
      <div className="  w-[65%] h-[530px] bg-white rounded-lg z-50">
        <div className="border-b-2   p-4 flex justify-between items-center">
          <div>
            <p className="font-bold text-lg">Formula Builder</p>
            <p className=" text-xs">
              Note:- Put your fields inside {'['} {']'}
            </p>
          </div>
          <CloseIcon
            className="cursor-pointer"
            onClick={() => setModel(false)}
          />
        </div>

        <div className="w-full h-[75%] flex flex-row">
          <div className="p-4 w-[65%]  border-r-2 border-b-2">
            <textarea
              id="formula-builder-text-area"
              onKeyUp={setPositions}
              className="w-full border-2 rounded-lg h-full p-2"
              value={formulaObj.displayFormula}
              onClick={setPositions}
              onChange={onChangeHandler}
            ></textarea>
          </div>
          <div className=" w-[35%]  border-b-2 h-full overflow-auto">
            <div className="mt-2 w-full">
              <Tabs
                // tabs={tabs}
                tabs={
                  isValidationFunctions
                    ? tabs?.filter((tab) => tab?.tabLabel !== 'Validate')
                    : tabs
                }
                selectedTab={active}
                handleTab={(e) => setActive(e.tabLabel)}
              />
            </div>
            <div>{exportComponents(active)}</div>
          </div>
        </div>
        <div className="flex items-center justify-end p-4 w-full h-[11%]">
          <Button
            variant="filled"
            className="w-[70px]"
            onClick={() => {
              if (isValidationFunctions || formulaObj?.displayFormula === '') {
                saveAction(formulaObj);
                setModel(false);
              } else {
                if (isValidated) {
                  saveAction(formulaObj);
                  setModel(false);
                } else alert('please validate the formula from validation tab');
              }
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
