import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import DeleteFormIcon from 'assets/svg/deleteFormIcon';
import { VersionHistoryIcon } from 'assets/svg/versionHistory';
import { CheckBoxProgram } from 'components/atoms/FormElements/input/Input';
import Select from 'components/atoms/FormElements/select/Select';
import { colorArrayShade1 } from 'pages/rangeBuilder/constants';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { ViewDetails } from '../internalRangePopUp/InternalRangePopUp';
import './rangeTable.scss';

export const RangeTable = ({
  selectedRowId,
  setSelectedRowId,
  setAddRowVisible,
  benchmarkColumns,
  internalCalculationsData,
  handleDelete,
  dropDownFilters,
  selectedFilters,
  setSelectedFilters,
  getVersionHistory,
  calculatedData,
  setCalculatedData,
  pivotedColumns,
  setPivotedColumns,
  selectedType,
}) => {
  const [viewDetails, setViewDetails] = useState(false);
  const [collapsedId, setCollapsedId] = useState('');
  const [defaultTabSelected, setDefaultTabSelected] = useState('');
  const [updatedBenchmarkColumns, setUpdatedBenchmarkColumns] = useState([]);
  const [subHeadings, setSubHeadings] = useState([]);

  const [benchmarkWithColSpan, setBenchmarkWithColSpan] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [versionHistory, setVersionHistory] = useState();
  const [viewRoleSelectedData, setViewRoleSelectedData] = useState();
  const [detailsTableValues, setDetailsTableValues] = useState({});
  const [activeViewPopUpId, setActiveViewPopUpId] = useState('');

  const handleSelectedFilter = (option, column) => {
    if (option) {
      if (selectedFilters?.filter((filter) => filter?.key === column)?.length) {
        setSelectedFilters(
          selectedFilters?.map((filter) => {
            if (filter?.key === column) {
              return { ...filter, value: option?.value };
            }
            return filter;
          }),
        );
      } else {
        setSelectedFilters([
          ...selectedFilters,
          { key: column, operator: 'EQUAL', value: option?.value },
        ]);
      }
    } else {
      setSelectedFilters(
        selectedFilters?.filter((filter) => filter?.key !== column),
      );
    }
  };

  const handleRowSelect = (data) => {
    if (selectedRowId?.includes(data?.id)) {
      setSelectedRowId(selectedRowId?.filter((id) => id !== data?.id));
    } else {
      setSelectedRowId([...selectedRowId, data?.id]);
    }
  };

  useEffect(() => {
    setUpdatedBenchmarkColumns([
      { id: v4(), columnName: 'Job Details', colSpan: 1 },
      { id: v4(), columnName: '', colSpan: pivotedColumns?.length - 1 },
      ...(benchmarkColumns || []).filter((benchmark) =>
        selectedType?.includes(benchmark?.columnName),
      ),
    ]);
  }, [benchmarkColumns, pivotedColumns?.length, selectedType]);

  const constructCalculatedData = (calculatedJson) => {
    const calculatedJsonKeys = Object.keys(calculatedJson);

    const restructuredCalculatedJson = calculatedJsonKeys.reduce((acc, key) => {
      const [point, benchmarkId] = key.split('_');
      if (!acc[benchmarkId]) {
        acc[benchmarkId] = {};
      }
      acc[benchmarkId][point] = calculatedJson[key];
      return acc;
    }, {});

    const result = Object.fromEntries(
      Object.entries(restructuredCalculatedJson).map(
        ([benchmarkId, values]) => [
          benchmarkId,
          Object.entries(values).map(([point, value]) => ({
            [point]: value ? value : 0,
          })),
        ],
      ),
    );

    return result;
  };

  console.log(
    internalCalculationsData?.filter(
      (dataTable) => dataTable?.id === activeViewPopUpId,
    ),
    'test',
  );

  useEffect(() => {
    const data = internalCalculationsData?.map((dataTable) => {
      const pivotedJson = dataTable?.pivotedJson || {};
      const { 'Pay Range Code': keyValue, ...rest } = pivotedJson;
      const sortedPivotedJson = { 'Pay Range Code': keyValue, ...rest };
      const pivotedData = Object?.values(sortedPivotedJson);

      const calculatedDataStructure = constructCalculatedData(
        dataTable?.calculationJson,
      );

      activeViewPopUpId === dataTable?.id &&
        setCalculatedData(calculatedDataStructure);

      let calculatedTableValues = [];
      let calculatedViewDetailsValues = [];

      benchmarkColumns
        ?.filter((benchmark) => selectedType?.includes(benchmark?.columnName))
        ?.map((column) => {
          calculatedViewDetailsValues = [
            ...calculatedViewDetailsValues,
            [
              column?.columnName,
              ...(
                Object.values(calculatedDataStructure?.[column?.id] || {}) || []
              ).map((value) => Object?.values(value)?.[0]),
            ],
          ];
          calculatedTableValues = [
            ...calculatedTableValues,
            ...(
              Object?.values(calculatedDataStructure?.[column?.id] || {}) || []
            ).map((value) => Object?.values(value)?.[0]),
          ];
        });

      return {
        id: dataTable?.id,
        data: [...pivotedData, ...calculatedTableValues],
      };
    });
    setTableData(data);
  }, [internalCalculationsData, selectedType, activeViewPopUpId]);

  useEffect(() => {
    const pivotDataColumns = Object.keys(
      internalCalculationsData?.[0]?.pivotedJson || {},
    );
    const sortedPivotedColumns = [
      ...(pivotDataColumns || []).filter((item) => item === 'Pay Range Code'),
      ...(pivotDataColumns || []).filter((item) => item !== 'Pay Range Code'),
    ];
    setPivotedColumns(sortedPivotedColumns);

    const calculatedJsonColumns = Object.keys(
      internalCalculationsData?.[0]?.calculationJson || {},
    )?.map((column) => {
      return column?.split('_');
    });

    const updatedBenchmarkWithColSpan = updatedBenchmarkColumns?.map(
      (column, index) => {
        console.log(column, calculatedJsonColumns, 'col');
        if (
          calculatedJsonColumns?.filter(
            (calculatedColumn) => calculatedColumn?.[1] === column?.id,
          )?.length
        ) {
          return {
            ...column,
            colSpan: calculatedJsonColumns?.filter(
              (calculatedColumn) => calculatedColumn?.[1] === column?.id,
            )?.length,
            backgroundColor: colorArrayShade1[index - 2],
          };
        } else if (column?.columnName && column?.columnName !== 'Job Details') {
          return {
            ...column,
            backgroundColor: colorArrayShade1[index - 2],
          };
        }
        return column;
      },
    );

    setBenchmarkWithColSpan(updatedBenchmarkWithColSpan);
    let headings = [];

    benchmarkColumns
      ?.filter((benchmark) => selectedType?.includes(benchmark?.columnName))
      ?.map((column) =>
        calculatedJsonColumns?.filter(
          (calculatedColumn) => calculatedColumn?.[1] === column?.id,
        ),
      )
      ?.map((column) => column?.map((subColumn) => subColumn?.[0]))
      ?.map((column) => {
        headings = [...headings, ...column];
      });
    setSubHeadings([...sortedPivotedColumns, ...headings]);
  }, [
    updatedBenchmarkColumns?.toString(),
    internalCalculationsData,
    selectedType,
    selectedFilters,
  ]);

  const handleBulkDelete = () => {
    handleDelete(selectedRowId);
    setSelectedRowId([]);
  };

  const handleSidePopUpData = async (roleId) => {
    setViewDetails(true);
    const response = await getVersionHistory(roleId);
    setVersionHistory(response?.data);
  };

  return (
    <>
      <div className="space-y-[10px]">
        {selectedRowId?.length > 0 && (
          <div className="flex items center gap-6 px-2">
            <p className="flex items-center gap-3">
              <CheckBoxProgram checked />
              <span className="text-sm font-medium text-gray-900">{`${selectedRowId?.length} selected`}</span>
            </p>
            <p className="text-gray-200">|</p>
            <p
              className="gap-2 flex items-center text-sm font-semibold text-gray-600 cursor-pointer"
              onClick={handleBulkDelete}
            >
              <DeleteFormIcon className="cursor-pointer" />
              Delete
            </p>
          </div>
        )}
        <div className="rangeBuilder">
          <table className="rangeTable w-full">
            <thead className="w-full">
              <tr className="w-full">
                {benchmarkWithColSpan?.map((column) => {
                  return (
                    <th
                      key={column?.id}
                      style={{
                        backgroundColor: column?.backgroundColor
                          ? column?.backgroundColor
                          : colorArrayShade1[9],
                      }}
                      colSpan={
                        column?.columnName
                          ? column?.colSpan
                          : pivotedColumns?.length - 1
                      }
                      onClick={() =>
                        setCollapsedId(
                          collapsedId !== column?.id ? column?.id : '',
                        )
                      }
                    >
                      <div className="flex items-center text-white">
                        {column?.columnName?.length ? (
                          collapsedId !== column?.id ? (
                            <ChevronRightIcon className="w-5 h-5 text-white" />
                          ) : (
                            <ChevronLeftIcon className="w-5 h-5 text-white" />
                          )
                        ) : (
                          <></>
                        )}
                        {column?.columnName}
                      </div>
                    </th>
                  );
                })}
                <th style={{ backgroundColor: '#F9F9F9' }}>
                  <div
                    className="flex items-center cursor-pointer justify-center gap-1 text-gray-700 w-full"
                    onClick={() => setAddRowVisible(true)}
                  >
                    <PlusIcon className="w-4 h-4 text-gray-900 font-semibold" />
                    Add Row
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm text-gray-600">
              <tr>
                {subHeadings?.map((column) => {
                  return (
                    <td
                      key={v4()}
                      className="capitalize"
                      style={{ color: '#98A2B3' }}
                    >
                      <div className="flex items-center justify-between gap-4">
                        <p>{column}</p>
                        {pivotedColumns?.includes(column) && (
                          <div className="w-fit">
                            <Select
                              id="remove-border-select"
                              options={dropDownFilters?.[0]?.[column]?.map(
                                (item) => ({ label: item, value: item }),
                              )}
                              isClearable
                              onChange={(option) =>
                                handleSelectedFilter(option, column)
                              }
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  );
                })}
                {/* <td
                  style={{
                    color: '#98A2B3',
                    boxShadow:
                      'box-shadow: -3px 6px 4px -4px rgba(16, 24, 40, 0.08)',
                    zIndex: 2,
                  }}
                >
                  Job Code
                </td>
                <td style={{ color: '#98A2B3' }}>Job Title</td>
                <td style={{ color: '#98A2B3' }}>Job Grade</td>
                <td style={{ color: '#98A2B3' }}>Currency</td>
                <td style={{ color: '#98A2B3' }}>Country</td>
                <td style={{ color: '#22CCEE', backgroundColor: '#F5FEFF' }}>
                  Min
                </td>
                <td style={{ color: '#22CCEE', backgroundColor: '#F5FEFF' }}>
                  Mid
                </td>
                <td style={{ color: '#22CCEE', backgroundColor: '#F5FEFF' }}>
                  Max
                </td>
                <td style={{ color: '#F63D68', backgroundColor: '#FFFAFB' }}>
                  Min
                </td>
                <td style={{ color: '#F63D68', backgroundColor: '#FFFAFB' }}>
                  Mid
                </td>
                <td style={{ color: '#F63D68', backgroundColor: '#FFFAFB' }}>
                  Max
                </td>
                <td style={{ color: '#7A5AF8', backgroundColor: '#FBFBFF' }}>
                  Education
                </td>
                <td style={{ color: '#7A5AF8', backgroundColor: '#FBFBFF' }}>
                  Experience
                </td>
                <td style={{ color: '#7A5AF8', backgroundColor: '#FBFBFF' }}>
                  job Family
                </td>
                <td style={{ color: '#7A5AF8', backgroundColor: '#FBFBFF' }}>
                  Industry
                </td>
                <td style={{ color: '#7A5AF8', backgroundColor: '#FBFBFF' }}>
                  Effective Date
                </td>
                <td style={{ color: '#7A5AF8', backgroundColor: '#FBFBFF' }}>
                  Status
                </td>
                <td style={{ color: '#7A5AF8', backgroundColor: '#FBFBFF' }}>
                  Min
                </td>
                <td style={{ color: '#7A5AF8', backgroundColor: '#FBFBFF' }}>
                  Mid
                </td>
                <td style={{ color: '#7A5AF8', backgroundColor: '#FBFBFF' }}>
                  Max
                </td> */}
                {subHeadings?.length > 0 && (
                  <td style={{ backgroundColor: '#F9F9F9' }}></td>
                )}
              </tr>
              {tableData?.map((data) => {
                return (
                  <>
                    <tr key={data?.id}>
                      {data?.data?.map((rowData, index) => {
                        return (
                          <>
                            {index === 0 && (
                              <td
                                key={rowData?.id}
                                style={{ zIndex: 2 }}
                                className="shadow-md"
                              >
                                <div className="flex items-center gap-3">
                                  <CheckBoxProgram
                                    onChange={() => handleRowSelect(data)}
                                    checked={selectedRowId?.includes(data?.id)}
                                  />
                                  <p
                                    className="text-sm font-semibold text-gray-900 cursor-pointer hover:font-underline"
                                    onClick={() => {
                                      handleSidePopUpData(data?.id);
                                      setViewRoleSelectedData(data?.data);
                                      setActiveViewPopUpId(data?.id);
                                    }}
                                  >
                                    {rowData}
                                  </p>
                                </div>
                              </td>
                            )}
                            {index !== 0 && <td>{rowData}</td>}
                          </>
                        );
                      })}
                      {/* <td>{data?.jobDetails?.jobTitle}</td>
                      <td>{data?.jobDetails?.jobGrade}</td>
                      <td>{data?.jobDetails?.currency}</td>
                      <td>{data?.jobDetails?.country}</td>
                      <td style={{ backgroundColor: '#F5FEFF' }}>
                        {data?.basePay?.min}
                      </td>
                      <td style={{ backgroundColor: '#F5FEFF' }}>
                        {data?.basePay?.mid}
                      </td>
                      <td style={{ backgroundColor: '#F5FEFF' }}>
                        {data?.basePay?.max}
                      </td>
                      <td style={{ backgroundColor: '#FFFAFB' }}>
                        {data?.variablePay?.min}
                      </td>
                      <td style={{ backgroundColor: '#FFFAFB' }}>
                        {data?.variablePay?.min}
                      </td>
                      <td style={{ backgroundColor: '#FFFAFB' }}>
                        {data?.variablePay?.min}
                      </td>
                      <td style={{ backgroundColor: '#FBFBFF' }}>
                        {data?.jobInformation?.education}
                      </td>
                      <td style={{ backgroundColor: '#FBFBFF' }}>
                        {data?.jobInformation?.experience}
                      </td>
                      <td style={{ backgroundColor: '#FBFBFF' }}>
                        {data?.jobInformation?.jobFamily}
                      </td>
                      <td style={{ backgroundColor: '#FBFBFF' }}>
                        {data?.jobInformation?.industry}
                      </td>
                      <td style={{ backgroundColor: '#FBFBFF' }}>
                        {data?.jobInformation?.effectiveDate}
                      </td>
                      <td style={{ backgroundColor: '#FBFBFF' }}>
                        {data?.jobInformation?.status}
                      </td>
                      <td style={{ backgroundColor: '#FBFBFF' }}>
                        {data?.jobInformation?.min}
                      </td>
                      <td style={{ backgroundColor: '#FBFBFF' }}>
                        {data?.jobInformation?.mid}
                      </td>
                      <td style={{ backgroundColor: '#FBFBFF' }}>
                        {data?.jobInformation?.max}
                      </td> */}
                      <td className="shadow-md">
                        <div className="flex items-center justify-center px-4 gap-5">
                          <VersionHistoryIcon
                            onClick={() => {
                              setViewDetails(true);
                              setDefaultTabSelected('Version History');
                            }}
                            className="cursor-pointer"
                          />
                          <DeleteFormIcon
                            onClick={() => handleDelete([data?.id])}
                            className="w-4 h-4 cursor-pointer text-gray-600"
                          />
                          {/* <EditPencilIcon
                            onClick={() => setViewDetails(true)}
                            className="w-4 h-4 cursor-pointer text-gray-600"
                          /> */}
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
          {!subHeadings?.length && (
            <p className="flex items-center justify-center font-semibold text-gray-400">
              No data to show
            </p>
          )}
        </div>
      </div>
      {viewDetails && (
        <ViewDetails
          setViewDetails={setViewDetails}
          defaultTabSelected={defaultTabSelected}
          setDefaultTabSelected={setDefaultTabSelected}
          versionHistory={versionHistory}
          pivotedColumns={pivotedColumns}
          pivotedData={viewRoleSelectedData}
          getVersionHistory={getVersionHistory}
          detailsTableColumns={[
            'Position',
            ...Object.values(
              calculatedData?.[benchmarkColumns?.[0]?.id] || [],
            ).map((value) => Object.keys(value)?.[0] || {}),
          ]}
          detailsTableData={[]}
          detailsTableValues={benchmarkColumns?.map((column) => {
            return [
              column?.columnName,
              ...(Object?.values(calculatedData?.[column?.id] || {}) || []).map(
                (value) => {
                  return Object?.values(value)?.[0] || '';
                },
              ),
            ];
          })}
        />
      )}
    </>
  );
};
