import { ArrowDownIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { getfinalRangeData } from 'apiClient/rangeBuilder/rangeBuilder';
import { NoRoleIcon } from 'assets/svg/noRoleIcon';
import { TabularIcon } from 'assets/svg/tabularIcon';
import { Loader } from 'components/atoms/loader/Loader';
import { Search } from 'pages/benefits/manageBenefits/Search';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompensationTypeFilter } from '../internalRange/CompensationTypeFilter';
import { CompensationUnitFilter } from '../internalRange/CompensationUnitFilter';
import { ReferenceTypeFilter } from '../internalRange/ReferenceTypeFilter';
import { AddColumModal } from './AddColumnModal';
import { ViewFinalRangeData } from './ViewFinalRangeData';
import BarChartSquareIcon from 'assets/svg/barChartSquareIcon';
import GraphicalView from '../internalRange/graphicalView/GraphicalView';
import BenchmarkFilter from '../employees/benchmarkFilter/BenchmarkFilter';
import { useGetFinalRangesGraphData } from 'query/rangeBuilder/finalRange/finalRange';
import { useDebounce } from 'hooks/useDebounce';
import { DEBOUNCE_SEARCH_TIME } from 'apiClient/api.constant';

export const FinalRange = ({ planName }) => {
  const [addColumnVisible, setAddColumnVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [finalRangeData, setFinalRangeData] = useState();
  const [dataAvailable, setDataAvailable] = useState(false);
  const [searchedValue, setSearchedValue] = useState('');
  const [isLakhsCompUnit, setIsLakhsCompUnit] = useState(false);
  const { id } = useParams();
  const [isGraphView, setIsGraphView] = useState(false);
  const [graphRow, setGraphRow] = useState(10);
  const [selectedGraphBenchmark, setSelectedGraphBenchmark] = useState(null);
  const [graphBenchMarkOptions, setGraphBenchmarkOptions] = useState([]);

  const debouncedValue = useDebounce(searchedValue, DEBOUNCE_SEARCH_TIME);

  const {
    data: graphApiData,
    refetch: refetchGraphData,
    isRefetching: isGraphDataRefetching,
  } = useGetFinalRangesGraphData(id, graphRow, 0, debouncedValue);

  useEffect(() => {
    const benchmark = graphApiData?.benchMarkMap ?? {};
    const benchmarkOptions = Object.keys(benchmark)?.map((key) => {
      return {
        label: benchmark?.[key],
        value: key,
      };
    });
    setGraphBenchmarkOptions(benchmarkOptions);
    if (!selectedGraphBenchmark) {
      setSelectedGraphBenchmark(benchmarkOptions?.[0]);
    }
  }, [graphApiData]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await getfinalRangeData(
          // 'afa60d89-2cab-41b9-a0e8-675104aec13e',
          id,
        );

        setDataAvailable(true);
        setFinalRangeData(response);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <div
        className={`flex items-center px-8 ${
          dataAvailable ? 'justify-between' : 'justify-end'
        }`}
      >
        {!dataAvailable && <div className="1/4"></div>}
        {dataAvailable && (
          <div className="flex gap-3 items-center">
            <div
              className="flex w-[73px] h-8 border py-2.5 px-3.5 rounded-md gap-1 items-center justify-center cursor-pointer"
              onClick={() => {
                setIsGraphView((preValue) => !preValue);
              }}
            >
              <div className="flex w-3.5 h-3.5">
                <BarChartSquareIcon />
              </div>
              <p className="text-sm font-semibold font-figtree text-gray-700">
                View
              </p>
            </div>
            {/* <div className="flex w-[1px] h-5 bg-gray-200 border border-gray-200"></div> */}
          </div>
        )}
        <div className="flex items-center gap-5">
          <Search
            searchedValue={searchedValue}
            setSearchedValue={setSearchedValue}
          />
          <div className="flex items-center gap-[10px]">
            <ArrowDownIcon className="w-5 cursor-pointer h-5 text-gray-600 border-b border-gray-600" />
            <Cog6ToothIcon className="text-gray-600 cursor-pointer w-5 h-5" />
            <TabularIcon className="cursor-pointer m-1" />
          </div>
        </div>
      </div>
      {!loading && dataAvailable && (
        <div className="border-y bg-gray-50 border-gray-200 px-8 py-1 flex items-center justify-between">
          <div className="flex items-center">
            {isGraphView ? (
              <BenchmarkFilter
                benchmarkList={graphBenchMarkOptions}
                selectedBenchmark={selectedGraphBenchmark}
                setSelectedBenchmark={setSelectedGraphBenchmark}
              />
            ) : (
              <>
                <CompensationTypeFilter
                  typeList={[
                    { name: 'Base Pay' },
                    { name: 'Variable Pay' },
                    { name: 'Others' },
                    { name: 'Equity' },
                  ]}
                />
                <p className="text-gray-300 px-4">|</p>
                <ReferenceTypeFilter
                  typeList={[{ name: 'Min' }, { name: 'Mid' }, { name: 'Max' }]}
                />
              </>
            )}
          </div>
          <CompensationUnitFilter
            isLakhsCompUnit={isLakhsCompUnit}
            setIsLakhsCompUnit={setIsLakhsCompUnit}
          />
        </div>
      )}
      <div className="px-8">
        {loading ? (
          <div className="flex items-center justify-center w-full mt-40">
            <Loader />
          </div>
        ) : !dataAvailable ? (
          <div className="flex flex-col items-center justify-center h-full space-y-5 my-14">
            <NoRoleIcon />
            <div className="space-y-1 flex flex-col items-center justify-between">
              <p className="text-sm font-semibold text-gray-900">
                Nothing to show
              </p>
              <p className="text-xs text-gray-600">
                If you have access, you will the see the benchmarks here
              </p>
            </div>
          </div>
        ) : (
          <>
            {!isGraphView ? (
              <ViewFinalRangeData finalRangeData={finalRangeData} />
            ) : (
              <GraphicalView
                apiData={graphApiData}
                refetchGraphData={refetchGraphData}
                isRefetching={isGraphDataRefetching}
                isLakhsCompUnit={isLakhsCompUnit}
                pageSize={graphRow}
                setPageSize={setGraphRow}
                selectedBenchmark={selectedGraphBenchmark}
                query={debouncedValue}
              />
            )}
          </>
        )}
      </div>

      {addColumnVisible && (
        <AddColumModal setAddColumnVisible={setAddColumnVisible} />
      )}
    </>
  );
};
