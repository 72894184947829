import Tabs from 'components/molecules/tabs/Tabs';
import { useEffect, useState } from 'react';
import DashboardChartList from './DashboadConfiguration/DashboardChartList';
import DashboardFilters from './DashboadConfiguration/DashboardFilters';
import DashboardSettings from './DashboadConfiguration/DashboardSettings';
import DashboardSource from './DashboadConfiguration/DashboardSource';
import DashboardChartData from './GraphConfiguration/DashboardChartData';
import DashboardChartSorting from './GraphConfiguration/DashboardChartSorting';
export default function ConfigureDashboard({
  configFor,
  dashboardCards,
  setDashboardCards,
  dashboardSettings,
  setDashboardSettings,
  selectedPage,
}) {
  const [active, setActive] = useState('Charts');
  const dashboardNav = [
    { id: 1, tabLabel: 'Charts' },
    { id: 2, tabLabel: 'Settings' },
    { id: 3, tabLabel: 'Source' },
    { id: 4, tabLabel: 'Filters' },
  ];
  const graphNav = [
    { id: 1, tabLabel: 'Data' },
    { id: 2, tabLabel: 'Sorting' },
  ];
  useEffect(() => {
    if (configFor === 'default') {
      setActive(dashboardNav[0]?.tabLabel);
    } else setActive(graphNav[0]?.tabLabel);
  }, [configFor]);
  const exportComponent = (componentName) => {
    switch (componentName) {
      case 'Charts':
        return (
          <DashboardChartList
            dashboardCards={dashboardCards}
            setDashboardCards={setDashboardCards}
            selectedPage={selectedPage}
            dashboardSettings={dashboardSettings}
            setDashboardSettings={setDashboardSettings}
          />
        );
      case 'Settings':
        return (
          <DashboardSettings
            dashboardSettings={dashboardSettings}
            setDashboardSettings={setDashboardSettings}
          />
        );
      case 'Source':
        return (
          <DashboardSource
            dashboardSettings={dashboardSettings}
            setDashboardSettings={setDashboardSettings}
          />
        );
      case 'Filters':
        return (
          <DashboardFilters
            dashboardSettings={dashboardSettings}
            setDashboardSettings={setDashboardSettings}
          />
        );
      case 'Data':
        return (
          <DashboardChartData
            configFor={configFor}
            layout={dashboardCards}
            setLayout={setDashboardCards}
            selectedPage={selectedPage}
          />
        );
      case 'Sorting':
        return <DashboardChartSorting />;

      default:
        return null; // Return null or handle error for unknown components
    }
  };
  return (
    <div className="bg-white h-full shadow-lg w-96  p-4 mb-6">
      <p className="text-base font-semibold pb-4">
        {configFor === 'default' ? 'Dashboard Configuration' : 'Edit Chart'}
      </p>
      <Tabs
        tabs={configFor === 'default' ? dashboardNav : graphNav}
        selectedTab={active}
        handleTab={(e) => setActive(e.tabLabel)}
      />
      <div className=" px-4 pt-4 overflow-y-auto h-[calc(100vh_-_291px)]">
        {exportComponent(active)}
      </div>
    </div>
  );
}
