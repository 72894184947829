import axios from 'axios';

// const apiCall =  () => {
async function apiCalls(method, url, data = null, type) {
  //  const { logout } =

  //  useAuth0();
  const config = {
    method,
    url,
    headers: {
      'x-access-token': localStorage.getItem('accessToken'),
      'x-id-token': localStorage.getItem('idAccessToken'),
      'Content-Type': type || 'application/json',
    },
    // data: data ? JSON.stringify(data) : null,
    ...(data ? { data } : {}),
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    //console.log(error);

    // const auth0 = await createAuth0Client({
    //   domain: process.env.REACT_APP_AUTH0_DOMAIN,
    //   client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    //   redirect_uri: "/", // Change to your redirect URI
    // });

    if (error.response && error.response.status === 401) {
      localStorage.clear();

      // auth0.logout({
      //   returnTo: "/",
      // });
      // logout({ returnTo: "/" })
      // window.location.reload();
    }
    // if (error.response && error.response.status === 502) {

    //   //logout({ returnTo: "/" })
    //   // window.location.reload();
    // }
    throw error;
  }
}
async function fetchBlobMedia(method, url, data = null, type) {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/pdf',
        'x-access-token': localStorage.getItem('accessToken'),
        'x-id-token': localStorage.getItem('idAccessToken'),
      },
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      throw error;
    }
  }
}

export default apiCalls;

export const get = (url, queryParams = {}) => apiCalls('GET', url, queryParams);
export const getBlobMedia = (url, queryParams = {}) =>
  fetchBlobMedia('GET', url, queryParams);

export const post = (url, data, type) => apiCalls('POST', url, data, type);

export const put = (url, data, type) => apiCalls('PUT', url, data, type);

export const patch = (url, data, type) => apiCalls('PATCH', url, data, type);

export const del = (url, data) => apiCalls('DELETE', url, data);
