import { Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import 'react-tooltip/dist/react-tooltip.css';
import './index.css';
// import reportWebVitals from "./reportWebVitals";
import * as Sentry from '@sentry/react';
import { getOrgDetails } from 'apiClient/orgDetails/orgDetails';
import InfinityLoader from 'assets/svg/InfinityLoader/InfinityLoader';
import ProgressBarLoader from 'components/atoms/progressBar/ProgressBarLoader';
import UnsecuredPage from 'components/atoms/unsecuredPage/UnsecuredPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import { store } from './store/store';

Sentry.init({
  dsn: 'https://a3659ba8ee0a6931d2ab5340ecb91d55@o4506761833545728.ingest.sentry.io/4506761836494848',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://devorg.talentmonk.com/'],
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
function RootComponent() {
  const [organization, setOrganization] = useState(null);
  const [orgDetailsError, setOrgDetailsError] = useState(null);
  const [progress, setProgress] = useState(0);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const fetchOrgDetails = async (subdomain) => {
    try {
      const res = await getOrgDetails(subdomain);
      if (res?.orgId) {
        setProgress(20);
        localStorage.setItem('orgDetails', JSON.stringify(res));
        await new Promise((resolve) => setTimeout(resolve, 500));
        setProgress(70);
        await new Promise((resolve) => setTimeout(resolve, 500));
        setOrganization(res);
        setProgress(100);
      }
    } catch (err) {
      setOrgDetailsError(
        'Error fetching organization details. Please check your organization name and try again.',
      );
    }
  };

  useEffect(() => {
    const url = window.location.hostname;
    const subdomain = url.split('.')[0];
    const storedOrgDetails = localStorage.getItem('orgDetails');
    const storedSubdomain = JSON.parse(storedOrgDetails);
    if (storedSubdomain?.subdomain === subdomain) {
      setOrganization(storedSubdomain);
      setProgress(100);
    } else {
      fetchOrgDetails(subdomain);
    }
  }, []);

  if (orgDetailsError) {
    return (
      <div className="flex flex-col justify-center items-center h-screen w-full">
        <div>
          Error:
          {orgDetailsError}
        </div>
      </div>
    );
  }
  if (progress < 100) {
    return (
      <div className="flex flex-col justify-center items-center h-screen w-full">
        <div className="w-64">
          <p className="text-md"> Loading...</p>
          <ProgressBarLoader percentage={progress} />
        </div>
      </div>
    );
  }

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Suspense
            fallback={
              <div className="flex flex-col justify-center items-center h-screen w-full">
                {/* <Loader />
                        <div>Loading..</div> */}
                <InfinityLoader />
              </div>
            }
          >
            <Auth0ProviderWithNavigate orgDetails={organization}>
              <App orgDetails={organization} />
            </Auth0ProviderWithNavigate>
          </Suspense>
        </Router>
      </QueryClientProvider>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
if (window.self === window.top) {
  root.render(<RootComponent />);
} else {
  root.render(<UnsecuredPage />);
}
