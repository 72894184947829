import React from 'react';

const CreateUserGroup = React.lazy(() =>
  import('pages/admin/permissionControls/createUserGroup/CreateUserGroup'),
);
const NotificationCategory = React.lazy(() =>
  import('pages/notification/NotificationCategoryEvents'),
);
const Error = React.lazy(() =>
  import('pages/genericPages/pageNotFound/PageNotFound'),
);
const ComingSoon = React.lazy(() =>
  import('pages/admin/comingSoon/ComingSoon'),
);
const Notification = React.lazy(() =>
  import('pages/notification/Notification'),
);
const NotificationCategoryEvents = React.lazy(() =>
  import('pages/notification/NotificationCategoryEvents'),
);
const PermissionControl = React.lazy(() =>
  import('pages/admin/permissionControls/permissionRoles/PermissionControl'),
);
const ManageHome = React.lazy(() =>
  import('pages/admin/manageHome/ManageHome'),
);
const CreatePermissionRole = React.lazy(() =>
  import(
    'pages/admin/permissionControls/permissionRoles/createPermissionRole/CreatePermissionRole'
  ),
);
const Masters = React.lazy(() => import('pages/admin/masters/Masters'));
const TemplateBuilder = React.lazy(() =>
  import('pages/admin/templateBuilder/TemplateBuilder'),
);
// const Reports = React.lazy(() => import('pages/admin/reports/Reports'));
const Dashboards = React.lazy(() => import('pages/admin/dashboard/Dashboard'));
const ManageWorkflowRequestList = React.lazy(() =>
  import('pages/admin/workFlow/workFlowRequest/ManageWorkflowRequestList'),
);

const ManageWorkflowHistory = React.lazy(() =>
  import('pages/admin/workFlow/workflowHistory/ManageWorkflowHistory'),
);

const WorkflowRoutes = [
  {
    type: 'collapse',
    name: 'workflowRequests',
    key: 'workflowRequests',
    route: '/admin/workflowRequest',
    //flagName: 'Viewaddandeditmastertables',
    component: <ManageWorkflowRequestList />,
    parentId: 'Admin',
    module: 'Admin',
  },
  {
    type: 'collapse',
    name: 'workflowHistory',
    key: 'workflowHistory',
    route: '/admin/workflowHistory',
    //flagName: 'Viewaddandeditmastertables',
    component: <ManageWorkflowHistory />,
    parentId: 'Admin',
    module: 'Admin',
  },
];

const AdminRoutes = [
  // >>>> Admin and default routes Starts here
  ...WorkflowRoutes,
  {
    type: 'collapse',
    name: 'aboutCompany',
    key: 'aboutCompany',
    route: 'about-company',
    flagName: 'Provideaccesstomanagepermissionsforotherusers',
    parentId: 'Admin',
    module: 'Admin',
    subModule: 'Landing Page Builder',
    component: <TemplateBuilder />,
  },
  {
    type: 'collapse',
    name: 'Polls',
    key: 'polls',
    route: '/admin/masters',
    flagName: 'Viewaddandeditmastertables',
    parentId: 'Admin',
    module: 'Admin',
    subModule: 'Masters',
    component: <Masters />,
  },
  {
    type: 'collapse',
    name: 'userGroups',
    key: 'userGroups',
    route: 'admin/permission-controls',
    parentId: 'Admin',
    module: 'Admin',
    subModule: 'Permission Control',
    component: <PermissionControl />,
  },
  {
    type: 'collapse',
    name: 'createUserGroups',
    key: 'createUserGroups',
    route: 'admin/permission-controls/usergroups/create',
    parentId: 'Admin',
    module: 'Admin',
    subModule: 'Permission Control',
    component: <CreateUserGroup />,
  },
  {
    name: 'viewUserGroups',
    key: 'viewUserGroups',
    route: 'admin/permission-controls/usergroups/view/:id',
    parentId: 'Admin',
    flagName: 'Provideaccesstomanagepermissionsforotherusers',
    module: 'Admin',
    subModule: 'Permission Control',
    component: <CreateUserGroup />,
  },
  {
    type: 'collapse',
    name: 'editUserGroups',
    key: 'editUserGroups',
    route: 'admin/permission-controls/usergroups/edit/:id',
    flagName: 'Provideaccesstomanagepermissionsforotherusers',
    parentId: 'Admin',
    module: 'Admin',
    subModule: 'Permission Control',
    component: <CreateUserGroup />,
  },
  {
    type: 'collapse',
    name: 'userGroupList',
    key: 'userGroupList',
    route: 'admin/permission-controls/usergroups',
    flagName: 'Provideaccesstomanagepermissionsforotherusers',
    parentId: 'Admin',
    module: 'Admin',
    subModule: 'Permission Control',
    component: <PermissionControl />,
  },
  {
    type: 'collapse',
    name: 'permissionRolesList',
    key: 'permissionRolesList',
    route: 'admin/permission-controls/permission-roles',
    flagName: 'Provideaccesstomanagepermissionsforotherusers',
    parentId: 'Admin',
    module: 'Admin',
    subModule: 'Permission Control',
    component: <PermissionControl />,
  },
  {
    type: 'collapse',
    name: 'createPermissionRoles',
    key: 'createPermissionRoles',
    route: 'admin/permission-controls/permission-roles/create',
    flagName: 'Provideaccesstomanagepermissionsforotherusers',
    parentId: 'Admin',
    module: 'Admin',
    subModule: 'Permission Control',
    component: <CreatePermissionRole />,
  },
  {
    type: 'collapse',
    name: 'viewPermissionRoles',
    key: 'viewPermissionRoles',
    route: 'admin/permission-controls/permission-roles/view/:id',
    flagName: 'Provideaccesstomanagepermissionsforotherusers',
    parentId: 'Admin',
    module: 'Admin',
    subModule: 'Permission Control',
    component: <CreatePermissionRole />,
  },
  {
    type: 'collapse',
    name: 'editPermissionRoles',
    key: 'editPermissionRoles',
    route: 'admin/permission-controls/permission-roles/edit/:id',
    flagName: 'Provideaccesstomanagepermissionsforotherusers',
    parentId: 'Admin',
    module: 'Admin',
    subModule: 'Permission Control',
    component: <CreatePermissionRole />,
  },
  // {
  //   type: 'collapse',
  //   name: 'Reports',
  //   key: 'Reports',
  //   route: '/admin/reports',
  //   parentId: 'Admin',
  //   flagName: 'Downloadreportsforvariousmodules',
  //   module: 'Admin',
  //   subModule: 'Admin',
  //   component: <Reports />,
  // },
  {
    type: 'collapse',
    name: 'Dashboards',
    key: 'Dashboards',
    route: '/admin/insights',
    parentId: 'Admin',
    flagName: 'Viewinsights',
    module: 'Admin',
    subModule: 'Insights',
    component: <Dashboards />,
  },
  {
    type: 'collapse',
    name: 'Manage Workflows',
    key: 'Manage Workflows',
    route: '/admin/permission-controls/manage-workflows',
    parentId: 'Admin',
    flagName: 'Viewinsights',
    module: 'Admin',
    subModule: 'Permission Control',
    component: <ComingSoon />,
  },
  {
    type: 'collapse',
    name: 'settings',
    key: 'settings',
    route: '/admin/company-settings',
    flagName: 'Provideaccesstomanagecomapnysettings',
    parentId: 'Admin',
    module: 'Admin',
    subModule: 'Company Settings',
    component: <ComingSoon />,
  },
  {
    type: 'collapse',
    name: 'settings',
    key: 'settings',
    route: '/admin/company-settings/manage-home',
    flagName: 'Provideaccesstomanagecomapnysettings',
    parentId: 'Admin',
    module: 'Admin',
    subModule: 'Company Settings',
    component: <ManageHome />,
  },
  {
    type: 'collapse',
    name: 'Notifications',
    key: 'notifications',
    route: '/notifications',
    module: 'Default',
    component: <Notification />,
  },

  {
    type: 'collapse',
    name: 'NotificationSetting',
    key: 'notificationSetting',
    route: '/notifications/setting',
    module: 'Default',
    component: <NotificationCategory />,
  },
  {
    type: 'collapes',
    name: 'NotificationEvent',
    key: 'notificationEvent',
    route: '/notifications/setting/:id',
    module: 'Default',
    component: <NotificationCategoryEvents />,
  },
  {
    type: 'collapse',
    name: 'Error',
    key: 'Error',
    // icon: <Icon fontSize="small">assignment</Icon>,
    module: 'Default',
    route: '/PageNotFound',
    component: <Error />,
  },
];

export default AdminRoutes;
