import { AxiosError } from 'axios';
import * as CONSTANT from '../api.constant';
import { get, patch, post, del } from '../apiCalls';

export const fetchAllLandingPageTemplates = async () => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/landing-page-template/get-all-landing-page-template`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err instanceof AxiosError) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Templates fetch failed');
    }
  }
};
export const fetchModule = async (moduleName) => {
  try {
    // const response = await get(
    //   `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/landing-page/get-module?${
    //     moduleName ? `&moduleName=${moduleName}` : ''
    //   }`,
    // );
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_SURFACE_V2}/get-side-nav-drop-down?tabType=Worktools`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err instanceof AxiosError) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Templates fetch failed');
    }
  }
};
export const fetchMedia = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/landing-page-media/get-landing-page-media/${id}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err instanceof AxiosError) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Templates fetch failed');
    }
  }
};
export const fetchAllLandingPage = async (pageIndex, pageSize, title) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${
        CONSTANT.API_UTILITY_V3
      }/landing-page/get-all-landing-page?${
        pageIndex ? `&pageIndex=${pageIndex}` : ''
      }${pageSize ? `&pageSize=${pageSize}` : ''}${
        title ? `&title=${title}` : ''
      }`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err instanceof AxiosError) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Templates fetch failed');
    }
  }
};
export const fetchLandingPage = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/landing-page/get-landing-page/${id}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err instanceof AxiosError) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Templates fetch failed');
    }
  }
};

export const getAllModules = async (id) => {
  try {
    const response = await get(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/landing-page/get-module`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err instanceof AxiosError) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! Templates fetch failed');
    }
  }
};

export const uploadMedia = async (data) => {
  try {
    console.log(data);
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/landing-page-media/create-landing-page-media`,
      data,
      'multipart/form-data',
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
export const landingPageConfiguration = async (data) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/landing-page-configuration/create-landing-page-configuration`,
      data,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
export const landingPageApi = async (data) => {
  try {
    const response = await post(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/landing-page/create-landing-page`,
      data,
      'multipart/form-data',
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

export const updateLandingPage = async (landingPageId, data) => {
  try {
    const response = await patch(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/landing-page/update-landing-page/${landingPageId}`,
      data,
      'multipart/form-data',
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};

export const deleteLandingPage = async (landingPageId) => {
  try {
    const response = await del(
      `${CONSTANT.API_URL}/${CONSTANT.API_UTILITY_V3}/landing-page/delete-landing-page/${landingPageId}`,
    );
    const resultData = response.data;
    return resultData;
  } catch (err) {
    if (err) {
      throw new AxiosError(err.message);
    } else {
      throw new Error('Error! employee by authId fetch failed');
    }
  }
};
